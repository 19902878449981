export default {
    index: '/',
    search: '/search',
    dashboard: '/dashboard',
    notifications: '/notifications',
    community: '/community',
    contact: '/contact',
    support: '/support',
    privacy: '/privacy-policy',
    gtc: '/gtc',
    imprint: '/imprint',
    confirm: '/confirm',

    auth: {
        login: '/login',
        register: '/register',
        reset_password: '/reset-password',
        set_password: '/password'
    },

    events: {
        index: '/events',
        sports_map: '/sports-map',

        show: {
            index: '/events/{event}',
            membership: '/events/{event}/assignment',
            contact: '/events/{event}/contact'
        },

        media: {
            index: '/events/{event}/photos',
            cart: '/events/{event}/photos/cart',
            show: '/events/{event}/photos/{gallery}',
        },

        stay: {
            index: '/events/{event}/stay'
        },

        activities: {
            index: '/events/{event}/news'
        },

        jobs: {
            index: '/events/{event}/jobs',
            show: '/events/{event}/jobs/{job}',
        },

        tracking: {
            index: '/events/{event}/tracking'
        },

        community: {
            index: '/events/{event}/community'
        },

        map: {
            index: '/events/{event}/map'
        },

        products: {
            index: '/events/{event}/products'
        },

        shop: {
            index: '/events/{event}/shop'
        },

        awards: {
            index: '/events/{event}/awards'
        },

        sponsors: {
            index: '/events/{event}/sponsors'
        },

        gtc: {
            index: '/events/{event}/gtc'
        },

        impressions: {
            index: '/events/{event}/impressions'
        },

        acquisition_campaigns: {
            index: '/events/{event}/campaigns'
        },

        results: {
            index: '/events/{event}/results'
        },

        participations: {
            index: '/events/{event}/participations',
            checkin: '/events/{event}/checkin',
            create: '/events/{event}/participate',
            edit: '/events/{event}/participations/{participation}/edit',
            assign: '/events/{event}/participations/assign',

            show: {
                index: '/events/{event}/participations/{participation}',
                tracking: '/events/{event}/participations/{participation}/tracking',
                results: '/events/{event}/participations/{participation}/results',
                map: '/events/{event}/participations/{participation}/map',
                start: '/events/{event}/participations/{participation}/start',
                team: '/events/{event}/participations/{participation}/team',
                payment: '/events/{event}/participations/{participation}/payment',
                settings: '/events/{event}/participations/{participation}/settings',
                campaigns: '/events/{event}/participations/{participation}/campaigns',
                products: '/events/{event}/participations/{participation}/products',
            },

            attempts: {
                create: '/events/{event}/participations/{participation}/attempts/create',
            }
        },

        participation_classes: {
            index: '/events/{event}/participation-classes',
            show: '/events/{event}/participation-classes#category-{class}',
        },

        stage_races: {
            show: '/events/{event}/participation-classes#stage-{class}',
        },

        ratings: {
            index: '/events/{event}/ratings',
            create: '/events/{event}/ratings/create'
        },

        albums: {
            index: '/events/{event}/media',
            show: '/events/{event}/media/{album}'
        },

        statistics: {
            index: '/events/{event}/statistics',
            conversions: '/events/{event}/statistics/conversions',
            pages: '/events/{event}/statistics/pages',
            campaigns: '/events/{event}/statistics/campaigns',
            referrers: '/events/{event}/statistics/referrers',
            locations: '/events/{event}/statistics/locations',
            times: '/events/{event}/statistics/times'
        },

        members: {
            assign: '/events/{event}/members/assign',
            join: '/events/{event}/join',
        },
    },

    event_groups: {
        show: '/events/groups/{group}'
    },

    users: {
        index: '/athletes',

        show: {
            index: '/athletes/{user}',
            events: '/athletes/{user}/events',
            memberships: '/athletes/{user}/memberships',
            feed: '/athletes/{user}/feed'
        },

        followings: {
            index: '/athletes/{user}/stars'
        },

        followers: {
            index: '/athletes/{user}/fans'
        }
    },

    clubs: {
        index: '/clubs',
        create: '/clubs/create',

        members: {
            index: '/clubs/{club}/members',
            assign: '/clubs/{club}/members/assign',
            join: '/clubs/{club}/join',
        },

        events: {
            index: '/clubs/{club}/events',
        },

        followers: {
            index: '/clubs/{club}/fans',
        },

        departments: {
            index: '/clubs/{club}/departments',
        },

        show: {
            index: '/clubs/{club}',
            contact: '/clubs/{club}/contact',
            feed: '/clubs/{club}/feed',
            blog: '/clubs/{club}/blog',
            news: '/clubs/{club}/news',
            membership: '/clubs/{club}/membership'
        }
    },

    orders: {
        pay: '/pay/{order}',
        show: '/orders/{order}',
        process: '/pay/{order}/process',
    },

    team: {
        index: '/team'
    },

    blog: {
        index: '/blog'
    },

    jobs: {
        index: '/jobs/all',
        events: {
            index: '/jobs/events',
            show: '/jobs/events/{event}',

            jobs: {
                show: '/jobs/events/{event}/{job}'
            }
        },
        clubs: {
            index: '/jobs/clubs',
            show: '/jobs/clubs/{club}',

            jobs: {
                show: '/jobs/clubs/{club}/{job}'
            }
        },
        companies: {
            index: '/jobs/companies',
            show: '/jobs/companies/{club}',

            jobs: {
                show: '/jobs/companies/{club}/{job}'
            }
        }
    },

    my: {
        index: '/my',

        memberships: {
            index: '/my/memberships'
        },

        followings: {
            index: '/my/stars'
        },

        finisherpoints: {
            info: '/my/finisherpoints/info',
            store: '/my/finisherpoints',
            achievements: '/my/finisherpoints/achievements',
            transactions: '/my/finisherpoints/transactions',
            orders: '/my/finisherpoints/orders'
        },

        events: {
            index: '/my/events',
            show: '/my/events/{event}',
        },

        profile: {
            index: '/my/profile',

            manage: {
                index: '/my/profile/manage',
                data: '/my/profile/manage/data',
                club: '/my/profile/manage/club',
                description: '/my/profile/manage/description',
                contact: '/my/profile/manage/contact',
                sport_types: '/my/profile/manage/sport-types',
                social: '/my/profile/manage/social',
                children: '/my/profile/manage/children',
                parents: '/my/profile/manage/parents',
                password: '/my/profile/manage/password',
                payment_information: '/my/profile/manage/payment-information',
                bank_accounts: '/my/profile/manage/bank-accounts',
                settings: {
                    index: '/my/profile/manage/settings',
                    privacy: '/my/profile/manage/settings/privacy',
                    notifications: {
                        index: '/my/profile/manage/settings/notifications',
                        general: '/my/profile/manage/settings/notifications/general',
                        events: '/my/profile/manage/settings/notifications/events',
                        athletes: '/my/profile/manage/settings/notifications/athletes',
                        clubs: '/my/profile/manage/settings/notifications/clubs',
                        devices: '/my/profile/manage/settings/notifications/devices',
                    }
                },

                billing_requests: {
                    index: '/my/profile/manage/billing/requests',
                    create: '/my/profile/manage/billing/requests/create',
                    show: '/my/profile/manage/billing/requests/{request}',
                    edit: '/my/profile/manage/billing/requests/{request}/edit',
                },

                articles: {
                    index: '/my/profile/manage/blog',
                    create: '/my/profile/manage/blog/create',
                    show: '/my/profile/manage/blog/{article}',
                    edit: '/my/profile/manage/blog/{article}/edit',
                },
            },
        }
    }

}
