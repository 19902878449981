import { default as blogVGXdATM0f8Meta } from "/home/runner/work/app/app/src/pages/athletes/[user]/blog.vue?macro=true";
import { default as events4NHz2YexJ2Meta } from "/home/runner/work/app/app/src/pages/athletes/[user]/events.vue?macro=true";
import { default as fansflyU0xAs8SMeta } from "/home/runner/work/app/app/src/pages/athletes/[user]/fans.vue?macro=true";
import { default as feeduSiB3NHYKHMeta } from "/home/runner/work/app/app/src/pages/athletes/[user]/feed.vue?macro=true";
import { default as indexIHjJHfG7ovMeta } from "/home/runner/work/app/app/src/pages/athletes/[user]/index.vue?macro=true";
import { default as memberships9YUZA54PFhMeta } from "/home/runner/work/app/app/src/pages/athletes/[user]/memberships.vue?macro=true";
import { default as resultsjnr0g0KLO4Meta } from "/home/runner/work/app/app/src/pages/athletes/[user]/results.vue?macro=true";
import { default as starsu1sc8vI4VDMeta } from "/home/runner/work/app/app/src/pages/athletes/[user]/stars.vue?macro=true";
import { default as _91user_93SDuU0CF6gvMeta } from "/home/runner/work/app/app/src/pages/athletes/[user].vue?macro=true";
import { default as indexElzxiQbFApMeta } from "/home/runner/work/app/app/src/pages/athletes/index.vue?macro=true";
import { default as _91article_93kYmkydgBioMeta } from "/home/runner/work/app/app/src/pages/blog/[article].vue?macro=true";
import { default as indexxkOUIDhFuIMeta } from "/home/runner/work/app/app/src/pages/blog/index.vue?macro=true";
import { default as blogtojob0HK1vMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/blog.vue?macro=true";
import { default as competition_45planner10V1Qv8IkwMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/competition-planner.vue?macro=true";
import { default as contactv7VfnbmnmzMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/contact.vue?macro=true";
import { default as departmentsCmfvX32AgyMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/departments.vue?macro=true";
import { default as participationsfKzrydLMZaMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/events/[event]/participations.vue?macro=true";
import { default as indext9y2DHcGB1Meta } from "/home/runner/work/app/app/src/pages/clubs/[club]/events/index.vue?macro=true";
import { default as fansOgHcxG0eCjMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/fans.vue?macro=true";
import { default as feed6KGau6XVthMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/feed.vue?macro=true";
import { default as indexKypU0evdGcMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/index.vue?macro=true";
import { default as joinvjFznwzSKIMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/join.vue?macro=true";
import { default as assignrwJ3dC5XMVMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/members/assign.vue?macro=true";
import { default as indexcZeOZ193aeMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/members/index.vue?macro=true";
import { default as membershipZ5NX3mVFTGMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/membership.vue?macro=true";
import { default as news0fSPl5ds5DMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/news.vue?macro=true";
import { default as resultsPiabbjM9XRMeta } from "/home/runner/work/app/app/src/pages/clubs/[club]/results.vue?macro=true";
import { default as _91club_93lvfeM1MaCLMeta } from "/home/runner/work/app/app/src/pages/clubs/[club].vue?macro=true";
import { default as create3k2rW85kDQMeta } from "/home/runner/work/app/app/src/pages/clubs/create.vue?macro=true";
import { default as indexiPWYfyfvcUMeta } from "/home/runner/work/app/app/src/pages/clubs/index.vue?macro=true";
import { default as communitygNu7kb8AABMeta } from "/home/runner/work/app/app/src/pages/community.vue?macro=true";
import { default as createlnaaAweAyqMeta } from "/home/runner/work/app/app/src/pages/companies/create.vue?macro=true";
import { default as competition_45plannerXIzuBbP4SBMeta } from "/home/runner/work/app/app/src/pages/competition-planner.vue?macro=true";
import { default as confirmZyX2tOQqpfMeta } from "/home/runner/work/app/app/src/pages/confirm.vue?macro=true";
import { default as contactpXBpLZanlVMeta } from "/home/runner/work/app/app/src/pages/contact.vue?macro=true";
import { default as coplZ5qbmuPXxMeta } from "/home/runner/work/app/app/src/pages/cop.vue?macro=true";
import { default as dashboardS17QZJVydhMeta } from "/home/runner/work/app/app/src/pages/dashboard.vue?macro=true";
import { default as assignmentAHWZNNAgiUMeta } from "/home/runner/work/app/app/src/pages/events/[event]/assignment.vue?macro=true";
import { default as awardsgZ6qhbupOBMeta } from "/home/runner/work/app/app/src/pages/events/[event]/awards.vue?macro=true";
import { default as campaignsFKAtULfnBJMeta } from "/home/runner/work/app/app/src/pages/events/[event]/campaigns.vue?macro=true";
import { default as communitylZYRcas0pLMeta } from "/home/runner/work/app/app/src/pages/events/[event]/community.vue?macro=true";
import { default as contactoNymCpb30mMeta } from "/home/runner/work/app/app/src/pages/events/[event]/contact.vue?macro=true";
import { default as gtc1aSZmzZzZBMeta } from "/home/runner/work/app/app/src/pages/events/[event]/gtc.vue?macro=true";
import { default as impressionsh0BmFJPG9NMeta } from "/home/runner/work/app/app/src/pages/events/[event]/impressions.vue?macro=true";
import { default as indexnFQ7qTpyblMeta } from "/home/runner/work/app/app/src/pages/events/[event]/index.vue?macro=true";
import { default as _91job_93dDwZg9isffMeta } from "/home/runner/work/app/app/src/pages/events/[event]/jobs/[job].vue?macro=true";
import { default as indexYerGb0viNjMeta } from "/home/runner/work/app/app/src/pages/events/[event]/jobs/index.vue?macro=true";
import { default as joineQfMxYOidiMeta } from "/home/runner/work/app/app/src/pages/events/[event]/join.vue?macro=true";
import { default as livei03zejafaIMeta } from "/home/runner/work/app/app/src/pages/events/[event]/live.vue?macro=true";
import { default as map9iktEiyr3PMeta } from "/home/runner/work/app/app/src/pages/events/[event]/map.vue?macro=true";
import { default as assignXgQ3yfZNeIMeta } from "/home/runner/work/app/app/src/pages/events/[event]/members/assign.vue?macro=true";
import { default as newsOwnCZH2whjMeta } from "/home/runner/work/app/app/src/pages/events/[event]/news.vue?macro=true";
import { default as index8WpzEpWxA7Meta } from "/home/runner/work/app/app/src/pages/events/[event]/participate/index.vue?macro=true";
import { default as index69lp8kbMmqMeta } from "/home/runner/work/app/app/src/pages/events/[event]/participation-classes/index.vue?macro=true";
import { default as createvd4DjGJxhbMeta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/attempts/create.vue?macro=true";
import { default as campaignskldeFLVBW0Meta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/campaigns.vue?macro=true";
import { default as editmzfOVtxBgyMeta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/edit.vue?macro=true";
import { default as indexZC7GISVufCMeta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/index.vue?macro=true";
import { default as mapayBBY5KPaqMeta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/map.vue?macro=true";
import { default as paymentZ7AxDGbbrFMeta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/payment.vue?macro=true";
import { default as productswtDywd9Kn0Meta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/products.vue?macro=true";
import { default as resultsNVfnLT1IeNMeta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/results.vue?macro=true";
import { default as starttSRpc0FxFQMeta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/start.vue?macro=true";
import { default as tracking8mSiPkSVu6Meta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/tracking.vue?macro=true";
import { default as _91participation_93gkfPBEb8U4Meta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/[participation].vue?macro=true";
import { default as assignMAKOjFXtjaMeta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/assign.vue?macro=true";
import { default as indexGnp7IOM4gVMeta } from "/home/runner/work/app/app/src/pages/events/[event]/participations/index.vue?macro=true";
import { default as _91gallery_9336ERkWkwCxMeta } from "/home/runner/work/app/app/src/pages/events/[event]/photos/[gallery].vue?macro=true";
import { default as carth37kQrbsqcMeta } from "/home/runner/work/app/app/src/pages/events/[event]/photos/cart.vue?macro=true";
import { default as indexP6WOwcYmiYMeta } from "/home/runner/work/app/app/src/pages/events/[event]/photos/index.vue?macro=true";
import { default as photosdGAycOCARdMeta } from "/home/runner/work/app/app/src/pages/events/[event]/photos.vue?macro=true";
import { default as productsgxS4Cq2HlkMeta } from "/home/runner/work/app/app/src/pages/events/[event]/products.vue?macro=true";
import { default as create87GhxG4G1IMeta } from "/home/runner/work/app/app/src/pages/events/[event]/ratings/create.vue?macro=true";
import { default as indexHH48F7rosMMeta } from "/home/runner/work/app/app/src/pages/events/[event]/ratings/index.vue?macro=true";
import { default as _91group_93PIEUw0GTQpMeta } from "/home/runner/work/app/app/src/pages/events/[event]/results/[group].vue?macro=true";
import { default as indexIUwJP2qta4Meta } from "/home/runner/work/app/app/src/pages/events/[event]/results/index.vue?macro=true";
import { default as shopZgRb79KbMyMeta } from "/home/runner/work/app/app/src/pages/events/[event]/shop.vue?macro=true";
import { default as sponsorsfvHDo8yOLQMeta } from "/home/runner/work/app/app/src/pages/events/[event]/sponsors.vue?macro=true";
import { default as staySh8EK4LzZkMeta } from "/home/runner/work/app/app/src/pages/events/[event]/stay.vue?macro=true";
import { default as trackingU5bJt6tsQZMeta } from "/home/runner/work/app/app/src/pages/events/[event]/tracking.vue?macro=true";
import { default as _91event_93Bek69s1NqNMeta } from "/home/runner/work/app/app/src/pages/events/[event].vue?macro=true";
import { default as _91group_93fAhH6kWFUKMeta } from "/home/runner/work/app/app/src/pages/events/groups/[group].vue?macro=true";
import { default as indexk5FDPznNfGMeta } from "/home/runner/work/app/app/src/pages/events/index.vue?macro=true";
import { default as gtcvt9Fjv26eIMeta } from "/home/runner/work/app/app/src/pages/gtc.vue?macro=true";
import { default as imprinttybrKesDc7Meta } from "/home/runner/work/app/app/src/pages/imprint.vue?macro=true";
import { default as indexLgvpHsodJbMeta } from "/home/runner/work/app/app/src/pages/index.vue?macro=true";
import { default as _91posting_93icH5buWv4oMeta } from "/home/runner/work/app/app/src/pages/jobs/[type]/[model]/[posting].vue?macro=true";
import { default as indexU547GCrkq0Meta } from "/home/runner/work/app/app/src/pages/jobs/[type]/[model]/index.vue?macro=true";
import { default as indexhapMc1mLy4Meta } from "/home/runner/work/app/app/src/pages/jobs/[type]/index.vue?macro=true";
import { default as loginD4ncAobNn4Meta } from "/home/runner/work/app/app/src/pages/login.vue?macro=true";
import { default as _91event_93eWC1NMesZjMeta } from "/home/runner/work/app/app/src/pages/my/events/[event].vue?macro=true";
import { default as indexntIWJMv9p2Meta } from "/home/runner/work/app/app/src/pages/my/events/index.vue?macro=true";
import { default as achievementskPnnUnnULVMeta } from "/home/runner/work/app/app/src/pages/my/finisherpoints/achievements.vue?macro=true";
import { default as indexmwBCFcBHVmMeta } from "/home/runner/work/app/app/src/pages/my/finisherpoints/index.vue?macro=true";
import { default as info7ao38qBaPoMeta } from "/home/runner/work/app/app/src/pages/my/finisherpoints/info.vue?macro=true";
import { default as ordersz0eckaRw3VMeta } from "/home/runner/work/app/app/src/pages/my/finisherpoints/orders.vue?macro=true";
import { default as transactionsGJqfSG8AyeMeta } from "/home/runner/work/app/app/src/pages/my/finisherpoints/transactions.vue?macro=true";
import { default as finisherpointsSVs53aqLY2Meta } from "/home/runner/work/app/app/src/pages/my/finisherpoints.vue?macro=true";
import { default as indexvEOs46JYbAMeta } from "/home/runner/work/app/app/src/pages/my/index.vue?macro=true";
import { default as memberships1a2WTf1gPLMeta } from "/home/runner/work/app/app/src/pages/my/memberships.vue?macro=true";
import { default as indexPNHWmS8f5pMeta } from "/home/runner/work/app/app/src/pages/my/profile/index.vue?macro=true";
import { default as bank_45accountsukoA6HoHDZMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/bank-accounts.vue?macro=true";
import { default as editOccRVJSI4AMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/[request]/edit.vue?macro=true";
import { default as indexJ5NSy5Ka16Meta } from "/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/[request]/index.vue?macro=true";
import { default as createUsBYTUTaUBMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/create.vue?macro=true";
import { default as index70ukLfzd04Meta } from "/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/index.vue?macro=true";
import { default as edit1u7Zd3VKFiMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/blog/[article]/edit.vue?macro=true";
import { default as create7mY2dcGEHrMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/blog/create.vue?macro=true";
import { default as indexkH4tjnPAsoMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/blog/index.vue?macro=true";
import { default as childrenGVy7XcCn6BMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/children.vue?macro=true";
import { default as connectionsaNWuDaLyD5Meta } from "/home/runner/work/app/app/src/pages/my/profile/manage/connections.vue?macro=true";
import { default as contact14eUYzBtfUMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/contact.vue?macro=true";
import { default as dataCE1LcYOZjzMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/data.vue?macro=true";
import { default as description5kWFNwoqe4Meta } from "/home/runner/work/app/app/src/pages/my/profile/manage/description.vue?macro=true";
import { default as indexrPh6IUOO6CMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/index.vue?macro=true";
import { default as linksGdpEON6MSdMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/links.vue?macro=true";
import { default as parents8KXqhn38Y6Meta } from "/home/runner/work/app/app/src/pages/my/profile/manage/parents.vue?macro=true";
import { default as passwordzkvBKlty1KMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/password.vue?macro=true";
import { default as payment_45informationvmQMYwyV9UMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/payment-information.vue?macro=true";
import { default as index7Q6h1QovTgMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/settings/index.vue?macro=true";
import { default as _91type_93WK0c3N7SJRMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/[type].vue?macro=true";
import { default as devicesgELcUXxOPWMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/devices.vue?macro=true";
import { default as index8tE0SsCx7YMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/index.vue?macro=true";
import { default as privacyIX0bRvU07HMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/settings/privacy.vue?macro=true";
import { default as sport_45types8HUd3TfGzrMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage/sport-types.vue?macro=true";
import { default as manageB5jVKDiwFFMeta } from "/home/runner/work/app/app/src/pages/my/profile/manage.vue?macro=true";
import { default as starsquzETRblYIMeta } from "/home/runner/work/app/app/src/pages/my/stars.vue?macro=true";
import { default as _91article_93wYDEzRUatnMeta } from "/home/runner/work/app/app/src/pages/news/[article].vue?macro=true";
import { default as indexBcxZoT8oUKMeta } from "/home/runner/work/app/app/src/pages/news/index.vue?macro=true";
import { default as notificationsET8Jt3ATWfMeta } from "/home/runner/work/app/app/src/pages/notifications.vue?macro=true";
import { default as operation_45plannero9l92BtUFgMeta } from "/home/runner/work/app/app/src/pages/operation-planner.vue?macro=true";
import { default as _91order_93ZdRmpGAqbXMeta } from "/home/runner/work/app/app/src/pages/orders/[order].vue?macro=true";
import { default as password8yLouPs9XaMeta } from "/home/runner/work/app/app/src/pages/password.vue?macro=true";
import { default as indexNAxJvs3P2DMeta } from "/home/runner/work/app/app/src/pages/pay/[order]/index.vue?macro=true";
import { default as processeTx51X4yr0Meta } from "/home/runner/work/app/app/src/pages/pay/[order]/process.vue?macro=true";
import { default as privacy_45policylZhrIpUwcfMeta } from "/home/runner/work/app/app/src/pages/privacy-policy.vue?macro=true";
import { default as registeroyHJqz45klMeta } from "/home/runner/work/app/app/src/pages/register.vue?macro=true";
import { default as reset_45passwordgKUfEH2U9XMeta } from "/home/runner/work/app/app/src/pages/reset-password.vue?macro=true";
import { default as resultshqvMPFGLeFMeta } from "/home/runner/work/app/app/src/pages/results.vue?macro=true";
import { default as search0rJlMV9ieAMeta } from "/home/runner/work/app/app/src/pages/search.vue?macro=true";
import { default as sports_45mapXzKbJm6fpVMeta } from "/home/runner/work/app/app/src/pages/sports-map.vue?macro=true";
import { default as _91sport_93JZo5J0an8LMeta } from "/home/runner/work/app/app/src/pages/sports/[sport].vue?macro=true";
import { default as indexIuPVxfCMEdMeta } from "/home/runner/work/app/app/src/pages/sports/index.vue?macro=true";
import { default as startk9Ksk7S5uAMeta } from "/home/runner/work/app/app/src/pages/start.vue?macro=true";
import { default as supportKhFNpPUlZiMeta } from "/home/runner/work/app/app/src/pages/support.vue?macro=true";
import { default as indexDqsHUt1UgEMeta } from "/home/runner/work/app/app/src/pages/team/index.vue?macro=true";
import { default as unsubscribeD5N9Wv7eKRMeta } from "/home/runner/work/app/app/src/pages/unsubscribe.vue?macro=true";
export default [
  {
    name: _91user_93SDuU0CF6gvMeta?.name ?? undefined,
    path: _91user_93SDuU0CF6gvMeta?.path ?? "/athletes/:user()",
    meta: _91user_93SDuU0CF6gvMeta || {},
    alias: _91user_93SDuU0CF6gvMeta?.alias || [],
    redirect: _91user_93SDuU0CF6gvMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user].vue").then(m => m.default || m),
    children: [
  {
    name: blogVGXdATM0f8Meta?.name ?? "athletes-user-blog___de",
    path: blogVGXdATM0f8Meta?.path ?? "blog",
    meta: blogVGXdATM0f8Meta || {},
    alias: blogVGXdATM0f8Meta?.alias || [],
    redirect: blogVGXdATM0f8Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/blog.vue").then(m => m.default || m)
  },
  {
    name: events4NHz2YexJ2Meta?.name ?? "athletes-user-events___de",
    path: events4NHz2YexJ2Meta?.path ?? "events",
    meta: events4NHz2YexJ2Meta || {},
    alias: events4NHz2YexJ2Meta?.alias || [],
    redirect: events4NHz2YexJ2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/events.vue").then(m => m.default || m)
  },
  {
    name: fansflyU0xAs8SMeta?.name ?? "athletes-user-fans___de",
    path: fansflyU0xAs8SMeta?.path ?? "fans",
    meta: fansflyU0xAs8SMeta || {},
    alias: fansflyU0xAs8SMeta?.alias || [],
    redirect: fansflyU0xAs8SMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/fans.vue").then(m => m.default || m)
  },
  {
    name: feeduSiB3NHYKHMeta?.name ?? "athletes-user-feed___de",
    path: feeduSiB3NHYKHMeta?.path ?? "feed",
    meta: feeduSiB3NHYKHMeta || {},
    alias: feeduSiB3NHYKHMeta?.alias || [],
    redirect: feeduSiB3NHYKHMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/feed.vue").then(m => m.default || m)
  },
  {
    name: indexIHjJHfG7ovMeta?.name ?? "athletes-user___de",
    path: indexIHjJHfG7ovMeta?.path ?? "",
    meta: indexIHjJHfG7ovMeta || {},
    alias: indexIHjJHfG7ovMeta?.alias || [],
    redirect: indexIHjJHfG7ovMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/index.vue").then(m => m.default || m)
  },
  {
    name: memberships9YUZA54PFhMeta?.name ?? "athletes-user-memberships___de",
    path: memberships9YUZA54PFhMeta?.path ?? "memberships",
    meta: memberships9YUZA54PFhMeta || {},
    alias: memberships9YUZA54PFhMeta?.alias || [],
    redirect: memberships9YUZA54PFhMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/memberships.vue").then(m => m.default || m)
  },
  {
    name: resultsjnr0g0KLO4Meta?.name ?? "athletes-user-results___de",
    path: resultsjnr0g0KLO4Meta?.path ?? "results",
    meta: resultsjnr0g0KLO4Meta || {},
    alias: resultsjnr0g0KLO4Meta?.alias || [],
    redirect: resultsjnr0g0KLO4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/results.vue").then(m => m.default || m)
  },
  {
    name: starsu1sc8vI4VDMeta?.name ?? "athletes-user-stars___de",
    path: starsu1sc8vI4VDMeta?.path ?? "stars",
    meta: starsu1sc8vI4VDMeta || {},
    alias: starsu1sc8vI4VDMeta?.alias || [],
    redirect: starsu1sc8vI4VDMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/stars.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91user_93SDuU0CF6gvMeta?.name ?? undefined,
    path: _91user_93SDuU0CF6gvMeta?.path ?? "/en/athletes/:user()",
    meta: _91user_93SDuU0CF6gvMeta || {},
    alias: _91user_93SDuU0CF6gvMeta?.alias || [],
    redirect: _91user_93SDuU0CF6gvMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user].vue").then(m => m.default || m),
    children: [
  {
    name: blogVGXdATM0f8Meta?.name ?? "athletes-user-blog___en",
    path: blogVGXdATM0f8Meta?.path ?? "blog",
    meta: blogVGXdATM0f8Meta || {},
    alias: blogVGXdATM0f8Meta?.alias || [],
    redirect: blogVGXdATM0f8Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/blog.vue").then(m => m.default || m)
  },
  {
    name: events4NHz2YexJ2Meta?.name ?? "athletes-user-events___en",
    path: events4NHz2YexJ2Meta?.path ?? "events",
    meta: events4NHz2YexJ2Meta || {},
    alias: events4NHz2YexJ2Meta?.alias || [],
    redirect: events4NHz2YexJ2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/events.vue").then(m => m.default || m)
  },
  {
    name: fansflyU0xAs8SMeta?.name ?? "athletes-user-fans___en",
    path: fansflyU0xAs8SMeta?.path ?? "fans",
    meta: fansflyU0xAs8SMeta || {},
    alias: fansflyU0xAs8SMeta?.alias || [],
    redirect: fansflyU0xAs8SMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/fans.vue").then(m => m.default || m)
  },
  {
    name: feeduSiB3NHYKHMeta?.name ?? "athletes-user-feed___en",
    path: feeduSiB3NHYKHMeta?.path ?? "feed",
    meta: feeduSiB3NHYKHMeta || {},
    alias: feeduSiB3NHYKHMeta?.alias || [],
    redirect: feeduSiB3NHYKHMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/feed.vue").then(m => m.default || m)
  },
  {
    name: indexIHjJHfG7ovMeta?.name ?? "athletes-user___en",
    path: indexIHjJHfG7ovMeta?.path ?? "",
    meta: indexIHjJHfG7ovMeta || {},
    alias: indexIHjJHfG7ovMeta?.alias || [],
    redirect: indexIHjJHfG7ovMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/index.vue").then(m => m.default || m)
  },
  {
    name: memberships9YUZA54PFhMeta?.name ?? "athletes-user-memberships___en",
    path: memberships9YUZA54PFhMeta?.path ?? "memberships",
    meta: memberships9YUZA54PFhMeta || {},
    alias: memberships9YUZA54PFhMeta?.alias || [],
    redirect: memberships9YUZA54PFhMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/memberships.vue").then(m => m.default || m)
  },
  {
    name: resultsjnr0g0KLO4Meta?.name ?? "athletes-user-results___en",
    path: resultsjnr0g0KLO4Meta?.path ?? "results",
    meta: resultsjnr0g0KLO4Meta || {},
    alias: resultsjnr0g0KLO4Meta?.alias || [],
    redirect: resultsjnr0g0KLO4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/results.vue").then(m => m.default || m)
  },
  {
    name: starsu1sc8vI4VDMeta?.name ?? "athletes-user-stars___en",
    path: starsu1sc8vI4VDMeta?.path ?? "stars",
    meta: starsu1sc8vI4VDMeta || {},
    alias: starsu1sc8vI4VDMeta?.alias || [],
    redirect: starsu1sc8vI4VDMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/stars.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91user_93SDuU0CF6gvMeta?.name ?? undefined,
    path: _91user_93SDuU0CF6gvMeta?.path ?? "/nl/athletes/:user()",
    meta: _91user_93SDuU0CF6gvMeta || {},
    alias: _91user_93SDuU0CF6gvMeta?.alias || [],
    redirect: _91user_93SDuU0CF6gvMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user].vue").then(m => m.default || m),
    children: [
  {
    name: blogVGXdATM0f8Meta?.name ?? "athletes-user-blog___nl",
    path: blogVGXdATM0f8Meta?.path ?? "blog",
    meta: blogVGXdATM0f8Meta || {},
    alias: blogVGXdATM0f8Meta?.alias || [],
    redirect: blogVGXdATM0f8Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/blog.vue").then(m => m.default || m)
  },
  {
    name: events4NHz2YexJ2Meta?.name ?? "athletes-user-events___nl",
    path: events4NHz2YexJ2Meta?.path ?? "events",
    meta: events4NHz2YexJ2Meta || {},
    alias: events4NHz2YexJ2Meta?.alias || [],
    redirect: events4NHz2YexJ2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/events.vue").then(m => m.default || m)
  },
  {
    name: fansflyU0xAs8SMeta?.name ?? "athletes-user-fans___nl",
    path: fansflyU0xAs8SMeta?.path ?? "fans",
    meta: fansflyU0xAs8SMeta || {},
    alias: fansflyU0xAs8SMeta?.alias || [],
    redirect: fansflyU0xAs8SMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/fans.vue").then(m => m.default || m)
  },
  {
    name: feeduSiB3NHYKHMeta?.name ?? "athletes-user-feed___nl",
    path: feeduSiB3NHYKHMeta?.path ?? "feed",
    meta: feeduSiB3NHYKHMeta || {},
    alias: feeduSiB3NHYKHMeta?.alias || [],
    redirect: feeduSiB3NHYKHMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/feed.vue").then(m => m.default || m)
  },
  {
    name: indexIHjJHfG7ovMeta?.name ?? "athletes-user___nl",
    path: indexIHjJHfG7ovMeta?.path ?? "",
    meta: indexIHjJHfG7ovMeta || {},
    alias: indexIHjJHfG7ovMeta?.alias || [],
    redirect: indexIHjJHfG7ovMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/index.vue").then(m => m.default || m)
  },
  {
    name: memberships9YUZA54PFhMeta?.name ?? "athletes-user-memberships___nl",
    path: memberships9YUZA54PFhMeta?.path ?? "memberships",
    meta: memberships9YUZA54PFhMeta || {},
    alias: memberships9YUZA54PFhMeta?.alias || [],
    redirect: memberships9YUZA54PFhMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/memberships.vue").then(m => m.default || m)
  },
  {
    name: resultsjnr0g0KLO4Meta?.name ?? "athletes-user-results___nl",
    path: resultsjnr0g0KLO4Meta?.path ?? "results",
    meta: resultsjnr0g0KLO4Meta || {},
    alias: resultsjnr0g0KLO4Meta?.alias || [],
    redirect: resultsjnr0g0KLO4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/results.vue").then(m => m.default || m)
  },
  {
    name: starsu1sc8vI4VDMeta?.name ?? "athletes-user-stars___nl",
    path: starsu1sc8vI4VDMeta?.path ?? "stars",
    meta: starsu1sc8vI4VDMeta || {},
    alias: starsu1sc8vI4VDMeta?.alias || [],
    redirect: starsu1sc8vI4VDMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/stars.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91user_93SDuU0CF6gvMeta?.name ?? undefined,
    path: _91user_93SDuU0CF6gvMeta?.path ?? "/cs/athletes/:user()",
    meta: _91user_93SDuU0CF6gvMeta || {},
    alias: _91user_93SDuU0CF6gvMeta?.alias || [],
    redirect: _91user_93SDuU0CF6gvMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user].vue").then(m => m.default || m),
    children: [
  {
    name: blogVGXdATM0f8Meta?.name ?? "athletes-user-blog___cs",
    path: blogVGXdATM0f8Meta?.path ?? "blog",
    meta: blogVGXdATM0f8Meta || {},
    alias: blogVGXdATM0f8Meta?.alias || [],
    redirect: blogVGXdATM0f8Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/blog.vue").then(m => m.default || m)
  },
  {
    name: events4NHz2YexJ2Meta?.name ?? "athletes-user-events___cs",
    path: events4NHz2YexJ2Meta?.path ?? "events",
    meta: events4NHz2YexJ2Meta || {},
    alias: events4NHz2YexJ2Meta?.alias || [],
    redirect: events4NHz2YexJ2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/events.vue").then(m => m.default || m)
  },
  {
    name: fansflyU0xAs8SMeta?.name ?? "athletes-user-fans___cs",
    path: fansflyU0xAs8SMeta?.path ?? "fans",
    meta: fansflyU0xAs8SMeta || {},
    alias: fansflyU0xAs8SMeta?.alias || [],
    redirect: fansflyU0xAs8SMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/fans.vue").then(m => m.default || m)
  },
  {
    name: feeduSiB3NHYKHMeta?.name ?? "athletes-user-feed___cs",
    path: feeduSiB3NHYKHMeta?.path ?? "feed",
    meta: feeduSiB3NHYKHMeta || {},
    alias: feeduSiB3NHYKHMeta?.alias || [],
    redirect: feeduSiB3NHYKHMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/feed.vue").then(m => m.default || m)
  },
  {
    name: indexIHjJHfG7ovMeta?.name ?? "athletes-user___cs",
    path: indexIHjJHfG7ovMeta?.path ?? "",
    meta: indexIHjJHfG7ovMeta || {},
    alias: indexIHjJHfG7ovMeta?.alias || [],
    redirect: indexIHjJHfG7ovMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/index.vue").then(m => m.default || m)
  },
  {
    name: memberships9YUZA54PFhMeta?.name ?? "athletes-user-memberships___cs",
    path: memberships9YUZA54PFhMeta?.path ?? "memberships",
    meta: memberships9YUZA54PFhMeta || {},
    alias: memberships9YUZA54PFhMeta?.alias || [],
    redirect: memberships9YUZA54PFhMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/memberships.vue").then(m => m.default || m)
  },
  {
    name: resultsjnr0g0KLO4Meta?.name ?? "athletes-user-results___cs",
    path: resultsjnr0g0KLO4Meta?.path ?? "results",
    meta: resultsjnr0g0KLO4Meta || {},
    alias: resultsjnr0g0KLO4Meta?.alias || [],
    redirect: resultsjnr0g0KLO4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/results.vue").then(m => m.default || m)
  },
  {
    name: starsu1sc8vI4VDMeta?.name ?? "athletes-user-stars___cs",
    path: starsu1sc8vI4VDMeta?.path ?? "stars",
    meta: starsu1sc8vI4VDMeta || {},
    alias: starsu1sc8vI4VDMeta?.alias || [],
    redirect: starsu1sc8vI4VDMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/[user]/stars.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexElzxiQbFApMeta?.name ?? "athletes___de",
    path: indexElzxiQbFApMeta?.path ?? "/athletes",
    meta: indexElzxiQbFApMeta || {},
    alias: indexElzxiQbFApMeta?.alias || [],
    redirect: indexElzxiQbFApMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexElzxiQbFApMeta?.name ?? "athletes___en",
    path: indexElzxiQbFApMeta?.path ?? "/en/athletes",
    meta: indexElzxiQbFApMeta || {},
    alias: indexElzxiQbFApMeta?.alias || [],
    redirect: indexElzxiQbFApMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexElzxiQbFApMeta?.name ?? "athletes___nl",
    path: indexElzxiQbFApMeta?.path ?? "/nl/athletes",
    meta: indexElzxiQbFApMeta || {},
    alias: indexElzxiQbFApMeta?.alias || [],
    redirect: indexElzxiQbFApMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexElzxiQbFApMeta?.name ?? "athletes___cs",
    path: indexElzxiQbFApMeta?.path ?? "/cs/athletes",
    meta: indexElzxiQbFApMeta || {},
    alias: indexElzxiQbFApMeta?.alias || [],
    redirect: indexElzxiQbFApMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: _91article_93kYmkydgBioMeta?.name ?? "blog-article___de",
    path: _91article_93kYmkydgBioMeta?.path ?? "/blog/:article()",
    meta: _91article_93kYmkydgBioMeta || {},
    alias: _91article_93kYmkydgBioMeta?.alias || [],
    redirect: _91article_93kYmkydgBioMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/blog/[article].vue").then(m => m.default || m)
  },
  {
    name: _91article_93kYmkydgBioMeta?.name ?? "blog-article___en",
    path: _91article_93kYmkydgBioMeta?.path ?? "/en/blog/:article()",
    meta: _91article_93kYmkydgBioMeta || {},
    alias: _91article_93kYmkydgBioMeta?.alias || [],
    redirect: _91article_93kYmkydgBioMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/blog/[article].vue").then(m => m.default || m)
  },
  {
    name: _91article_93kYmkydgBioMeta?.name ?? "blog-article___nl",
    path: _91article_93kYmkydgBioMeta?.path ?? "/nl/blog/:article()",
    meta: _91article_93kYmkydgBioMeta || {},
    alias: _91article_93kYmkydgBioMeta?.alias || [],
    redirect: _91article_93kYmkydgBioMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/blog/[article].vue").then(m => m.default || m)
  },
  {
    name: _91article_93kYmkydgBioMeta?.name ?? "blog-article___cs",
    path: _91article_93kYmkydgBioMeta?.path ?? "/cs/blog/:article()",
    meta: _91article_93kYmkydgBioMeta || {},
    alias: _91article_93kYmkydgBioMeta?.alias || [],
    redirect: _91article_93kYmkydgBioMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/blog/[article].vue").then(m => m.default || m)
  },
  {
    name: indexxkOUIDhFuIMeta?.name ?? "blog___de",
    path: indexxkOUIDhFuIMeta?.path ?? "/blog",
    meta: indexxkOUIDhFuIMeta || {},
    alias: indexxkOUIDhFuIMeta?.alias || [],
    redirect: indexxkOUIDhFuIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexxkOUIDhFuIMeta?.name ?? "blog___en",
    path: indexxkOUIDhFuIMeta?.path ?? "/en/blog",
    meta: indexxkOUIDhFuIMeta || {},
    alias: indexxkOUIDhFuIMeta?.alias || [],
    redirect: indexxkOUIDhFuIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexxkOUIDhFuIMeta?.name ?? "blog___nl",
    path: indexxkOUIDhFuIMeta?.path ?? "/nl/blog",
    meta: indexxkOUIDhFuIMeta || {},
    alias: indexxkOUIDhFuIMeta?.alias || [],
    redirect: indexxkOUIDhFuIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexxkOUIDhFuIMeta?.name ?? "blog___cs",
    path: indexxkOUIDhFuIMeta?.path ?? "/cs/blog",
    meta: indexxkOUIDhFuIMeta || {},
    alias: indexxkOUIDhFuIMeta?.alias || [],
    redirect: indexxkOUIDhFuIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91club_93lvfeM1MaCLMeta?.name ?? undefined,
    path: _91club_93lvfeM1MaCLMeta?.path ?? "/clubs/:club()",
    meta: _91club_93lvfeM1MaCLMeta || {},
    alias: _91club_93lvfeM1MaCLMeta?.alias || [],
    redirect: _91club_93lvfeM1MaCLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club].vue").then(m => m.default || m),
    children: [
  {
    name: blogtojob0HK1vMeta?.name ?? "clubs-club-blog___de",
    path: blogtojob0HK1vMeta?.path ?? "blog",
    meta: blogtojob0HK1vMeta || {},
    alias: blogtojob0HK1vMeta?.alias || [],
    redirect: blogtojob0HK1vMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/blog.vue").then(m => m.default || m)
  },
  {
    name: competition_45planner10V1Qv8IkwMeta?.name ?? "clubs-club-competition-planner___de",
    path: competition_45planner10V1Qv8IkwMeta?.path ?? "competition-planner",
    meta: competition_45planner10V1Qv8IkwMeta || {},
    alias: competition_45planner10V1Qv8IkwMeta?.alias || [],
    redirect: competition_45planner10V1Qv8IkwMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/competition-planner.vue").then(m => m.default || m)
  },
  {
    name: contactv7VfnbmnmzMeta?.name ?? "clubs-club-contact___de",
    path: contactv7VfnbmnmzMeta?.path ?? "contact",
    meta: contactv7VfnbmnmzMeta || {},
    alias: contactv7VfnbmnmzMeta?.alias || [],
    redirect: contactv7VfnbmnmzMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/contact.vue").then(m => m.default || m)
  },
  {
    name: departmentsCmfvX32AgyMeta?.name ?? "clubs-club-departments___de",
    path: departmentsCmfvX32AgyMeta?.path ?? "departments",
    meta: departmentsCmfvX32AgyMeta || {},
    alias: departmentsCmfvX32AgyMeta?.alias || [],
    redirect: departmentsCmfvX32AgyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/departments.vue").then(m => m.default || m)
  },
  {
    name: participationsfKzrydLMZaMeta?.name ?? "clubs-club-events-event-participations___de",
    path: participationsfKzrydLMZaMeta?.path ?? "events/:event()/participations",
    meta: participationsfKzrydLMZaMeta || {},
    alias: participationsfKzrydLMZaMeta?.alias || [],
    redirect: participationsfKzrydLMZaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/events/[event]/participations.vue").then(m => m.default || m)
  },
  {
    name: indext9y2DHcGB1Meta?.name ?? "clubs-club-events___de",
    path: indext9y2DHcGB1Meta?.path ?? "events",
    meta: indext9y2DHcGB1Meta || {},
    alias: indext9y2DHcGB1Meta?.alias || [],
    redirect: indext9y2DHcGB1Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/events/index.vue").then(m => m.default || m)
  },
  {
    name: fansOgHcxG0eCjMeta?.name ?? "clubs-club-fans___de",
    path: fansOgHcxG0eCjMeta?.path ?? "fans",
    meta: fansOgHcxG0eCjMeta || {},
    alias: fansOgHcxG0eCjMeta?.alias || [],
    redirect: fansOgHcxG0eCjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/fans.vue").then(m => m.default || m)
  },
  {
    name: feed6KGau6XVthMeta?.name ?? "clubs-club-feed___de",
    path: feed6KGau6XVthMeta?.path ?? "feed",
    meta: feed6KGau6XVthMeta || {},
    alias: feed6KGau6XVthMeta?.alias || [],
    redirect: feed6KGau6XVthMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/feed.vue").then(m => m.default || m)
  },
  {
    name: indexKypU0evdGcMeta?.name ?? "clubs-club___de",
    path: indexKypU0evdGcMeta?.path ?? "",
    meta: indexKypU0evdGcMeta || {},
    alias: indexKypU0evdGcMeta?.alias || [],
    redirect: indexKypU0evdGcMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/index.vue").then(m => m.default || m)
  },
  {
    name: joinvjFznwzSKIMeta?.name ?? "clubs-club-join___de",
    path: joinvjFznwzSKIMeta?.path ?? "join",
    meta: joinvjFznwzSKIMeta || {},
    alias: joinvjFznwzSKIMeta?.alias || [],
    redirect: joinvjFznwzSKIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/join.vue").then(m => m.default || m)
  },
  {
    name: assignrwJ3dC5XMVMeta?.name ?? "clubs-club-members-assign___de",
    path: assignrwJ3dC5XMVMeta?.path ?? "members/assign",
    meta: assignrwJ3dC5XMVMeta || {},
    alias: assignrwJ3dC5XMVMeta?.alias || [],
    redirect: assignrwJ3dC5XMVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/members/assign.vue").then(m => m.default || m)
  },
  {
    name: indexcZeOZ193aeMeta?.name ?? "clubs-club-members___de",
    path: indexcZeOZ193aeMeta?.path ?? "members",
    meta: indexcZeOZ193aeMeta || {},
    alias: indexcZeOZ193aeMeta?.alias || [],
    redirect: indexcZeOZ193aeMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/members/index.vue").then(m => m.default || m)
  },
  {
    name: membershipZ5NX3mVFTGMeta?.name ?? "clubs-club-membership___de",
    path: membershipZ5NX3mVFTGMeta?.path ?? "membership",
    meta: membershipZ5NX3mVFTGMeta || {},
    alias: membershipZ5NX3mVFTGMeta?.alias || [],
    redirect: membershipZ5NX3mVFTGMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/membership.vue").then(m => m.default || m)
  },
  {
    name: news0fSPl5ds5DMeta?.name ?? "clubs-club-news___de",
    path: news0fSPl5ds5DMeta?.path ?? "news",
    meta: news0fSPl5ds5DMeta || {},
    alias: news0fSPl5ds5DMeta?.alias || [],
    redirect: news0fSPl5ds5DMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/news.vue").then(m => m.default || m)
  },
  {
    name: resultsPiabbjM9XRMeta?.name ?? "clubs-club-results___de",
    path: resultsPiabbjM9XRMeta?.path ?? "results",
    meta: resultsPiabbjM9XRMeta || {},
    alias: resultsPiabbjM9XRMeta?.alias || [],
    redirect: resultsPiabbjM9XRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/results.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91club_93lvfeM1MaCLMeta?.name ?? undefined,
    path: _91club_93lvfeM1MaCLMeta?.path ?? "/en/clubs/:club()",
    meta: _91club_93lvfeM1MaCLMeta || {},
    alias: _91club_93lvfeM1MaCLMeta?.alias || [],
    redirect: _91club_93lvfeM1MaCLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club].vue").then(m => m.default || m),
    children: [
  {
    name: blogtojob0HK1vMeta?.name ?? "clubs-club-blog___en",
    path: blogtojob0HK1vMeta?.path ?? "blog",
    meta: blogtojob0HK1vMeta || {},
    alias: blogtojob0HK1vMeta?.alias || [],
    redirect: blogtojob0HK1vMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/blog.vue").then(m => m.default || m)
  },
  {
    name: competition_45planner10V1Qv8IkwMeta?.name ?? "clubs-club-competition-planner___en",
    path: competition_45planner10V1Qv8IkwMeta?.path ?? "competition-planner",
    meta: competition_45planner10V1Qv8IkwMeta || {},
    alias: competition_45planner10V1Qv8IkwMeta?.alias || [],
    redirect: competition_45planner10V1Qv8IkwMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/competition-planner.vue").then(m => m.default || m)
  },
  {
    name: contactv7VfnbmnmzMeta?.name ?? "clubs-club-contact___en",
    path: contactv7VfnbmnmzMeta?.path ?? "contact",
    meta: contactv7VfnbmnmzMeta || {},
    alias: contactv7VfnbmnmzMeta?.alias || [],
    redirect: contactv7VfnbmnmzMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/contact.vue").then(m => m.default || m)
  },
  {
    name: departmentsCmfvX32AgyMeta?.name ?? "clubs-club-departments___en",
    path: departmentsCmfvX32AgyMeta?.path ?? "departments",
    meta: departmentsCmfvX32AgyMeta || {},
    alias: departmentsCmfvX32AgyMeta?.alias || [],
    redirect: departmentsCmfvX32AgyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/departments.vue").then(m => m.default || m)
  },
  {
    name: participationsfKzrydLMZaMeta?.name ?? "clubs-club-events-event-participations___en",
    path: participationsfKzrydLMZaMeta?.path ?? "events/:event()/participations",
    meta: participationsfKzrydLMZaMeta || {},
    alias: participationsfKzrydLMZaMeta?.alias || [],
    redirect: participationsfKzrydLMZaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/events/[event]/participations.vue").then(m => m.default || m)
  },
  {
    name: indext9y2DHcGB1Meta?.name ?? "clubs-club-events___en",
    path: indext9y2DHcGB1Meta?.path ?? "events",
    meta: indext9y2DHcGB1Meta || {},
    alias: indext9y2DHcGB1Meta?.alias || [],
    redirect: indext9y2DHcGB1Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/events/index.vue").then(m => m.default || m)
  },
  {
    name: fansOgHcxG0eCjMeta?.name ?? "clubs-club-fans___en",
    path: fansOgHcxG0eCjMeta?.path ?? "fans",
    meta: fansOgHcxG0eCjMeta || {},
    alias: fansOgHcxG0eCjMeta?.alias || [],
    redirect: fansOgHcxG0eCjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/fans.vue").then(m => m.default || m)
  },
  {
    name: feed6KGau6XVthMeta?.name ?? "clubs-club-feed___en",
    path: feed6KGau6XVthMeta?.path ?? "feed",
    meta: feed6KGau6XVthMeta || {},
    alias: feed6KGau6XVthMeta?.alias || [],
    redirect: feed6KGau6XVthMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/feed.vue").then(m => m.default || m)
  },
  {
    name: indexKypU0evdGcMeta?.name ?? "clubs-club___en",
    path: indexKypU0evdGcMeta?.path ?? "",
    meta: indexKypU0evdGcMeta || {},
    alias: indexKypU0evdGcMeta?.alias || [],
    redirect: indexKypU0evdGcMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/index.vue").then(m => m.default || m)
  },
  {
    name: joinvjFznwzSKIMeta?.name ?? "clubs-club-join___en",
    path: joinvjFznwzSKIMeta?.path ?? "join",
    meta: joinvjFznwzSKIMeta || {},
    alias: joinvjFznwzSKIMeta?.alias || [],
    redirect: joinvjFznwzSKIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/join.vue").then(m => m.default || m)
  },
  {
    name: assignrwJ3dC5XMVMeta?.name ?? "clubs-club-members-assign___en",
    path: assignrwJ3dC5XMVMeta?.path ?? "members/assign",
    meta: assignrwJ3dC5XMVMeta || {},
    alias: assignrwJ3dC5XMVMeta?.alias || [],
    redirect: assignrwJ3dC5XMVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/members/assign.vue").then(m => m.default || m)
  },
  {
    name: indexcZeOZ193aeMeta?.name ?? "clubs-club-members___en",
    path: indexcZeOZ193aeMeta?.path ?? "members",
    meta: indexcZeOZ193aeMeta || {},
    alias: indexcZeOZ193aeMeta?.alias || [],
    redirect: indexcZeOZ193aeMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/members/index.vue").then(m => m.default || m)
  },
  {
    name: membershipZ5NX3mVFTGMeta?.name ?? "clubs-club-membership___en",
    path: membershipZ5NX3mVFTGMeta?.path ?? "membership",
    meta: membershipZ5NX3mVFTGMeta || {},
    alias: membershipZ5NX3mVFTGMeta?.alias || [],
    redirect: membershipZ5NX3mVFTGMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/membership.vue").then(m => m.default || m)
  },
  {
    name: news0fSPl5ds5DMeta?.name ?? "clubs-club-news___en",
    path: news0fSPl5ds5DMeta?.path ?? "news",
    meta: news0fSPl5ds5DMeta || {},
    alias: news0fSPl5ds5DMeta?.alias || [],
    redirect: news0fSPl5ds5DMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/news.vue").then(m => m.default || m)
  },
  {
    name: resultsPiabbjM9XRMeta?.name ?? "clubs-club-results___en",
    path: resultsPiabbjM9XRMeta?.path ?? "results",
    meta: resultsPiabbjM9XRMeta || {},
    alias: resultsPiabbjM9XRMeta?.alias || [],
    redirect: resultsPiabbjM9XRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/results.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91club_93lvfeM1MaCLMeta?.name ?? undefined,
    path: _91club_93lvfeM1MaCLMeta?.path ?? "/nl/clubs/:club()",
    meta: _91club_93lvfeM1MaCLMeta || {},
    alias: _91club_93lvfeM1MaCLMeta?.alias || [],
    redirect: _91club_93lvfeM1MaCLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club].vue").then(m => m.default || m),
    children: [
  {
    name: blogtojob0HK1vMeta?.name ?? "clubs-club-blog___nl",
    path: blogtojob0HK1vMeta?.path ?? "blog",
    meta: blogtojob0HK1vMeta || {},
    alias: blogtojob0HK1vMeta?.alias || [],
    redirect: blogtojob0HK1vMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/blog.vue").then(m => m.default || m)
  },
  {
    name: competition_45planner10V1Qv8IkwMeta?.name ?? "clubs-club-competition-planner___nl",
    path: competition_45planner10V1Qv8IkwMeta?.path ?? "competition-planner",
    meta: competition_45planner10V1Qv8IkwMeta || {},
    alias: competition_45planner10V1Qv8IkwMeta?.alias || [],
    redirect: competition_45planner10V1Qv8IkwMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/competition-planner.vue").then(m => m.default || m)
  },
  {
    name: contactv7VfnbmnmzMeta?.name ?? "clubs-club-contact___nl",
    path: contactv7VfnbmnmzMeta?.path ?? "contact",
    meta: contactv7VfnbmnmzMeta || {},
    alias: contactv7VfnbmnmzMeta?.alias || [],
    redirect: contactv7VfnbmnmzMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/contact.vue").then(m => m.default || m)
  },
  {
    name: departmentsCmfvX32AgyMeta?.name ?? "clubs-club-departments___nl",
    path: departmentsCmfvX32AgyMeta?.path ?? "departments",
    meta: departmentsCmfvX32AgyMeta || {},
    alias: departmentsCmfvX32AgyMeta?.alias || [],
    redirect: departmentsCmfvX32AgyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/departments.vue").then(m => m.default || m)
  },
  {
    name: participationsfKzrydLMZaMeta?.name ?? "clubs-club-events-event-participations___nl",
    path: participationsfKzrydLMZaMeta?.path ?? "events/:event()/participations",
    meta: participationsfKzrydLMZaMeta || {},
    alias: participationsfKzrydLMZaMeta?.alias || [],
    redirect: participationsfKzrydLMZaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/events/[event]/participations.vue").then(m => m.default || m)
  },
  {
    name: indext9y2DHcGB1Meta?.name ?? "clubs-club-events___nl",
    path: indext9y2DHcGB1Meta?.path ?? "events",
    meta: indext9y2DHcGB1Meta || {},
    alias: indext9y2DHcGB1Meta?.alias || [],
    redirect: indext9y2DHcGB1Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/events/index.vue").then(m => m.default || m)
  },
  {
    name: fansOgHcxG0eCjMeta?.name ?? "clubs-club-fans___nl",
    path: fansOgHcxG0eCjMeta?.path ?? "fans",
    meta: fansOgHcxG0eCjMeta || {},
    alias: fansOgHcxG0eCjMeta?.alias || [],
    redirect: fansOgHcxG0eCjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/fans.vue").then(m => m.default || m)
  },
  {
    name: feed6KGau6XVthMeta?.name ?? "clubs-club-feed___nl",
    path: feed6KGau6XVthMeta?.path ?? "feed",
    meta: feed6KGau6XVthMeta || {},
    alias: feed6KGau6XVthMeta?.alias || [],
    redirect: feed6KGau6XVthMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/feed.vue").then(m => m.default || m)
  },
  {
    name: indexKypU0evdGcMeta?.name ?? "clubs-club___nl",
    path: indexKypU0evdGcMeta?.path ?? "",
    meta: indexKypU0evdGcMeta || {},
    alias: indexKypU0evdGcMeta?.alias || [],
    redirect: indexKypU0evdGcMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/index.vue").then(m => m.default || m)
  },
  {
    name: joinvjFznwzSKIMeta?.name ?? "clubs-club-join___nl",
    path: joinvjFznwzSKIMeta?.path ?? "join",
    meta: joinvjFznwzSKIMeta || {},
    alias: joinvjFznwzSKIMeta?.alias || [],
    redirect: joinvjFznwzSKIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/join.vue").then(m => m.default || m)
  },
  {
    name: assignrwJ3dC5XMVMeta?.name ?? "clubs-club-members-assign___nl",
    path: assignrwJ3dC5XMVMeta?.path ?? "members/assign",
    meta: assignrwJ3dC5XMVMeta || {},
    alias: assignrwJ3dC5XMVMeta?.alias || [],
    redirect: assignrwJ3dC5XMVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/members/assign.vue").then(m => m.default || m)
  },
  {
    name: indexcZeOZ193aeMeta?.name ?? "clubs-club-members___nl",
    path: indexcZeOZ193aeMeta?.path ?? "members",
    meta: indexcZeOZ193aeMeta || {},
    alias: indexcZeOZ193aeMeta?.alias || [],
    redirect: indexcZeOZ193aeMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/members/index.vue").then(m => m.default || m)
  },
  {
    name: membershipZ5NX3mVFTGMeta?.name ?? "clubs-club-membership___nl",
    path: membershipZ5NX3mVFTGMeta?.path ?? "membership",
    meta: membershipZ5NX3mVFTGMeta || {},
    alias: membershipZ5NX3mVFTGMeta?.alias || [],
    redirect: membershipZ5NX3mVFTGMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/membership.vue").then(m => m.default || m)
  },
  {
    name: news0fSPl5ds5DMeta?.name ?? "clubs-club-news___nl",
    path: news0fSPl5ds5DMeta?.path ?? "news",
    meta: news0fSPl5ds5DMeta || {},
    alias: news0fSPl5ds5DMeta?.alias || [],
    redirect: news0fSPl5ds5DMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/news.vue").then(m => m.default || m)
  },
  {
    name: resultsPiabbjM9XRMeta?.name ?? "clubs-club-results___nl",
    path: resultsPiabbjM9XRMeta?.path ?? "results",
    meta: resultsPiabbjM9XRMeta || {},
    alias: resultsPiabbjM9XRMeta?.alias || [],
    redirect: resultsPiabbjM9XRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/results.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91club_93lvfeM1MaCLMeta?.name ?? undefined,
    path: _91club_93lvfeM1MaCLMeta?.path ?? "/cs/clubs/:club()",
    meta: _91club_93lvfeM1MaCLMeta || {},
    alias: _91club_93lvfeM1MaCLMeta?.alias || [],
    redirect: _91club_93lvfeM1MaCLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club].vue").then(m => m.default || m),
    children: [
  {
    name: blogtojob0HK1vMeta?.name ?? "clubs-club-blog___cs",
    path: blogtojob0HK1vMeta?.path ?? "blog",
    meta: blogtojob0HK1vMeta || {},
    alias: blogtojob0HK1vMeta?.alias || [],
    redirect: blogtojob0HK1vMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/blog.vue").then(m => m.default || m)
  },
  {
    name: competition_45planner10V1Qv8IkwMeta?.name ?? "clubs-club-competition-planner___cs",
    path: competition_45planner10V1Qv8IkwMeta?.path ?? "competition-planner",
    meta: competition_45planner10V1Qv8IkwMeta || {},
    alias: competition_45planner10V1Qv8IkwMeta?.alias || [],
    redirect: competition_45planner10V1Qv8IkwMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/competition-planner.vue").then(m => m.default || m)
  },
  {
    name: contactv7VfnbmnmzMeta?.name ?? "clubs-club-contact___cs",
    path: contactv7VfnbmnmzMeta?.path ?? "contact",
    meta: contactv7VfnbmnmzMeta || {},
    alias: contactv7VfnbmnmzMeta?.alias || [],
    redirect: contactv7VfnbmnmzMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/contact.vue").then(m => m.default || m)
  },
  {
    name: departmentsCmfvX32AgyMeta?.name ?? "clubs-club-departments___cs",
    path: departmentsCmfvX32AgyMeta?.path ?? "departments",
    meta: departmentsCmfvX32AgyMeta || {},
    alias: departmentsCmfvX32AgyMeta?.alias || [],
    redirect: departmentsCmfvX32AgyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/departments.vue").then(m => m.default || m)
  },
  {
    name: participationsfKzrydLMZaMeta?.name ?? "clubs-club-events-event-participations___cs",
    path: participationsfKzrydLMZaMeta?.path ?? "events/:event()/participations",
    meta: participationsfKzrydLMZaMeta || {},
    alias: participationsfKzrydLMZaMeta?.alias || [],
    redirect: participationsfKzrydLMZaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/events/[event]/participations.vue").then(m => m.default || m)
  },
  {
    name: indext9y2DHcGB1Meta?.name ?? "clubs-club-events___cs",
    path: indext9y2DHcGB1Meta?.path ?? "events",
    meta: indext9y2DHcGB1Meta || {},
    alias: indext9y2DHcGB1Meta?.alias || [],
    redirect: indext9y2DHcGB1Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/events/index.vue").then(m => m.default || m)
  },
  {
    name: fansOgHcxG0eCjMeta?.name ?? "clubs-club-fans___cs",
    path: fansOgHcxG0eCjMeta?.path ?? "fans",
    meta: fansOgHcxG0eCjMeta || {},
    alias: fansOgHcxG0eCjMeta?.alias || [],
    redirect: fansOgHcxG0eCjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/fans.vue").then(m => m.default || m)
  },
  {
    name: feed6KGau6XVthMeta?.name ?? "clubs-club-feed___cs",
    path: feed6KGau6XVthMeta?.path ?? "feed",
    meta: feed6KGau6XVthMeta || {},
    alias: feed6KGau6XVthMeta?.alias || [],
    redirect: feed6KGau6XVthMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/feed.vue").then(m => m.default || m)
  },
  {
    name: indexKypU0evdGcMeta?.name ?? "clubs-club___cs",
    path: indexKypU0evdGcMeta?.path ?? "",
    meta: indexKypU0evdGcMeta || {},
    alias: indexKypU0evdGcMeta?.alias || [],
    redirect: indexKypU0evdGcMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/index.vue").then(m => m.default || m)
  },
  {
    name: joinvjFznwzSKIMeta?.name ?? "clubs-club-join___cs",
    path: joinvjFznwzSKIMeta?.path ?? "join",
    meta: joinvjFznwzSKIMeta || {},
    alias: joinvjFznwzSKIMeta?.alias || [],
    redirect: joinvjFznwzSKIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/join.vue").then(m => m.default || m)
  },
  {
    name: assignrwJ3dC5XMVMeta?.name ?? "clubs-club-members-assign___cs",
    path: assignrwJ3dC5XMVMeta?.path ?? "members/assign",
    meta: assignrwJ3dC5XMVMeta || {},
    alias: assignrwJ3dC5XMVMeta?.alias || [],
    redirect: assignrwJ3dC5XMVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/members/assign.vue").then(m => m.default || m)
  },
  {
    name: indexcZeOZ193aeMeta?.name ?? "clubs-club-members___cs",
    path: indexcZeOZ193aeMeta?.path ?? "members",
    meta: indexcZeOZ193aeMeta || {},
    alias: indexcZeOZ193aeMeta?.alias || [],
    redirect: indexcZeOZ193aeMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/members/index.vue").then(m => m.default || m)
  },
  {
    name: membershipZ5NX3mVFTGMeta?.name ?? "clubs-club-membership___cs",
    path: membershipZ5NX3mVFTGMeta?.path ?? "membership",
    meta: membershipZ5NX3mVFTGMeta || {},
    alias: membershipZ5NX3mVFTGMeta?.alias || [],
    redirect: membershipZ5NX3mVFTGMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/membership.vue").then(m => m.default || m)
  },
  {
    name: news0fSPl5ds5DMeta?.name ?? "clubs-club-news___cs",
    path: news0fSPl5ds5DMeta?.path ?? "news",
    meta: news0fSPl5ds5DMeta || {},
    alias: news0fSPl5ds5DMeta?.alias || [],
    redirect: news0fSPl5ds5DMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/news.vue").then(m => m.default || m)
  },
  {
    name: resultsPiabbjM9XRMeta?.name ?? "clubs-club-results___cs",
    path: resultsPiabbjM9XRMeta?.path ?? "results",
    meta: resultsPiabbjM9XRMeta || {},
    alias: resultsPiabbjM9XRMeta?.alias || [],
    redirect: resultsPiabbjM9XRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/[club]/results.vue").then(m => m.default || m)
  }
]
  },
  {
    name: create3k2rW85kDQMeta?.name ?? "clubs-create___de",
    path: create3k2rW85kDQMeta?.path ?? "/clubs/create",
    meta: create3k2rW85kDQMeta || {},
    alias: create3k2rW85kDQMeta?.alias || [],
    redirect: create3k2rW85kDQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/create.vue").then(m => m.default || m)
  },
  {
    name: create3k2rW85kDQMeta?.name ?? "clubs-create___en",
    path: create3k2rW85kDQMeta?.path ?? "/en/clubs/create",
    meta: create3k2rW85kDQMeta || {},
    alias: create3k2rW85kDQMeta?.alias || [],
    redirect: create3k2rW85kDQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/create.vue").then(m => m.default || m)
  },
  {
    name: create3k2rW85kDQMeta?.name ?? "clubs-create___nl",
    path: create3k2rW85kDQMeta?.path ?? "/nl/clubs/create",
    meta: create3k2rW85kDQMeta || {},
    alias: create3k2rW85kDQMeta?.alias || [],
    redirect: create3k2rW85kDQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/create.vue").then(m => m.default || m)
  },
  {
    name: create3k2rW85kDQMeta?.name ?? "clubs-create___cs",
    path: create3k2rW85kDQMeta?.path ?? "/cs/clubs/create",
    meta: create3k2rW85kDQMeta || {},
    alias: create3k2rW85kDQMeta?.alias || [],
    redirect: create3k2rW85kDQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/create.vue").then(m => m.default || m)
  },
  {
    name: indexiPWYfyfvcUMeta?.name ?? "clubs___de",
    path: indexiPWYfyfvcUMeta?.path ?? "/clubs",
    meta: indexiPWYfyfvcUMeta || {},
    alias: indexiPWYfyfvcUMeta?.alias || [],
    redirect: indexiPWYfyfvcUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPWYfyfvcUMeta?.name ?? "clubs___en",
    path: indexiPWYfyfvcUMeta?.path ?? "/en/clubs",
    meta: indexiPWYfyfvcUMeta || {},
    alias: indexiPWYfyfvcUMeta?.alias || [],
    redirect: indexiPWYfyfvcUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPWYfyfvcUMeta?.name ?? "clubs___nl",
    path: indexiPWYfyfvcUMeta?.path ?? "/nl/clubs",
    meta: indexiPWYfyfvcUMeta || {},
    alias: indexiPWYfyfvcUMeta?.alias || [],
    redirect: indexiPWYfyfvcUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPWYfyfvcUMeta?.name ?? "clubs___cs",
    path: indexiPWYfyfvcUMeta?.path ?? "/cs/clubs",
    meta: indexiPWYfyfvcUMeta || {},
    alias: indexiPWYfyfvcUMeta?.alias || [],
    redirect: indexiPWYfyfvcUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/clubs/index.vue").then(m => m.default || m)
  },
  {
    name: communitygNu7kb8AABMeta?.name ?? "community___de",
    path: communitygNu7kb8AABMeta?.path ?? "/community",
    meta: communitygNu7kb8AABMeta || {},
    alias: communitygNu7kb8AABMeta?.alias || [],
    redirect: communitygNu7kb8AABMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/community.vue").then(m => m.default || m)
  },
  {
    name: communitygNu7kb8AABMeta?.name ?? "community___en",
    path: communitygNu7kb8AABMeta?.path ?? "/en/community",
    meta: communitygNu7kb8AABMeta || {},
    alias: communitygNu7kb8AABMeta?.alias || [],
    redirect: communitygNu7kb8AABMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/community.vue").then(m => m.default || m)
  },
  {
    name: communitygNu7kb8AABMeta?.name ?? "community___nl",
    path: communitygNu7kb8AABMeta?.path ?? "/nl/community",
    meta: communitygNu7kb8AABMeta || {},
    alias: communitygNu7kb8AABMeta?.alias || [],
    redirect: communitygNu7kb8AABMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/community.vue").then(m => m.default || m)
  },
  {
    name: communitygNu7kb8AABMeta?.name ?? "community___cs",
    path: communitygNu7kb8AABMeta?.path ?? "/cs/community",
    meta: communitygNu7kb8AABMeta || {},
    alias: communitygNu7kb8AABMeta?.alias || [],
    redirect: communitygNu7kb8AABMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/community.vue").then(m => m.default || m)
  },
  {
    name: createlnaaAweAyqMeta?.name ?? "companies-create___de",
    path: createlnaaAweAyqMeta?.path ?? "/companies/create",
    meta: createlnaaAweAyqMeta || {},
    alias: createlnaaAweAyqMeta?.alias || [],
    redirect: createlnaaAweAyqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/companies/create.vue").then(m => m.default || m)
  },
  {
    name: createlnaaAweAyqMeta?.name ?? "companies-create___en",
    path: createlnaaAweAyqMeta?.path ?? "/en/companies/create",
    meta: createlnaaAweAyqMeta || {},
    alias: createlnaaAweAyqMeta?.alias || [],
    redirect: createlnaaAweAyqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/companies/create.vue").then(m => m.default || m)
  },
  {
    name: createlnaaAweAyqMeta?.name ?? "companies-create___nl",
    path: createlnaaAweAyqMeta?.path ?? "/nl/companies/create",
    meta: createlnaaAweAyqMeta || {},
    alias: createlnaaAweAyqMeta?.alias || [],
    redirect: createlnaaAweAyqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/companies/create.vue").then(m => m.default || m)
  },
  {
    name: createlnaaAweAyqMeta?.name ?? "companies-create___cs",
    path: createlnaaAweAyqMeta?.path ?? "/cs/companies/create",
    meta: createlnaaAweAyqMeta || {},
    alias: createlnaaAweAyqMeta?.alias || [],
    redirect: createlnaaAweAyqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/companies/create.vue").then(m => m.default || m)
  },
  {
    name: competition_45plannerXIzuBbP4SBMeta?.name ?? "competition-planner___de",
    path: competition_45plannerXIzuBbP4SBMeta?.path ?? "/competition-planner",
    meta: competition_45plannerXIzuBbP4SBMeta || {},
    alias: competition_45plannerXIzuBbP4SBMeta?.alias || [],
    redirect: competition_45plannerXIzuBbP4SBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/competition-planner.vue").then(m => m.default || m)
  },
  {
    name: competition_45plannerXIzuBbP4SBMeta?.name ?? "competition-planner___en",
    path: competition_45plannerXIzuBbP4SBMeta?.path ?? "/en/competition-planner",
    meta: competition_45plannerXIzuBbP4SBMeta || {},
    alias: competition_45plannerXIzuBbP4SBMeta?.alias || [],
    redirect: competition_45plannerXIzuBbP4SBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/competition-planner.vue").then(m => m.default || m)
  },
  {
    name: competition_45plannerXIzuBbP4SBMeta?.name ?? "competition-planner___nl",
    path: competition_45plannerXIzuBbP4SBMeta?.path ?? "/nl/competition-planner",
    meta: competition_45plannerXIzuBbP4SBMeta || {},
    alias: competition_45plannerXIzuBbP4SBMeta?.alias || [],
    redirect: competition_45plannerXIzuBbP4SBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/competition-planner.vue").then(m => m.default || m)
  },
  {
    name: competition_45plannerXIzuBbP4SBMeta?.name ?? "competition-planner___cs",
    path: competition_45plannerXIzuBbP4SBMeta?.path ?? "/cs/competition-planner",
    meta: competition_45plannerXIzuBbP4SBMeta || {},
    alias: competition_45plannerXIzuBbP4SBMeta?.alias || [],
    redirect: competition_45plannerXIzuBbP4SBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/competition-planner.vue").then(m => m.default || m)
  },
  {
    name: confirmZyX2tOQqpfMeta?.name ?? "confirm___de",
    path: confirmZyX2tOQqpfMeta?.path ?? "/confirm",
    meta: confirmZyX2tOQqpfMeta || {},
    alias: confirmZyX2tOQqpfMeta?.alias || [],
    redirect: confirmZyX2tOQqpfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmZyX2tOQqpfMeta?.name ?? "confirm___en",
    path: confirmZyX2tOQqpfMeta?.path ?? "/en/confirm",
    meta: confirmZyX2tOQqpfMeta || {},
    alias: confirmZyX2tOQqpfMeta?.alias || [],
    redirect: confirmZyX2tOQqpfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmZyX2tOQqpfMeta?.name ?? "confirm___nl",
    path: confirmZyX2tOQqpfMeta?.path ?? "/nl/confirm",
    meta: confirmZyX2tOQqpfMeta || {},
    alias: confirmZyX2tOQqpfMeta?.alias || [],
    redirect: confirmZyX2tOQqpfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmZyX2tOQqpfMeta?.name ?? "confirm___cs",
    path: confirmZyX2tOQqpfMeta?.path ?? "/cs/confirm",
    meta: confirmZyX2tOQqpfMeta || {},
    alias: confirmZyX2tOQqpfMeta?.alias || [],
    redirect: confirmZyX2tOQqpfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: contactpXBpLZanlVMeta?.name ?? "contact___de",
    path: contactpXBpLZanlVMeta?.path ?? "/contact",
    meta: contactpXBpLZanlVMeta || {},
    alias: contactpXBpLZanlVMeta?.alias || [],
    redirect: contactpXBpLZanlVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactpXBpLZanlVMeta?.name ?? "contact___en",
    path: contactpXBpLZanlVMeta?.path ?? "/en/contact",
    meta: contactpXBpLZanlVMeta || {},
    alias: contactpXBpLZanlVMeta?.alias || [],
    redirect: contactpXBpLZanlVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactpXBpLZanlVMeta?.name ?? "contact___nl",
    path: contactpXBpLZanlVMeta?.path ?? "/nl/contact",
    meta: contactpXBpLZanlVMeta || {},
    alias: contactpXBpLZanlVMeta?.alias || [],
    redirect: contactpXBpLZanlVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactpXBpLZanlVMeta?.name ?? "contact___cs",
    path: contactpXBpLZanlVMeta?.path ?? "/cs/contact",
    meta: contactpXBpLZanlVMeta || {},
    alias: contactpXBpLZanlVMeta?.alias || [],
    redirect: contactpXBpLZanlVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: coplZ5qbmuPXxMeta?.name ?? "cop___de",
    path: coplZ5qbmuPXxMeta?.path ?? "/cop",
    meta: coplZ5qbmuPXxMeta || {},
    alias: coplZ5qbmuPXxMeta?.alias || [],
    redirect: coplZ5qbmuPXxMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/cop.vue").then(m => m.default || m)
  },
  {
    name: coplZ5qbmuPXxMeta?.name ?? "cop___en",
    path: coplZ5qbmuPXxMeta?.path ?? "/en/cop",
    meta: coplZ5qbmuPXxMeta || {},
    alias: coplZ5qbmuPXxMeta?.alias || [],
    redirect: coplZ5qbmuPXxMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/cop.vue").then(m => m.default || m)
  },
  {
    name: coplZ5qbmuPXxMeta?.name ?? "cop___nl",
    path: coplZ5qbmuPXxMeta?.path ?? "/nl/cop",
    meta: coplZ5qbmuPXxMeta || {},
    alias: coplZ5qbmuPXxMeta?.alias || [],
    redirect: coplZ5qbmuPXxMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/cop.vue").then(m => m.default || m)
  },
  {
    name: coplZ5qbmuPXxMeta?.name ?? "cop___cs",
    path: coplZ5qbmuPXxMeta?.path ?? "/cs/cop",
    meta: coplZ5qbmuPXxMeta || {},
    alias: coplZ5qbmuPXxMeta?.alias || [],
    redirect: coplZ5qbmuPXxMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/cop.vue").then(m => m.default || m)
  },
  {
    name: dashboardS17QZJVydhMeta?.name ?? "dashboard___de",
    path: dashboardS17QZJVydhMeta?.path ?? "/dashboard",
    meta: dashboardS17QZJVydhMeta || {},
    alias: dashboardS17QZJVydhMeta?.alias || [],
    redirect: dashboardS17QZJVydhMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardS17QZJVydhMeta?.name ?? "dashboard___en",
    path: dashboardS17QZJVydhMeta?.path ?? "/en/dashboard",
    meta: dashboardS17QZJVydhMeta || {},
    alias: dashboardS17QZJVydhMeta?.alias || [],
    redirect: dashboardS17QZJVydhMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardS17QZJVydhMeta?.name ?? "dashboard___nl",
    path: dashboardS17QZJVydhMeta?.path ?? "/nl/dashboard",
    meta: dashboardS17QZJVydhMeta || {},
    alias: dashboardS17QZJVydhMeta?.alias || [],
    redirect: dashboardS17QZJVydhMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardS17QZJVydhMeta?.name ?? "dashboard___cs",
    path: dashboardS17QZJVydhMeta?.path ?? "/cs/dashboard",
    meta: dashboardS17QZJVydhMeta || {},
    alias: dashboardS17QZJVydhMeta?.alias || [],
    redirect: dashboardS17QZJVydhMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91event_93Bek69s1NqNMeta?.name ?? undefined,
    path: _91event_93Bek69s1NqNMeta?.path ?? "/events/:event()",
    meta: _91event_93Bek69s1NqNMeta || {},
    alias: _91event_93Bek69s1NqNMeta?.alias || [],
    redirect: _91event_93Bek69s1NqNMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event].vue").then(m => m.default || m),
    children: [
  {
    name: assignmentAHWZNNAgiUMeta?.name ?? "events-event-assignment___de",
    path: assignmentAHWZNNAgiUMeta?.path ?? "assignment",
    meta: assignmentAHWZNNAgiUMeta || {},
    alias: assignmentAHWZNNAgiUMeta?.alias || [],
    redirect: assignmentAHWZNNAgiUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/assignment.vue").then(m => m.default || m)
  },
  {
    name: awardsgZ6qhbupOBMeta?.name ?? "events-event-awards___de",
    path: awardsgZ6qhbupOBMeta?.path ?? "awards",
    meta: awardsgZ6qhbupOBMeta || {},
    alias: awardsgZ6qhbupOBMeta?.alias || [],
    redirect: awardsgZ6qhbupOBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/awards.vue").then(m => m.default || m)
  },
  {
    name: campaignsFKAtULfnBJMeta?.name ?? "events-event-campaigns___de",
    path: campaignsFKAtULfnBJMeta?.path ?? "campaigns",
    meta: campaignsFKAtULfnBJMeta || {},
    alias: campaignsFKAtULfnBJMeta?.alias || [],
    redirect: campaignsFKAtULfnBJMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/campaigns.vue").then(m => m.default || m)
  },
  {
    name: communitylZYRcas0pLMeta?.name ?? "events-event-community___de",
    path: communitylZYRcas0pLMeta?.path ?? "community",
    meta: communitylZYRcas0pLMeta || {},
    alias: communitylZYRcas0pLMeta?.alias || [],
    redirect: communitylZYRcas0pLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/community.vue").then(m => m.default || m)
  },
  {
    name: contactoNymCpb30mMeta?.name ?? "events-event-contact___de",
    path: contactoNymCpb30mMeta?.path ?? "contact",
    meta: contactoNymCpb30mMeta || {},
    alias: contactoNymCpb30mMeta?.alias || [],
    redirect: contactoNymCpb30mMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/contact.vue").then(m => m.default || m)
  },
  {
    name: gtc1aSZmzZzZBMeta?.name ?? "events-event-gtc___de",
    path: gtc1aSZmzZzZBMeta?.path ?? "gtc",
    meta: gtc1aSZmzZzZBMeta || {},
    alias: gtc1aSZmzZzZBMeta?.alias || [],
    redirect: gtc1aSZmzZzZBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/gtc.vue").then(m => m.default || m)
  },
  {
    name: impressionsh0BmFJPG9NMeta?.name ?? "events-event-impressions___de",
    path: impressionsh0BmFJPG9NMeta?.path ?? "impressions",
    meta: impressionsh0BmFJPG9NMeta || {},
    alias: impressionsh0BmFJPG9NMeta?.alias || [],
    redirect: impressionsh0BmFJPG9NMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/impressions.vue").then(m => m.default || m)
  },
  {
    name: indexnFQ7qTpyblMeta?.name ?? "events-event___de",
    path: indexnFQ7qTpyblMeta?.path ?? "",
    meta: indexnFQ7qTpyblMeta || {},
    alias: indexnFQ7qTpyblMeta?.alias || [],
    redirect: indexnFQ7qTpyblMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: _91job_93dDwZg9isffMeta?.name ?? "events-event-jobs-job___de",
    path: _91job_93dDwZg9isffMeta?.path ?? "jobs/:job()",
    meta: _91job_93dDwZg9isffMeta || {},
    alias: _91job_93dDwZg9isffMeta?.alias || [],
    redirect: _91job_93dDwZg9isffMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/jobs/[job].vue").then(m => m.default || m)
  },
  {
    name: indexYerGb0viNjMeta?.name ?? "events-event-jobs___de",
    path: indexYerGb0viNjMeta?.path ?? "jobs",
    meta: indexYerGb0viNjMeta || {},
    alias: indexYerGb0viNjMeta?.alias || [],
    redirect: indexYerGb0viNjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: joineQfMxYOidiMeta?.name ?? "events-event-join___de",
    path: joineQfMxYOidiMeta?.path ?? "join",
    meta: joineQfMxYOidiMeta || {},
    alias: joineQfMxYOidiMeta?.alias || [],
    redirect: joineQfMxYOidiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/join.vue").then(m => m.default || m)
  },
  {
    name: livei03zejafaIMeta?.name ?? "events-event-live___de",
    path: livei03zejafaIMeta?.path ?? "live",
    meta: livei03zejafaIMeta || {},
    alias: livei03zejafaIMeta?.alias || [],
    redirect: livei03zejafaIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/live.vue").then(m => m.default || m)
  },
  {
    name: map9iktEiyr3PMeta?.name ?? "events-event-map___de",
    path: map9iktEiyr3PMeta?.path ?? "map",
    meta: map9iktEiyr3PMeta || {},
    alias: map9iktEiyr3PMeta?.alias || [],
    redirect: map9iktEiyr3PMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/map.vue").then(m => m.default || m)
  },
  {
    name: assignXgQ3yfZNeIMeta?.name ?? "events-event-members-assign___de",
    path: assignXgQ3yfZNeIMeta?.path ?? "members/assign",
    meta: assignXgQ3yfZNeIMeta || {},
    alias: assignXgQ3yfZNeIMeta?.alias || [],
    redirect: assignXgQ3yfZNeIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/members/assign.vue").then(m => m.default || m)
  },
  {
    name: newsOwnCZH2whjMeta?.name ?? "events-event-news___de",
    path: newsOwnCZH2whjMeta?.path ?? "news",
    meta: newsOwnCZH2whjMeta || {},
    alias: newsOwnCZH2whjMeta?.alias || [],
    redirect: newsOwnCZH2whjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/news.vue").then(m => m.default || m)
  },
  {
    name: index8WpzEpWxA7Meta?.name ?? "events-event-participate___de",
    path: index8WpzEpWxA7Meta?.path ?? "participate",
    meta: index8WpzEpWxA7Meta || {},
    alias: index8WpzEpWxA7Meta?.alias || [],
    redirect: index8WpzEpWxA7Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participate/index.vue").then(m => m.default || m)
  },
  {
    name: index69lp8kbMmqMeta?.name ?? "events-event-participation-classes___de",
    path: index69lp8kbMmqMeta?.path ?? "participation-classes",
    meta: index69lp8kbMmqMeta || {},
    alias: index69lp8kbMmqMeta?.alias || [],
    redirect: index69lp8kbMmqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participation-classes/index.vue").then(m => m.default || m)
  },
  {
    name: _91participation_93gkfPBEb8U4Meta?.name ?? undefined,
    path: _91participation_93gkfPBEb8U4Meta?.path ?? "participations/:participation()",
    meta: _91participation_93gkfPBEb8U4Meta || {},
    alias: _91participation_93gkfPBEb8U4Meta?.alias || [],
    redirect: _91participation_93gkfPBEb8U4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation].vue").then(m => m.default || m),
    children: [
  {
    name: createvd4DjGJxhbMeta?.name ?? "events-event-participations-participation-attempts-create___de",
    path: createvd4DjGJxhbMeta?.path ?? "attempts/create",
    meta: createvd4DjGJxhbMeta || {},
    alias: createvd4DjGJxhbMeta?.alias || [],
    redirect: createvd4DjGJxhbMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/attempts/create.vue").then(m => m.default || m)
  },
  {
    name: campaignskldeFLVBW0Meta?.name ?? "events-event-participations-participation-campaigns___de",
    path: campaignskldeFLVBW0Meta?.path ?? "campaigns",
    meta: campaignskldeFLVBW0Meta || {},
    alias: campaignskldeFLVBW0Meta?.alias || [],
    redirect: campaignskldeFLVBW0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/campaigns.vue").then(m => m.default || m)
  },
  {
    name: editmzfOVtxBgyMeta?.name ?? "events-event-participations-participation-edit___de",
    path: editmzfOVtxBgyMeta?.path ?? "edit",
    meta: editmzfOVtxBgyMeta || {},
    alias: editmzfOVtxBgyMeta?.alias || [],
    redirect: editmzfOVtxBgyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZC7GISVufCMeta?.name ?? "events-event-participations-participation___de",
    path: indexZC7GISVufCMeta?.path ?? "",
    meta: indexZC7GISVufCMeta || {},
    alias: indexZC7GISVufCMeta?.alias || [],
    redirect: indexZC7GISVufCMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/index.vue").then(m => m.default || m)
  },
  {
    name: mapayBBY5KPaqMeta?.name ?? "events-event-participations-participation-map___de",
    path: mapayBBY5KPaqMeta?.path ?? "map",
    meta: mapayBBY5KPaqMeta || {},
    alias: mapayBBY5KPaqMeta?.alias || [],
    redirect: mapayBBY5KPaqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/map.vue").then(m => m.default || m)
  },
  {
    name: paymentZ7AxDGbbrFMeta?.name ?? "events-event-participations-participation-payment___de",
    path: paymentZ7AxDGbbrFMeta?.path ?? "payment",
    meta: paymentZ7AxDGbbrFMeta || {},
    alias: paymentZ7AxDGbbrFMeta?.alias || [],
    redirect: paymentZ7AxDGbbrFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/payment.vue").then(m => m.default || m)
  },
  {
    name: productswtDywd9Kn0Meta?.name ?? "events-event-participations-participation-products___de",
    path: productswtDywd9Kn0Meta?.path ?? "products",
    meta: productswtDywd9Kn0Meta || {},
    alias: productswtDywd9Kn0Meta?.alias || [],
    redirect: productswtDywd9Kn0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/products.vue").then(m => m.default || m)
  },
  {
    name: resultsNVfnLT1IeNMeta?.name ?? "events-event-participations-participation-results___de",
    path: resultsNVfnLT1IeNMeta?.path ?? "results",
    meta: resultsNVfnLT1IeNMeta || {},
    alias: resultsNVfnLT1IeNMeta?.alias || [],
    redirect: resultsNVfnLT1IeNMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/results.vue").then(m => m.default || m)
  },
  {
    name: starttSRpc0FxFQMeta?.name ?? "events-event-participations-participation-start___de",
    path: starttSRpc0FxFQMeta?.path ?? "start",
    meta: starttSRpc0FxFQMeta || {},
    alias: starttSRpc0FxFQMeta?.alias || [],
    redirect: starttSRpc0FxFQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/start.vue").then(m => m.default || m)
  },
  {
    name: tracking8mSiPkSVu6Meta?.name ?? "events-event-participations-participation-tracking___de",
    path: tracking8mSiPkSVu6Meta?.path ?? "tracking",
    meta: tracking8mSiPkSVu6Meta || {},
    alias: tracking8mSiPkSVu6Meta?.alias || [],
    redirect: tracking8mSiPkSVu6Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/tracking.vue").then(m => m.default || m)
  }
]
  },
  {
    name: assignMAKOjFXtjaMeta?.name ?? "events-event-participations-assign___de",
    path: assignMAKOjFXtjaMeta?.path ?? "participations/assign",
    meta: assignMAKOjFXtjaMeta || {},
    alias: assignMAKOjFXtjaMeta?.alias || [],
    redirect: assignMAKOjFXtjaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/assign.vue").then(m => m.default || m)
  },
  {
    name: indexGnp7IOM4gVMeta?.name ?? "events-event-participations___de",
    path: indexGnp7IOM4gVMeta?.path ?? "participations",
    meta: indexGnp7IOM4gVMeta || {},
    alias: indexGnp7IOM4gVMeta?.alias || [],
    redirect: indexGnp7IOM4gVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/index.vue").then(m => m.default || m)
  },
  {
    name: photosdGAycOCARdMeta?.name ?? undefined,
    path: photosdGAycOCARdMeta?.path ?? "photos",
    meta: photosdGAycOCARdMeta || {},
    alias: photosdGAycOCARdMeta?.alias || [],
    redirect: photosdGAycOCARdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos.vue").then(m => m.default || m),
    children: [
  {
    name: _91gallery_9336ERkWkwCxMeta?.name ?? "events-event-photos-gallery___de",
    path: _91gallery_9336ERkWkwCxMeta?.path ?? ":gallery()",
    meta: _91gallery_9336ERkWkwCxMeta || {},
    alias: _91gallery_9336ERkWkwCxMeta?.alias || [],
    redirect: _91gallery_9336ERkWkwCxMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/[gallery].vue").then(m => m.default || m)
  },
  {
    name: carth37kQrbsqcMeta?.name ?? "events-event-photos-cart___de",
    path: carth37kQrbsqcMeta?.path ?? "cart",
    meta: carth37kQrbsqcMeta || {},
    alias: carth37kQrbsqcMeta?.alias || [],
    redirect: carth37kQrbsqcMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/cart.vue").then(m => m.default || m)
  },
  {
    name: indexP6WOwcYmiYMeta?.name ?? "events-event-photos___de",
    path: indexP6WOwcYmiYMeta?.path ?? "",
    meta: indexP6WOwcYmiYMeta || {},
    alias: indexP6WOwcYmiYMeta?.alias || [],
    redirect: indexP6WOwcYmiYMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: productsgxS4Cq2HlkMeta?.name ?? "events-event-products___de",
    path: productsgxS4Cq2HlkMeta?.path ?? "products",
    meta: productsgxS4Cq2HlkMeta || {},
    alias: productsgxS4Cq2HlkMeta?.alias || [],
    redirect: productsgxS4Cq2HlkMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/products.vue").then(m => m.default || m)
  },
  {
    name: create87GhxG4G1IMeta?.name ?? "events-event-ratings-create___de",
    path: create87GhxG4G1IMeta?.path ?? "ratings/create",
    meta: create87GhxG4G1IMeta || {},
    alias: create87GhxG4G1IMeta?.alias || [],
    redirect: create87GhxG4G1IMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/ratings/create.vue").then(m => m.default || m)
  },
  {
    name: indexHH48F7rosMMeta?.name ?? "events-event-ratings___de",
    path: indexHH48F7rosMMeta?.path ?? "ratings",
    meta: indexHH48F7rosMMeta || {},
    alias: indexHH48F7rosMMeta?.alias || [],
    redirect: indexHH48F7rosMMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/ratings/index.vue").then(m => m.default || m)
  },
  {
    name: _91group_93PIEUw0GTQpMeta?.name ?? "events-event-results-group___de",
    path: _91group_93PIEUw0GTQpMeta?.path ?? "results/:group()",
    meta: _91group_93PIEUw0GTQpMeta || {},
    alias: _91group_93PIEUw0GTQpMeta?.alias || [],
    redirect: _91group_93PIEUw0GTQpMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/results/[group].vue").then(m => m.default || m)
  },
  {
    name: indexIUwJP2qta4Meta?.name ?? "events-event-results___de",
    path: indexIUwJP2qta4Meta?.path ?? "results",
    meta: indexIUwJP2qta4Meta || {},
    alias: indexIUwJP2qta4Meta?.alias || [],
    redirect: indexIUwJP2qta4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/results/index.vue").then(m => m.default || m)
  },
  {
    name: shopZgRb79KbMyMeta?.name ?? "events-event-shop___de",
    path: shopZgRb79KbMyMeta?.path ?? "shop",
    meta: shopZgRb79KbMyMeta || {},
    alias: shopZgRb79KbMyMeta?.alias || [],
    redirect: shopZgRb79KbMyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/shop.vue").then(m => m.default || m)
  },
  {
    name: sponsorsfvHDo8yOLQMeta?.name ?? "events-event-sponsors___de",
    path: sponsorsfvHDo8yOLQMeta?.path ?? "sponsors",
    meta: sponsorsfvHDo8yOLQMeta || {},
    alias: sponsorsfvHDo8yOLQMeta?.alias || [],
    redirect: sponsorsfvHDo8yOLQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/sponsors.vue").then(m => m.default || m)
  },
  {
    name: staySh8EK4LzZkMeta?.name ?? "events-event-stay___de",
    path: staySh8EK4LzZkMeta?.path ?? "stay",
    meta: staySh8EK4LzZkMeta || {},
    alias: staySh8EK4LzZkMeta?.alias || [],
    redirect: staySh8EK4LzZkMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/stay.vue").then(m => m.default || m)
  },
  {
    name: trackingU5bJt6tsQZMeta?.name ?? "events-event-tracking___de",
    path: trackingU5bJt6tsQZMeta?.path ?? "tracking",
    meta: trackingU5bJt6tsQZMeta || {},
    alias: trackingU5bJt6tsQZMeta?.alias || [],
    redirect: trackingU5bJt6tsQZMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/tracking.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91event_93Bek69s1NqNMeta?.name ?? undefined,
    path: _91event_93Bek69s1NqNMeta?.path ?? "/en/events/:event()",
    meta: _91event_93Bek69s1NqNMeta || {},
    alias: _91event_93Bek69s1NqNMeta?.alias || [],
    redirect: _91event_93Bek69s1NqNMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event].vue").then(m => m.default || m),
    children: [
  {
    name: assignmentAHWZNNAgiUMeta?.name ?? "events-event-assignment___en",
    path: assignmentAHWZNNAgiUMeta?.path ?? "assignment",
    meta: assignmentAHWZNNAgiUMeta || {},
    alias: assignmentAHWZNNAgiUMeta?.alias || [],
    redirect: assignmentAHWZNNAgiUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/assignment.vue").then(m => m.default || m)
  },
  {
    name: awardsgZ6qhbupOBMeta?.name ?? "events-event-awards___en",
    path: awardsgZ6qhbupOBMeta?.path ?? "awards",
    meta: awardsgZ6qhbupOBMeta || {},
    alias: awardsgZ6qhbupOBMeta?.alias || [],
    redirect: awardsgZ6qhbupOBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/awards.vue").then(m => m.default || m)
  },
  {
    name: campaignsFKAtULfnBJMeta?.name ?? "events-event-campaigns___en",
    path: campaignsFKAtULfnBJMeta?.path ?? "campaigns",
    meta: campaignsFKAtULfnBJMeta || {},
    alias: campaignsFKAtULfnBJMeta?.alias || [],
    redirect: campaignsFKAtULfnBJMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/campaigns.vue").then(m => m.default || m)
  },
  {
    name: communitylZYRcas0pLMeta?.name ?? "events-event-community___en",
    path: communitylZYRcas0pLMeta?.path ?? "community",
    meta: communitylZYRcas0pLMeta || {},
    alias: communitylZYRcas0pLMeta?.alias || [],
    redirect: communitylZYRcas0pLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/community.vue").then(m => m.default || m)
  },
  {
    name: contactoNymCpb30mMeta?.name ?? "events-event-contact___en",
    path: contactoNymCpb30mMeta?.path ?? "contact",
    meta: contactoNymCpb30mMeta || {},
    alias: contactoNymCpb30mMeta?.alias || [],
    redirect: contactoNymCpb30mMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/contact.vue").then(m => m.default || m)
  },
  {
    name: gtc1aSZmzZzZBMeta?.name ?? "events-event-gtc___en",
    path: gtc1aSZmzZzZBMeta?.path ?? "gtc",
    meta: gtc1aSZmzZzZBMeta || {},
    alias: gtc1aSZmzZzZBMeta?.alias || [],
    redirect: gtc1aSZmzZzZBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/gtc.vue").then(m => m.default || m)
  },
  {
    name: impressionsh0BmFJPG9NMeta?.name ?? "events-event-impressions___en",
    path: impressionsh0BmFJPG9NMeta?.path ?? "impressions",
    meta: impressionsh0BmFJPG9NMeta || {},
    alias: impressionsh0BmFJPG9NMeta?.alias || [],
    redirect: impressionsh0BmFJPG9NMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/impressions.vue").then(m => m.default || m)
  },
  {
    name: indexnFQ7qTpyblMeta?.name ?? "events-event___en",
    path: indexnFQ7qTpyblMeta?.path ?? "",
    meta: indexnFQ7qTpyblMeta || {},
    alias: indexnFQ7qTpyblMeta?.alias || [],
    redirect: indexnFQ7qTpyblMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: _91job_93dDwZg9isffMeta?.name ?? "events-event-jobs-job___en",
    path: _91job_93dDwZg9isffMeta?.path ?? "jobs/:job()",
    meta: _91job_93dDwZg9isffMeta || {},
    alias: _91job_93dDwZg9isffMeta?.alias || [],
    redirect: _91job_93dDwZg9isffMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/jobs/[job].vue").then(m => m.default || m)
  },
  {
    name: indexYerGb0viNjMeta?.name ?? "events-event-jobs___en",
    path: indexYerGb0viNjMeta?.path ?? "jobs",
    meta: indexYerGb0viNjMeta || {},
    alias: indexYerGb0viNjMeta?.alias || [],
    redirect: indexYerGb0viNjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: joineQfMxYOidiMeta?.name ?? "events-event-join___en",
    path: joineQfMxYOidiMeta?.path ?? "join",
    meta: joineQfMxYOidiMeta || {},
    alias: joineQfMxYOidiMeta?.alias || [],
    redirect: joineQfMxYOidiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/join.vue").then(m => m.default || m)
  },
  {
    name: livei03zejafaIMeta?.name ?? "events-event-live___en",
    path: livei03zejafaIMeta?.path ?? "live",
    meta: livei03zejafaIMeta || {},
    alias: livei03zejafaIMeta?.alias || [],
    redirect: livei03zejafaIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/live.vue").then(m => m.default || m)
  },
  {
    name: map9iktEiyr3PMeta?.name ?? "events-event-map___en",
    path: map9iktEiyr3PMeta?.path ?? "map",
    meta: map9iktEiyr3PMeta || {},
    alias: map9iktEiyr3PMeta?.alias || [],
    redirect: map9iktEiyr3PMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/map.vue").then(m => m.default || m)
  },
  {
    name: assignXgQ3yfZNeIMeta?.name ?? "events-event-members-assign___en",
    path: assignXgQ3yfZNeIMeta?.path ?? "members/assign",
    meta: assignXgQ3yfZNeIMeta || {},
    alias: assignXgQ3yfZNeIMeta?.alias || [],
    redirect: assignXgQ3yfZNeIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/members/assign.vue").then(m => m.default || m)
  },
  {
    name: newsOwnCZH2whjMeta?.name ?? "events-event-news___en",
    path: newsOwnCZH2whjMeta?.path ?? "news",
    meta: newsOwnCZH2whjMeta || {},
    alias: newsOwnCZH2whjMeta?.alias || [],
    redirect: newsOwnCZH2whjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/news.vue").then(m => m.default || m)
  },
  {
    name: index8WpzEpWxA7Meta?.name ?? "events-event-participate___en",
    path: index8WpzEpWxA7Meta?.path ?? "participate",
    meta: index8WpzEpWxA7Meta || {},
    alias: index8WpzEpWxA7Meta?.alias || [],
    redirect: index8WpzEpWxA7Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participate/index.vue").then(m => m.default || m)
  },
  {
    name: index69lp8kbMmqMeta?.name ?? "events-event-participation-classes___en",
    path: index69lp8kbMmqMeta?.path ?? "participation-classes",
    meta: index69lp8kbMmqMeta || {},
    alias: index69lp8kbMmqMeta?.alias || [],
    redirect: index69lp8kbMmqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participation-classes/index.vue").then(m => m.default || m)
  },
  {
    name: _91participation_93gkfPBEb8U4Meta?.name ?? undefined,
    path: _91participation_93gkfPBEb8U4Meta?.path ?? "participations/:participation()",
    meta: _91participation_93gkfPBEb8U4Meta || {},
    alias: _91participation_93gkfPBEb8U4Meta?.alias || [],
    redirect: _91participation_93gkfPBEb8U4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation].vue").then(m => m.default || m),
    children: [
  {
    name: createvd4DjGJxhbMeta?.name ?? "events-event-participations-participation-attempts-create___en",
    path: createvd4DjGJxhbMeta?.path ?? "attempts/create",
    meta: createvd4DjGJxhbMeta || {},
    alias: createvd4DjGJxhbMeta?.alias || [],
    redirect: createvd4DjGJxhbMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/attempts/create.vue").then(m => m.default || m)
  },
  {
    name: campaignskldeFLVBW0Meta?.name ?? "events-event-participations-participation-campaigns___en",
    path: campaignskldeFLVBW0Meta?.path ?? "campaigns",
    meta: campaignskldeFLVBW0Meta || {},
    alias: campaignskldeFLVBW0Meta?.alias || [],
    redirect: campaignskldeFLVBW0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/campaigns.vue").then(m => m.default || m)
  },
  {
    name: editmzfOVtxBgyMeta?.name ?? "events-event-participations-participation-edit___en",
    path: editmzfOVtxBgyMeta?.path ?? "edit",
    meta: editmzfOVtxBgyMeta || {},
    alias: editmzfOVtxBgyMeta?.alias || [],
    redirect: editmzfOVtxBgyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZC7GISVufCMeta?.name ?? "events-event-participations-participation___en",
    path: indexZC7GISVufCMeta?.path ?? "",
    meta: indexZC7GISVufCMeta || {},
    alias: indexZC7GISVufCMeta?.alias || [],
    redirect: indexZC7GISVufCMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/index.vue").then(m => m.default || m)
  },
  {
    name: mapayBBY5KPaqMeta?.name ?? "events-event-participations-participation-map___en",
    path: mapayBBY5KPaqMeta?.path ?? "map",
    meta: mapayBBY5KPaqMeta || {},
    alias: mapayBBY5KPaqMeta?.alias || [],
    redirect: mapayBBY5KPaqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/map.vue").then(m => m.default || m)
  },
  {
    name: paymentZ7AxDGbbrFMeta?.name ?? "events-event-participations-participation-payment___en",
    path: paymentZ7AxDGbbrFMeta?.path ?? "payment",
    meta: paymentZ7AxDGbbrFMeta || {},
    alias: paymentZ7AxDGbbrFMeta?.alias || [],
    redirect: paymentZ7AxDGbbrFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/payment.vue").then(m => m.default || m)
  },
  {
    name: productswtDywd9Kn0Meta?.name ?? "events-event-participations-participation-products___en",
    path: productswtDywd9Kn0Meta?.path ?? "products",
    meta: productswtDywd9Kn0Meta || {},
    alias: productswtDywd9Kn0Meta?.alias || [],
    redirect: productswtDywd9Kn0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/products.vue").then(m => m.default || m)
  },
  {
    name: resultsNVfnLT1IeNMeta?.name ?? "events-event-participations-participation-results___en",
    path: resultsNVfnLT1IeNMeta?.path ?? "results",
    meta: resultsNVfnLT1IeNMeta || {},
    alias: resultsNVfnLT1IeNMeta?.alias || [],
    redirect: resultsNVfnLT1IeNMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/results.vue").then(m => m.default || m)
  },
  {
    name: starttSRpc0FxFQMeta?.name ?? "events-event-participations-participation-start___en",
    path: starttSRpc0FxFQMeta?.path ?? "start",
    meta: starttSRpc0FxFQMeta || {},
    alias: starttSRpc0FxFQMeta?.alias || [],
    redirect: starttSRpc0FxFQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/start.vue").then(m => m.default || m)
  },
  {
    name: tracking8mSiPkSVu6Meta?.name ?? "events-event-participations-participation-tracking___en",
    path: tracking8mSiPkSVu6Meta?.path ?? "tracking",
    meta: tracking8mSiPkSVu6Meta || {},
    alias: tracking8mSiPkSVu6Meta?.alias || [],
    redirect: tracking8mSiPkSVu6Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/tracking.vue").then(m => m.default || m)
  }
]
  },
  {
    name: assignMAKOjFXtjaMeta?.name ?? "events-event-participations-assign___en",
    path: assignMAKOjFXtjaMeta?.path ?? "participations/assign",
    meta: assignMAKOjFXtjaMeta || {},
    alias: assignMAKOjFXtjaMeta?.alias || [],
    redirect: assignMAKOjFXtjaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/assign.vue").then(m => m.default || m)
  },
  {
    name: indexGnp7IOM4gVMeta?.name ?? "events-event-participations___en",
    path: indexGnp7IOM4gVMeta?.path ?? "participations",
    meta: indexGnp7IOM4gVMeta || {},
    alias: indexGnp7IOM4gVMeta?.alias || [],
    redirect: indexGnp7IOM4gVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/index.vue").then(m => m.default || m)
  },
  {
    name: photosdGAycOCARdMeta?.name ?? undefined,
    path: photosdGAycOCARdMeta?.path ?? "photos",
    meta: photosdGAycOCARdMeta || {},
    alias: photosdGAycOCARdMeta?.alias || [],
    redirect: photosdGAycOCARdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos.vue").then(m => m.default || m),
    children: [
  {
    name: _91gallery_9336ERkWkwCxMeta?.name ?? "events-event-photos-gallery___en",
    path: _91gallery_9336ERkWkwCxMeta?.path ?? ":gallery()",
    meta: _91gallery_9336ERkWkwCxMeta || {},
    alias: _91gallery_9336ERkWkwCxMeta?.alias || [],
    redirect: _91gallery_9336ERkWkwCxMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/[gallery].vue").then(m => m.default || m)
  },
  {
    name: carth37kQrbsqcMeta?.name ?? "events-event-photos-cart___en",
    path: carth37kQrbsqcMeta?.path ?? "cart",
    meta: carth37kQrbsqcMeta || {},
    alias: carth37kQrbsqcMeta?.alias || [],
    redirect: carth37kQrbsqcMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/cart.vue").then(m => m.default || m)
  },
  {
    name: indexP6WOwcYmiYMeta?.name ?? "events-event-photos___en",
    path: indexP6WOwcYmiYMeta?.path ?? "",
    meta: indexP6WOwcYmiYMeta || {},
    alias: indexP6WOwcYmiYMeta?.alias || [],
    redirect: indexP6WOwcYmiYMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: productsgxS4Cq2HlkMeta?.name ?? "events-event-products___en",
    path: productsgxS4Cq2HlkMeta?.path ?? "products",
    meta: productsgxS4Cq2HlkMeta || {},
    alias: productsgxS4Cq2HlkMeta?.alias || [],
    redirect: productsgxS4Cq2HlkMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/products.vue").then(m => m.default || m)
  },
  {
    name: create87GhxG4G1IMeta?.name ?? "events-event-ratings-create___en",
    path: create87GhxG4G1IMeta?.path ?? "ratings/create",
    meta: create87GhxG4G1IMeta || {},
    alias: create87GhxG4G1IMeta?.alias || [],
    redirect: create87GhxG4G1IMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/ratings/create.vue").then(m => m.default || m)
  },
  {
    name: indexHH48F7rosMMeta?.name ?? "events-event-ratings___en",
    path: indexHH48F7rosMMeta?.path ?? "ratings",
    meta: indexHH48F7rosMMeta || {},
    alias: indexHH48F7rosMMeta?.alias || [],
    redirect: indexHH48F7rosMMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/ratings/index.vue").then(m => m.default || m)
  },
  {
    name: _91group_93PIEUw0GTQpMeta?.name ?? "events-event-results-group___en",
    path: _91group_93PIEUw0GTQpMeta?.path ?? "results/:group()",
    meta: _91group_93PIEUw0GTQpMeta || {},
    alias: _91group_93PIEUw0GTQpMeta?.alias || [],
    redirect: _91group_93PIEUw0GTQpMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/results/[group].vue").then(m => m.default || m)
  },
  {
    name: indexIUwJP2qta4Meta?.name ?? "events-event-results___en",
    path: indexIUwJP2qta4Meta?.path ?? "results",
    meta: indexIUwJP2qta4Meta || {},
    alias: indexIUwJP2qta4Meta?.alias || [],
    redirect: indexIUwJP2qta4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/results/index.vue").then(m => m.default || m)
  },
  {
    name: shopZgRb79KbMyMeta?.name ?? "events-event-shop___en",
    path: shopZgRb79KbMyMeta?.path ?? "shop",
    meta: shopZgRb79KbMyMeta || {},
    alias: shopZgRb79KbMyMeta?.alias || [],
    redirect: shopZgRb79KbMyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/shop.vue").then(m => m.default || m)
  },
  {
    name: sponsorsfvHDo8yOLQMeta?.name ?? "events-event-sponsors___en",
    path: sponsorsfvHDo8yOLQMeta?.path ?? "sponsors",
    meta: sponsorsfvHDo8yOLQMeta || {},
    alias: sponsorsfvHDo8yOLQMeta?.alias || [],
    redirect: sponsorsfvHDo8yOLQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/sponsors.vue").then(m => m.default || m)
  },
  {
    name: staySh8EK4LzZkMeta?.name ?? "events-event-stay___en",
    path: staySh8EK4LzZkMeta?.path ?? "stay",
    meta: staySh8EK4LzZkMeta || {},
    alias: staySh8EK4LzZkMeta?.alias || [],
    redirect: staySh8EK4LzZkMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/stay.vue").then(m => m.default || m)
  },
  {
    name: trackingU5bJt6tsQZMeta?.name ?? "events-event-tracking___en",
    path: trackingU5bJt6tsQZMeta?.path ?? "tracking",
    meta: trackingU5bJt6tsQZMeta || {},
    alias: trackingU5bJt6tsQZMeta?.alias || [],
    redirect: trackingU5bJt6tsQZMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/tracking.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91event_93Bek69s1NqNMeta?.name ?? undefined,
    path: _91event_93Bek69s1NqNMeta?.path ?? "/nl/events/:event()",
    meta: _91event_93Bek69s1NqNMeta || {},
    alias: _91event_93Bek69s1NqNMeta?.alias || [],
    redirect: _91event_93Bek69s1NqNMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event].vue").then(m => m.default || m),
    children: [
  {
    name: assignmentAHWZNNAgiUMeta?.name ?? "events-event-assignment___nl",
    path: assignmentAHWZNNAgiUMeta?.path ?? "assignment",
    meta: assignmentAHWZNNAgiUMeta || {},
    alias: assignmentAHWZNNAgiUMeta?.alias || [],
    redirect: assignmentAHWZNNAgiUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/assignment.vue").then(m => m.default || m)
  },
  {
    name: awardsgZ6qhbupOBMeta?.name ?? "events-event-awards___nl",
    path: awardsgZ6qhbupOBMeta?.path ?? "awards",
    meta: awardsgZ6qhbupOBMeta || {},
    alias: awardsgZ6qhbupOBMeta?.alias || [],
    redirect: awardsgZ6qhbupOBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/awards.vue").then(m => m.default || m)
  },
  {
    name: campaignsFKAtULfnBJMeta?.name ?? "events-event-campaigns___nl",
    path: campaignsFKAtULfnBJMeta?.path ?? "campaigns",
    meta: campaignsFKAtULfnBJMeta || {},
    alias: campaignsFKAtULfnBJMeta?.alias || [],
    redirect: campaignsFKAtULfnBJMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/campaigns.vue").then(m => m.default || m)
  },
  {
    name: communitylZYRcas0pLMeta?.name ?? "events-event-community___nl",
    path: communitylZYRcas0pLMeta?.path ?? "community",
    meta: communitylZYRcas0pLMeta || {},
    alias: communitylZYRcas0pLMeta?.alias || [],
    redirect: communitylZYRcas0pLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/community.vue").then(m => m.default || m)
  },
  {
    name: contactoNymCpb30mMeta?.name ?? "events-event-contact___nl",
    path: contactoNymCpb30mMeta?.path ?? "contact",
    meta: contactoNymCpb30mMeta || {},
    alias: contactoNymCpb30mMeta?.alias || [],
    redirect: contactoNymCpb30mMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/contact.vue").then(m => m.default || m)
  },
  {
    name: gtc1aSZmzZzZBMeta?.name ?? "events-event-gtc___nl",
    path: gtc1aSZmzZzZBMeta?.path ?? "gtc",
    meta: gtc1aSZmzZzZBMeta || {},
    alias: gtc1aSZmzZzZBMeta?.alias || [],
    redirect: gtc1aSZmzZzZBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/gtc.vue").then(m => m.default || m)
  },
  {
    name: impressionsh0BmFJPG9NMeta?.name ?? "events-event-impressions___nl",
    path: impressionsh0BmFJPG9NMeta?.path ?? "impressions",
    meta: impressionsh0BmFJPG9NMeta || {},
    alias: impressionsh0BmFJPG9NMeta?.alias || [],
    redirect: impressionsh0BmFJPG9NMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/impressions.vue").then(m => m.default || m)
  },
  {
    name: indexnFQ7qTpyblMeta?.name ?? "events-event___nl",
    path: indexnFQ7qTpyblMeta?.path ?? "",
    meta: indexnFQ7qTpyblMeta || {},
    alias: indexnFQ7qTpyblMeta?.alias || [],
    redirect: indexnFQ7qTpyblMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: _91job_93dDwZg9isffMeta?.name ?? "events-event-jobs-job___nl",
    path: _91job_93dDwZg9isffMeta?.path ?? "jobs/:job()",
    meta: _91job_93dDwZg9isffMeta || {},
    alias: _91job_93dDwZg9isffMeta?.alias || [],
    redirect: _91job_93dDwZg9isffMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/jobs/[job].vue").then(m => m.default || m)
  },
  {
    name: indexYerGb0viNjMeta?.name ?? "events-event-jobs___nl",
    path: indexYerGb0viNjMeta?.path ?? "jobs",
    meta: indexYerGb0viNjMeta || {},
    alias: indexYerGb0viNjMeta?.alias || [],
    redirect: indexYerGb0viNjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: joineQfMxYOidiMeta?.name ?? "events-event-join___nl",
    path: joineQfMxYOidiMeta?.path ?? "join",
    meta: joineQfMxYOidiMeta || {},
    alias: joineQfMxYOidiMeta?.alias || [],
    redirect: joineQfMxYOidiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/join.vue").then(m => m.default || m)
  },
  {
    name: livei03zejafaIMeta?.name ?? "events-event-live___nl",
    path: livei03zejafaIMeta?.path ?? "live",
    meta: livei03zejafaIMeta || {},
    alias: livei03zejafaIMeta?.alias || [],
    redirect: livei03zejafaIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/live.vue").then(m => m.default || m)
  },
  {
    name: map9iktEiyr3PMeta?.name ?? "events-event-map___nl",
    path: map9iktEiyr3PMeta?.path ?? "map",
    meta: map9iktEiyr3PMeta || {},
    alias: map9iktEiyr3PMeta?.alias || [],
    redirect: map9iktEiyr3PMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/map.vue").then(m => m.default || m)
  },
  {
    name: assignXgQ3yfZNeIMeta?.name ?? "events-event-members-assign___nl",
    path: assignXgQ3yfZNeIMeta?.path ?? "members/assign",
    meta: assignXgQ3yfZNeIMeta || {},
    alias: assignXgQ3yfZNeIMeta?.alias || [],
    redirect: assignXgQ3yfZNeIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/members/assign.vue").then(m => m.default || m)
  },
  {
    name: newsOwnCZH2whjMeta?.name ?? "events-event-news___nl",
    path: newsOwnCZH2whjMeta?.path ?? "news",
    meta: newsOwnCZH2whjMeta || {},
    alias: newsOwnCZH2whjMeta?.alias || [],
    redirect: newsOwnCZH2whjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/news.vue").then(m => m.default || m)
  },
  {
    name: index8WpzEpWxA7Meta?.name ?? "events-event-participate___nl",
    path: index8WpzEpWxA7Meta?.path ?? "participate",
    meta: index8WpzEpWxA7Meta || {},
    alias: index8WpzEpWxA7Meta?.alias || [],
    redirect: index8WpzEpWxA7Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participate/index.vue").then(m => m.default || m)
  },
  {
    name: index69lp8kbMmqMeta?.name ?? "events-event-participation-classes___nl",
    path: index69lp8kbMmqMeta?.path ?? "participation-classes",
    meta: index69lp8kbMmqMeta || {},
    alias: index69lp8kbMmqMeta?.alias || [],
    redirect: index69lp8kbMmqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participation-classes/index.vue").then(m => m.default || m)
  },
  {
    name: _91participation_93gkfPBEb8U4Meta?.name ?? undefined,
    path: _91participation_93gkfPBEb8U4Meta?.path ?? "participations/:participation()",
    meta: _91participation_93gkfPBEb8U4Meta || {},
    alias: _91participation_93gkfPBEb8U4Meta?.alias || [],
    redirect: _91participation_93gkfPBEb8U4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation].vue").then(m => m.default || m),
    children: [
  {
    name: createvd4DjGJxhbMeta?.name ?? "events-event-participations-participation-attempts-create___nl",
    path: createvd4DjGJxhbMeta?.path ?? "attempts/create",
    meta: createvd4DjGJxhbMeta || {},
    alias: createvd4DjGJxhbMeta?.alias || [],
    redirect: createvd4DjGJxhbMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/attempts/create.vue").then(m => m.default || m)
  },
  {
    name: campaignskldeFLVBW0Meta?.name ?? "events-event-participations-participation-campaigns___nl",
    path: campaignskldeFLVBW0Meta?.path ?? "campaigns",
    meta: campaignskldeFLVBW0Meta || {},
    alias: campaignskldeFLVBW0Meta?.alias || [],
    redirect: campaignskldeFLVBW0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/campaigns.vue").then(m => m.default || m)
  },
  {
    name: editmzfOVtxBgyMeta?.name ?? "events-event-participations-participation-edit___nl",
    path: editmzfOVtxBgyMeta?.path ?? "edit",
    meta: editmzfOVtxBgyMeta || {},
    alias: editmzfOVtxBgyMeta?.alias || [],
    redirect: editmzfOVtxBgyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZC7GISVufCMeta?.name ?? "events-event-participations-participation___nl",
    path: indexZC7GISVufCMeta?.path ?? "",
    meta: indexZC7GISVufCMeta || {},
    alias: indexZC7GISVufCMeta?.alias || [],
    redirect: indexZC7GISVufCMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/index.vue").then(m => m.default || m)
  },
  {
    name: mapayBBY5KPaqMeta?.name ?? "events-event-participations-participation-map___nl",
    path: mapayBBY5KPaqMeta?.path ?? "map",
    meta: mapayBBY5KPaqMeta || {},
    alias: mapayBBY5KPaqMeta?.alias || [],
    redirect: mapayBBY5KPaqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/map.vue").then(m => m.default || m)
  },
  {
    name: paymentZ7AxDGbbrFMeta?.name ?? "events-event-participations-participation-payment___nl",
    path: paymentZ7AxDGbbrFMeta?.path ?? "payment",
    meta: paymentZ7AxDGbbrFMeta || {},
    alias: paymentZ7AxDGbbrFMeta?.alias || [],
    redirect: paymentZ7AxDGbbrFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/payment.vue").then(m => m.default || m)
  },
  {
    name: productswtDywd9Kn0Meta?.name ?? "events-event-participations-participation-products___nl",
    path: productswtDywd9Kn0Meta?.path ?? "products",
    meta: productswtDywd9Kn0Meta || {},
    alias: productswtDywd9Kn0Meta?.alias || [],
    redirect: productswtDywd9Kn0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/products.vue").then(m => m.default || m)
  },
  {
    name: resultsNVfnLT1IeNMeta?.name ?? "events-event-participations-participation-results___nl",
    path: resultsNVfnLT1IeNMeta?.path ?? "results",
    meta: resultsNVfnLT1IeNMeta || {},
    alias: resultsNVfnLT1IeNMeta?.alias || [],
    redirect: resultsNVfnLT1IeNMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/results.vue").then(m => m.default || m)
  },
  {
    name: starttSRpc0FxFQMeta?.name ?? "events-event-participations-participation-start___nl",
    path: starttSRpc0FxFQMeta?.path ?? "start",
    meta: starttSRpc0FxFQMeta || {},
    alias: starttSRpc0FxFQMeta?.alias || [],
    redirect: starttSRpc0FxFQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/start.vue").then(m => m.default || m)
  },
  {
    name: tracking8mSiPkSVu6Meta?.name ?? "events-event-participations-participation-tracking___nl",
    path: tracking8mSiPkSVu6Meta?.path ?? "tracking",
    meta: tracking8mSiPkSVu6Meta || {},
    alias: tracking8mSiPkSVu6Meta?.alias || [],
    redirect: tracking8mSiPkSVu6Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/tracking.vue").then(m => m.default || m)
  }
]
  },
  {
    name: assignMAKOjFXtjaMeta?.name ?? "events-event-participations-assign___nl",
    path: assignMAKOjFXtjaMeta?.path ?? "participations/assign",
    meta: assignMAKOjFXtjaMeta || {},
    alias: assignMAKOjFXtjaMeta?.alias || [],
    redirect: assignMAKOjFXtjaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/assign.vue").then(m => m.default || m)
  },
  {
    name: indexGnp7IOM4gVMeta?.name ?? "events-event-participations___nl",
    path: indexGnp7IOM4gVMeta?.path ?? "participations",
    meta: indexGnp7IOM4gVMeta || {},
    alias: indexGnp7IOM4gVMeta?.alias || [],
    redirect: indexGnp7IOM4gVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/index.vue").then(m => m.default || m)
  },
  {
    name: photosdGAycOCARdMeta?.name ?? undefined,
    path: photosdGAycOCARdMeta?.path ?? "photos",
    meta: photosdGAycOCARdMeta || {},
    alias: photosdGAycOCARdMeta?.alias || [],
    redirect: photosdGAycOCARdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos.vue").then(m => m.default || m),
    children: [
  {
    name: _91gallery_9336ERkWkwCxMeta?.name ?? "events-event-photos-gallery___nl",
    path: _91gallery_9336ERkWkwCxMeta?.path ?? ":gallery()",
    meta: _91gallery_9336ERkWkwCxMeta || {},
    alias: _91gallery_9336ERkWkwCxMeta?.alias || [],
    redirect: _91gallery_9336ERkWkwCxMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/[gallery].vue").then(m => m.default || m)
  },
  {
    name: carth37kQrbsqcMeta?.name ?? "events-event-photos-cart___nl",
    path: carth37kQrbsqcMeta?.path ?? "cart",
    meta: carth37kQrbsqcMeta || {},
    alias: carth37kQrbsqcMeta?.alias || [],
    redirect: carth37kQrbsqcMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/cart.vue").then(m => m.default || m)
  },
  {
    name: indexP6WOwcYmiYMeta?.name ?? "events-event-photos___nl",
    path: indexP6WOwcYmiYMeta?.path ?? "",
    meta: indexP6WOwcYmiYMeta || {},
    alias: indexP6WOwcYmiYMeta?.alias || [],
    redirect: indexP6WOwcYmiYMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: productsgxS4Cq2HlkMeta?.name ?? "events-event-products___nl",
    path: productsgxS4Cq2HlkMeta?.path ?? "products",
    meta: productsgxS4Cq2HlkMeta || {},
    alias: productsgxS4Cq2HlkMeta?.alias || [],
    redirect: productsgxS4Cq2HlkMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/products.vue").then(m => m.default || m)
  },
  {
    name: create87GhxG4G1IMeta?.name ?? "events-event-ratings-create___nl",
    path: create87GhxG4G1IMeta?.path ?? "ratings/create",
    meta: create87GhxG4G1IMeta || {},
    alias: create87GhxG4G1IMeta?.alias || [],
    redirect: create87GhxG4G1IMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/ratings/create.vue").then(m => m.default || m)
  },
  {
    name: indexHH48F7rosMMeta?.name ?? "events-event-ratings___nl",
    path: indexHH48F7rosMMeta?.path ?? "ratings",
    meta: indexHH48F7rosMMeta || {},
    alias: indexHH48F7rosMMeta?.alias || [],
    redirect: indexHH48F7rosMMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/ratings/index.vue").then(m => m.default || m)
  },
  {
    name: _91group_93PIEUw0GTQpMeta?.name ?? "events-event-results-group___nl",
    path: _91group_93PIEUw0GTQpMeta?.path ?? "results/:group()",
    meta: _91group_93PIEUw0GTQpMeta || {},
    alias: _91group_93PIEUw0GTQpMeta?.alias || [],
    redirect: _91group_93PIEUw0GTQpMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/results/[group].vue").then(m => m.default || m)
  },
  {
    name: indexIUwJP2qta4Meta?.name ?? "events-event-results___nl",
    path: indexIUwJP2qta4Meta?.path ?? "results",
    meta: indexIUwJP2qta4Meta || {},
    alias: indexIUwJP2qta4Meta?.alias || [],
    redirect: indexIUwJP2qta4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/results/index.vue").then(m => m.default || m)
  },
  {
    name: shopZgRb79KbMyMeta?.name ?? "events-event-shop___nl",
    path: shopZgRb79KbMyMeta?.path ?? "shop",
    meta: shopZgRb79KbMyMeta || {},
    alias: shopZgRb79KbMyMeta?.alias || [],
    redirect: shopZgRb79KbMyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/shop.vue").then(m => m.default || m)
  },
  {
    name: sponsorsfvHDo8yOLQMeta?.name ?? "events-event-sponsors___nl",
    path: sponsorsfvHDo8yOLQMeta?.path ?? "sponsors",
    meta: sponsorsfvHDo8yOLQMeta || {},
    alias: sponsorsfvHDo8yOLQMeta?.alias || [],
    redirect: sponsorsfvHDo8yOLQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/sponsors.vue").then(m => m.default || m)
  },
  {
    name: staySh8EK4LzZkMeta?.name ?? "events-event-stay___nl",
    path: staySh8EK4LzZkMeta?.path ?? "stay",
    meta: staySh8EK4LzZkMeta || {},
    alias: staySh8EK4LzZkMeta?.alias || [],
    redirect: staySh8EK4LzZkMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/stay.vue").then(m => m.default || m)
  },
  {
    name: trackingU5bJt6tsQZMeta?.name ?? "events-event-tracking___nl",
    path: trackingU5bJt6tsQZMeta?.path ?? "tracking",
    meta: trackingU5bJt6tsQZMeta || {},
    alias: trackingU5bJt6tsQZMeta?.alias || [],
    redirect: trackingU5bJt6tsQZMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/tracking.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91event_93Bek69s1NqNMeta?.name ?? undefined,
    path: _91event_93Bek69s1NqNMeta?.path ?? "/cs/events/:event()",
    meta: _91event_93Bek69s1NqNMeta || {},
    alias: _91event_93Bek69s1NqNMeta?.alias || [],
    redirect: _91event_93Bek69s1NqNMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event].vue").then(m => m.default || m),
    children: [
  {
    name: assignmentAHWZNNAgiUMeta?.name ?? "events-event-assignment___cs",
    path: assignmentAHWZNNAgiUMeta?.path ?? "assignment",
    meta: assignmentAHWZNNAgiUMeta || {},
    alias: assignmentAHWZNNAgiUMeta?.alias || [],
    redirect: assignmentAHWZNNAgiUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/assignment.vue").then(m => m.default || m)
  },
  {
    name: awardsgZ6qhbupOBMeta?.name ?? "events-event-awards___cs",
    path: awardsgZ6qhbupOBMeta?.path ?? "awards",
    meta: awardsgZ6qhbupOBMeta || {},
    alias: awardsgZ6qhbupOBMeta?.alias || [],
    redirect: awardsgZ6qhbupOBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/awards.vue").then(m => m.default || m)
  },
  {
    name: campaignsFKAtULfnBJMeta?.name ?? "events-event-campaigns___cs",
    path: campaignsFKAtULfnBJMeta?.path ?? "campaigns",
    meta: campaignsFKAtULfnBJMeta || {},
    alias: campaignsFKAtULfnBJMeta?.alias || [],
    redirect: campaignsFKAtULfnBJMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/campaigns.vue").then(m => m.default || m)
  },
  {
    name: communitylZYRcas0pLMeta?.name ?? "events-event-community___cs",
    path: communitylZYRcas0pLMeta?.path ?? "community",
    meta: communitylZYRcas0pLMeta || {},
    alias: communitylZYRcas0pLMeta?.alias || [],
    redirect: communitylZYRcas0pLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/community.vue").then(m => m.default || m)
  },
  {
    name: contactoNymCpb30mMeta?.name ?? "events-event-contact___cs",
    path: contactoNymCpb30mMeta?.path ?? "contact",
    meta: contactoNymCpb30mMeta || {},
    alias: contactoNymCpb30mMeta?.alias || [],
    redirect: contactoNymCpb30mMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/contact.vue").then(m => m.default || m)
  },
  {
    name: gtc1aSZmzZzZBMeta?.name ?? "events-event-gtc___cs",
    path: gtc1aSZmzZzZBMeta?.path ?? "gtc",
    meta: gtc1aSZmzZzZBMeta || {},
    alias: gtc1aSZmzZzZBMeta?.alias || [],
    redirect: gtc1aSZmzZzZBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/gtc.vue").then(m => m.default || m)
  },
  {
    name: impressionsh0BmFJPG9NMeta?.name ?? "events-event-impressions___cs",
    path: impressionsh0BmFJPG9NMeta?.path ?? "impressions",
    meta: impressionsh0BmFJPG9NMeta || {},
    alias: impressionsh0BmFJPG9NMeta?.alias || [],
    redirect: impressionsh0BmFJPG9NMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/impressions.vue").then(m => m.default || m)
  },
  {
    name: indexnFQ7qTpyblMeta?.name ?? "events-event___cs",
    path: indexnFQ7qTpyblMeta?.path ?? "",
    meta: indexnFQ7qTpyblMeta || {},
    alias: indexnFQ7qTpyblMeta?.alias || [],
    redirect: indexnFQ7qTpyblMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: _91job_93dDwZg9isffMeta?.name ?? "events-event-jobs-job___cs",
    path: _91job_93dDwZg9isffMeta?.path ?? "jobs/:job()",
    meta: _91job_93dDwZg9isffMeta || {},
    alias: _91job_93dDwZg9isffMeta?.alias || [],
    redirect: _91job_93dDwZg9isffMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/jobs/[job].vue").then(m => m.default || m)
  },
  {
    name: indexYerGb0viNjMeta?.name ?? "events-event-jobs___cs",
    path: indexYerGb0viNjMeta?.path ?? "jobs",
    meta: indexYerGb0viNjMeta || {},
    alias: indexYerGb0viNjMeta?.alias || [],
    redirect: indexYerGb0viNjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: joineQfMxYOidiMeta?.name ?? "events-event-join___cs",
    path: joineQfMxYOidiMeta?.path ?? "join",
    meta: joineQfMxYOidiMeta || {},
    alias: joineQfMxYOidiMeta?.alias || [],
    redirect: joineQfMxYOidiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/join.vue").then(m => m.default || m)
  },
  {
    name: livei03zejafaIMeta?.name ?? "events-event-live___cs",
    path: livei03zejafaIMeta?.path ?? "live",
    meta: livei03zejafaIMeta || {},
    alias: livei03zejafaIMeta?.alias || [],
    redirect: livei03zejafaIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/live.vue").then(m => m.default || m)
  },
  {
    name: map9iktEiyr3PMeta?.name ?? "events-event-map___cs",
    path: map9iktEiyr3PMeta?.path ?? "map",
    meta: map9iktEiyr3PMeta || {},
    alias: map9iktEiyr3PMeta?.alias || [],
    redirect: map9iktEiyr3PMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/map.vue").then(m => m.default || m)
  },
  {
    name: assignXgQ3yfZNeIMeta?.name ?? "events-event-members-assign___cs",
    path: assignXgQ3yfZNeIMeta?.path ?? "members/assign",
    meta: assignXgQ3yfZNeIMeta || {},
    alias: assignXgQ3yfZNeIMeta?.alias || [],
    redirect: assignXgQ3yfZNeIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/members/assign.vue").then(m => m.default || m)
  },
  {
    name: newsOwnCZH2whjMeta?.name ?? "events-event-news___cs",
    path: newsOwnCZH2whjMeta?.path ?? "news",
    meta: newsOwnCZH2whjMeta || {},
    alias: newsOwnCZH2whjMeta?.alias || [],
    redirect: newsOwnCZH2whjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/news.vue").then(m => m.default || m)
  },
  {
    name: index8WpzEpWxA7Meta?.name ?? "events-event-participate___cs",
    path: index8WpzEpWxA7Meta?.path ?? "participate",
    meta: index8WpzEpWxA7Meta || {},
    alias: index8WpzEpWxA7Meta?.alias || [],
    redirect: index8WpzEpWxA7Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participate/index.vue").then(m => m.default || m)
  },
  {
    name: index69lp8kbMmqMeta?.name ?? "events-event-participation-classes___cs",
    path: index69lp8kbMmqMeta?.path ?? "participation-classes",
    meta: index69lp8kbMmqMeta || {},
    alias: index69lp8kbMmqMeta?.alias || [],
    redirect: index69lp8kbMmqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participation-classes/index.vue").then(m => m.default || m)
  },
  {
    name: _91participation_93gkfPBEb8U4Meta?.name ?? undefined,
    path: _91participation_93gkfPBEb8U4Meta?.path ?? "participations/:participation()",
    meta: _91participation_93gkfPBEb8U4Meta || {},
    alias: _91participation_93gkfPBEb8U4Meta?.alias || [],
    redirect: _91participation_93gkfPBEb8U4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation].vue").then(m => m.default || m),
    children: [
  {
    name: createvd4DjGJxhbMeta?.name ?? "events-event-participations-participation-attempts-create___cs",
    path: createvd4DjGJxhbMeta?.path ?? "attempts/create",
    meta: createvd4DjGJxhbMeta || {},
    alias: createvd4DjGJxhbMeta?.alias || [],
    redirect: createvd4DjGJxhbMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/attempts/create.vue").then(m => m.default || m)
  },
  {
    name: campaignskldeFLVBW0Meta?.name ?? "events-event-participations-participation-campaigns___cs",
    path: campaignskldeFLVBW0Meta?.path ?? "campaigns",
    meta: campaignskldeFLVBW0Meta || {},
    alias: campaignskldeFLVBW0Meta?.alias || [],
    redirect: campaignskldeFLVBW0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/campaigns.vue").then(m => m.default || m)
  },
  {
    name: editmzfOVtxBgyMeta?.name ?? "events-event-participations-participation-edit___cs",
    path: editmzfOVtxBgyMeta?.path ?? "edit",
    meta: editmzfOVtxBgyMeta || {},
    alias: editmzfOVtxBgyMeta?.alias || [],
    redirect: editmzfOVtxBgyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZC7GISVufCMeta?.name ?? "events-event-participations-participation___cs",
    path: indexZC7GISVufCMeta?.path ?? "",
    meta: indexZC7GISVufCMeta || {},
    alias: indexZC7GISVufCMeta?.alias || [],
    redirect: indexZC7GISVufCMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/index.vue").then(m => m.default || m)
  },
  {
    name: mapayBBY5KPaqMeta?.name ?? "events-event-participations-participation-map___cs",
    path: mapayBBY5KPaqMeta?.path ?? "map",
    meta: mapayBBY5KPaqMeta || {},
    alias: mapayBBY5KPaqMeta?.alias || [],
    redirect: mapayBBY5KPaqMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/map.vue").then(m => m.default || m)
  },
  {
    name: paymentZ7AxDGbbrFMeta?.name ?? "events-event-participations-participation-payment___cs",
    path: paymentZ7AxDGbbrFMeta?.path ?? "payment",
    meta: paymentZ7AxDGbbrFMeta || {},
    alias: paymentZ7AxDGbbrFMeta?.alias || [],
    redirect: paymentZ7AxDGbbrFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/payment.vue").then(m => m.default || m)
  },
  {
    name: productswtDywd9Kn0Meta?.name ?? "events-event-participations-participation-products___cs",
    path: productswtDywd9Kn0Meta?.path ?? "products",
    meta: productswtDywd9Kn0Meta || {},
    alias: productswtDywd9Kn0Meta?.alias || [],
    redirect: productswtDywd9Kn0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/products.vue").then(m => m.default || m)
  },
  {
    name: resultsNVfnLT1IeNMeta?.name ?? "events-event-participations-participation-results___cs",
    path: resultsNVfnLT1IeNMeta?.path ?? "results",
    meta: resultsNVfnLT1IeNMeta || {},
    alias: resultsNVfnLT1IeNMeta?.alias || [],
    redirect: resultsNVfnLT1IeNMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/results.vue").then(m => m.default || m)
  },
  {
    name: starttSRpc0FxFQMeta?.name ?? "events-event-participations-participation-start___cs",
    path: starttSRpc0FxFQMeta?.path ?? "start",
    meta: starttSRpc0FxFQMeta || {},
    alias: starttSRpc0FxFQMeta?.alias || [],
    redirect: starttSRpc0FxFQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/start.vue").then(m => m.default || m)
  },
  {
    name: tracking8mSiPkSVu6Meta?.name ?? "events-event-participations-participation-tracking___cs",
    path: tracking8mSiPkSVu6Meta?.path ?? "tracking",
    meta: tracking8mSiPkSVu6Meta || {},
    alias: tracking8mSiPkSVu6Meta?.alias || [],
    redirect: tracking8mSiPkSVu6Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/[participation]/tracking.vue").then(m => m.default || m)
  }
]
  },
  {
    name: assignMAKOjFXtjaMeta?.name ?? "events-event-participations-assign___cs",
    path: assignMAKOjFXtjaMeta?.path ?? "participations/assign",
    meta: assignMAKOjFXtjaMeta || {},
    alias: assignMAKOjFXtjaMeta?.alias || [],
    redirect: assignMAKOjFXtjaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/assign.vue").then(m => m.default || m)
  },
  {
    name: indexGnp7IOM4gVMeta?.name ?? "events-event-participations___cs",
    path: indexGnp7IOM4gVMeta?.path ?? "participations",
    meta: indexGnp7IOM4gVMeta || {},
    alias: indexGnp7IOM4gVMeta?.alias || [],
    redirect: indexGnp7IOM4gVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/participations/index.vue").then(m => m.default || m)
  },
  {
    name: photosdGAycOCARdMeta?.name ?? undefined,
    path: photosdGAycOCARdMeta?.path ?? "photos",
    meta: photosdGAycOCARdMeta || {},
    alias: photosdGAycOCARdMeta?.alias || [],
    redirect: photosdGAycOCARdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos.vue").then(m => m.default || m),
    children: [
  {
    name: _91gallery_9336ERkWkwCxMeta?.name ?? "events-event-photos-gallery___cs",
    path: _91gallery_9336ERkWkwCxMeta?.path ?? ":gallery()",
    meta: _91gallery_9336ERkWkwCxMeta || {},
    alias: _91gallery_9336ERkWkwCxMeta?.alias || [],
    redirect: _91gallery_9336ERkWkwCxMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/[gallery].vue").then(m => m.default || m)
  },
  {
    name: carth37kQrbsqcMeta?.name ?? "events-event-photos-cart___cs",
    path: carth37kQrbsqcMeta?.path ?? "cart",
    meta: carth37kQrbsqcMeta || {},
    alias: carth37kQrbsqcMeta?.alias || [],
    redirect: carth37kQrbsqcMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/cart.vue").then(m => m.default || m)
  },
  {
    name: indexP6WOwcYmiYMeta?.name ?? "events-event-photos___cs",
    path: indexP6WOwcYmiYMeta?.path ?? "",
    meta: indexP6WOwcYmiYMeta || {},
    alias: indexP6WOwcYmiYMeta?.alias || [],
    redirect: indexP6WOwcYmiYMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/photos/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: productsgxS4Cq2HlkMeta?.name ?? "events-event-products___cs",
    path: productsgxS4Cq2HlkMeta?.path ?? "products",
    meta: productsgxS4Cq2HlkMeta || {},
    alias: productsgxS4Cq2HlkMeta?.alias || [],
    redirect: productsgxS4Cq2HlkMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/products.vue").then(m => m.default || m)
  },
  {
    name: create87GhxG4G1IMeta?.name ?? "events-event-ratings-create___cs",
    path: create87GhxG4G1IMeta?.path ?? "ratings/create",
    meta: create87GhxG4G1IMeta || {},
    alias: create87GhxG4G1IMeta?.alias || [],
    redirect: create87GhxG4G1IMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/ratings/create.vue").then(m => m.default || m)
  },
  {
    name: indexHH48F7rosMMeta?.name ?? "events-event-ratings___cs",
    path: indexHH48F7rosMMeta?.path ?? "ratings",
    meta: indexHH48F7rosMMeta || {},
    alias: indexHH48F7rosMMeta?.alias || [],
    redirect: indexHH48F7rosMMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/ratings/index.vue").then(m => m.default || m)
  },
  {
    name: _91group_93PIEUw0GTQpMeta?.name ?? "events-event-results-group___cs",
    path: _91group_93PIEUw0GTQpMeta?.path ?? "results/:group()",
    meta: _91group_93PIEUw0GTQpMeta || {},
    alias: _91group_93PIEUw0GTQpMeta?.alias || [],
    redirect: _91group_93PIEUw0GTQpMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/results/[group].vue").then(m => m.default || m)
  },
  {
    name: indexIUwJP2qta4Meta?.name ?? "events-event-results___cs",
    path: indexIUwJP2qta4Meta?.path ?? "results",
    meta: indexIUwJP2qta4Meta || {},
    alias: indexIUwJP2qta4Meta?.alias || [],
    redirect: indexIUwJP2qta4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/results/index.vue").then(m => m.default || m)
  },
  {
    name: shopZgRb79KbMyMeta?.name ?? "events-event-shop___cs",
    path: shopZgRb79KbMyMeta?.path ?? "shop",
    meta: shopZgRb79KbMyMeta || {},
    alias: shopZgRb79KbMyMeta?.alias || [],
    redirect: shopZgRb79KbMyMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/shop.vue").then(m => m.default || m)
  },
  {
    name: sponsorsfvHDo8yOLQMeta?.name ?? "events-event-sponsors___cs",
    path: sponsorsfvHDo8yOLQMeta?.path ?? "sponsors",
    meta: sponsorsfvHDo8yOLQMeta || {},
    alias: sponsorsfvHDo8yOLQMeta?.alias || [],
    redirect: sponsorsfvHDo8yOLQMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/sponsors.vue").then(m => m.default || m)
  },
  {
    name: staySh8EK4LzZkMeta?.name ?? "events-event-stay___cs",
    path: staySh8EK4LzZkMeta?.path ?? "stay",
    meta: staySh8EK4LzZkMeta || {},
    alias: staySh8EK4LzZkMeta?.alias || [],
    redirect: staySh8EK4LzZkMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/stay.vue").then(m => m.default || m)
  },
  {
    name: trackingU5bJt6tsQZMeta?.name ?? "events-event-tracking___cs",
    path: trackingU5bJt6tsQZMeta?.path ?? "tracking",
    meta: trackingU5bJt6tsQZMeta || {},
    alias: trackingU5bJt6tsQZMeta?.alias || [],
    redirect: trackingU5bJt6tsQZMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/[event]/tracking.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91group_93fAhH6kWFUKMeta?.name ?? "events-groups-group___de",
    path: _91group_93fAhH6kWFUKMeta?.path ?? "/events/groups/:group()",
    meta: _91group_93fAhH6kWFUKMeta || {},
    alias: _91group_93fAhH6kWFUKMeta?.alias || [],
    redirect: _91group_93fAhH6kWFUKMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/groups/[group].vue").then(m => m.default || m)
  },
  {
    name: _91group_93fAhH6kWFUKMeta?.name ?? "events-groups-group___en",
    path: _91group_93fAhH6kWFUKMeta?.path ?? "/en/events/groups/:group()",
    meta: _91group_93fAhH6kWFUKMeta || {},
    alias: _91group_93fAhH6kWFUKMeta?.alias || [],
    redirect: _91group_93fAhH6kWFUKMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/groups/[group].vue").then(m => m.default || m)
  },
  {
    name: _91group_93fAhH6kWFUKMeta?.name ?? "events-groups-group___nl",
    path: _91group_93fAhH6kWFUKMeta?.path ?? "/nl/events/groups/:group()",
    meta: _91group_93fAhH6kWFUKMeta || {},
    alias: _91group_93fAhH6kWFUKMeta?.alias || [],
    redirect: _91group_93fAhH6kWFUKMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/groups/[group].vue").then(m => m.default || m)
  },
  {
    name: _91group_93fAhH6kWFUKMeta?.name ?? "events-groups-group___cs",
    path: _91group_93fAhH6kWFUKMeta?.path ?? "/cs/events/groups/:group()",
    meta: _91group_93fAhH6kWFUKMeta || {},
    alias: _91group_93fAhH6kWFUKMeta?.alias || [],
    redirect: _91group_93fAhH6kWFUKMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/groups/[group].vue").then(m => m.default || m)
  },
  {
    name: indexk5FDPznNfGMeta?.name ?? "events___de",
    path: indexk5FDPznNfGMeta?.path ?? "/events",
    meta: indexk5FDPznNfGMeta || {},
    alias: indexk5FDPznNfGMeta?.alias || [],
    redirect: indexk5FDPznNfGMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexk5FDPznNfGMeta?.name ?? "events___en",
    path: indexk5FDPznNfGMeta?.path ?? "/en/events",
    meta: indexk5FDPznNfGMeta || {},
    alias: indexk5FDPznNfGMeta?.alias || [],
    redirect: indexk5FDPznNfGMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexk5FDPznNfGMeta?.name ?? "events___nl",
    path: indexk5FDPznNfGMeta?.path ?? "/nl/events",
    meta: indexk5FDPznNfGMeta || {},
    alias: indexk5FDPznNfGMeta?.alias || [],
    redirect: indexk5FDPznNfGMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexk5FDPznNfGMeta?.name ?? "events___cs",
    path: indexk5FDPznNfGMeta?.path ?? "/cs/events",
    meta: indexk5FDPznNfGMeta || {},
    alias: indexk5FDPznNfGMeta?.alias || [],
    redirect: indexk5FDPznNfGMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: gtcvt9Fjv26eIMeta?.name ?? "gtc___de",
    path: gtcvt9Fjv26eIMeta?.path ?? "/gtc",
    meta: gtcvt9Fjv26eIMeta || {},
    alias: gtcvt9Fjv26eIMeta?.alias || [],
    redirect: gtcvt9Fjv26eIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/gtc.vue").then(m => m.default || m)
  },
  {
    name: gtcvt9Fjv26eIMeta?.name ?? "gtc___en",
    path: gtcvt9Fjv26eIMeta?.path ?? "/en/gtc",
    meta: gtcvt9Fjv26eIMeta || {},
    alias: gtcvt9Fjv26eIMeta?.alias || [],
    redirect: gtcvt9Fjv26eIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/gtc.vue").then(m => m.default || m)
  },
  {
    name: gtcvt9Fjv26eIMeta?.name ?? "gtc___nl",
    path: gtcvt9Fjv26eIMeta?.path ?? "/nl/gtc",
    meta: gtcvt9Fjv26eIMeta || {},
    alias: gtcvt9Fjv26eIMeta?.alias || [],
    redirect: gtcvt9Fjv26eIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/gtc.vue").then(m => m.default || m)
  },
  {
    name: gtcvt9Fjv26eIMeta?.name ?? "gtc___cs",
    path: gtcvt9Fjv26eIMeta?.path ?? "/cs/gtc",
    meta: gtcvt9Fjv26eIMeta || {},
    alias: gtcvt9Fjv26eIMeta?.alias || [],
    redirect: gtcvt9Fjv26eIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/gtc.vue").then(m => m.default || m)
  },
  {
    name: imprinttybrKesDc7Meta?.name ?? "imprint___de",
    path: imprinttybrKesDc7Meta?.path ?? "/imprint",
    meta: imprinttybrKesDc7Meta || {},
    alias: imprinttybrKesDc7Meta?.alias || [],
    redirect: imprinttybrKesDc7Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprinttybrKesDc7Meta?.name ?? "imprint___en",
    path: imprinttybrKesDc7Meta?.path ?? "/en/imprint",
    meta: imprinttybrKesDc7Meta || {},
    alias: imprinttybrKesDc7Meta?.alias || [],
    redirect: imprinttybrKesDc7Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprinttybrKesDc7Meta?.name ?? "imprint___nl",
    path: imprinttybrKesDc7Meta?.path ?? "/nl/imprint",
    meta: imprinttybrKesDc7Meta || {},
    alias: imprinttybrKesDc7Meta?.alias || [],
    redirect: imprinttybrKesDc7Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprinttybrKesDc7Meta?.name ?? "imprint___cs",
    path: imprinttybrKesDc7Meta?.path ?? "/cs/imprint",
    meta: imprinttybrKesDc7Meta || {},
    alias: imprinttybrKesDc7Meta?.alias || [],
    redirect: imprinttybrKesDc7Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: indexLgvpHsodJbMeta?.name ?? "index___de",
    path: indexLgvpHsodJbMeta?.path ?? "/",
    meta: indexLgvpHsodJbMeta || {},
    alias: indexLgvpHsodJbMeta?.alias || [],
    redirect: indexLgvpHsodJbMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexLgvpHsodJbMeta?.name ?? "index___en",
    path: indexLgvpHsodJbMeta?.path ?? "/en",
    meta: indexLgvpHsodJbMeta || {},
    alias: indexLgvpHsodJbMeta?.alias || [],
    redirect: indexLgvpHsodJbMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexLgvpHsodJbMeta?.name ?? "index___nl",
    path: indexLgvpHsodJbMeta?.path ?? "/nl",
    meta: indexLgvpHsodJbMeta || {},
    alias: indexLgvpHsodJbMeta?.alias || [],
    redirect: indexLgvpHsodJbMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexLgvpHsodJbMeta?.name ?? "index___cs",
    path: indexLgvpHsodJbMeta?.path ?? "/cs",
    meta: indexLgvpHsodJbMeta || {},
    alias: indexLgvpHsodJbMeta?.alias || [],
    redirect: indexLgvpHsodJbMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91posting_93icH5buWv4oMeta?.name ?? "jobs-type-model-posting___de",
    path: _91posting_93icH5buWv4oMeta?.path ?? "/jobs/:type()/:model()/:posting()",
    meta: _91posting_93icH5buWv4oMeta || {},
    alias: _91posting_93icH5buWv4oMeta?.alias || [],
    redirect: _91posting_93icH5buWv4oMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/[model]/[posting].vue").then(m => m.default || m)
  },
  {
    name: _91posting_93icH5buWv4oMeta?.name ?? "jobs-type-model-posting___en",
    path: _91posting_93icH5buWv4oMeta?.path ?? "/en/jobs/:type()/:model()/:posting()",
    meta: _91posting_93icH5buWv4oMeta || {},
    alias: _91posting_93icH5buWv4oMeta?.alias || [],
    redirect: _91posting_93icH5buWv4oMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/[model]/[posting].vue").then(m => m.default || m)
  },
  {
    name: _91posting_93icH5buWv4oMeta?.name ?? "jobs-type-model-posting___nl",
    path: _91posting_93icH5buWv4oMeta?.path ?? "/nl/jobs/:type()/:model()/:posting()",
    meta: _91posting_93icH5buWv4oMeta || {},
    alias: _91posting_93icH5buWv4oMeta?.alias || [],
    redirect: _91posting_93icH5buWv4oMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/[model]/[posting].vue").then(m => m.default || m)
  },
  {
    name: _91posting_93icH5buWv4oMeta?.name ?? "jobs-type-model-posting___cs",
    path: _91posting_93icH5buWv4oMeta?.path ?? "/cs/jobs/:type()/:model()/:posting()",
    meta: _91posting_93icH5buWv4oMeta || {},
    alias: _91posting_93icH5buWv4oMeta?.alias || [],
    redirect: _91posting_93icH5buWv4oMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/[model]/[posting].vue").then(m => m.default || m)
  },
  {
    name: indexU547GCrkq0Meta?.name ?? "jobs-type-model___de",
    path: indexU547GCrkq0Meta?.path ?? "/jobs/:type()/:model()",
    meta: indexU547GCrkq0Meta || {},
    alias: indexU547GCrkq0Meta?.alias || [],
    redirect: indexU547GCrkq0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexU547GCrkq0Meta?.name ?? "jobs-type-model___en",
    path: indexU547GCrkq0Meta?.path ?? "/en/jobs/:type()/:model()",
    meta: indexU547GCrkq0Meta || {},
    alias: indexU547GCrkq0Meta?.alias || [],
    redirect: indexU547GCrkq0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexU547GCrkq0Meta?.name ?? "jobs-type-model___nl",
    path: indexU547GCrkq0Meta?.path ?? "/nl/jobs/:type()/:model()",
    meta: indexU547GCrkq0Meta || {},
    alias: indexU547GCrkq0Meta?.alias || [],
    redirect: indexU547GCrkq0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexU547GCrkq0Meta?.name ?? "jobs-type-model___cs",
    path: indexU547GCrkq0Meta?.path ?? "/cs/jobs/:type()/:model()",
    meta: indexU547GCrkq0Meta || {},
    alias: indexU547GCrkq0Meta?.alias || [],
    redirect: indexU547GCrkq0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexhapMc1mLy4Meta?.name ?? "jobs-type___de",
    path: indexhapMc1mLy4Meta?.path ?? "/jobs/:type()",
    meta: indexhapMc1mLy4Meta || {},
    alias: indexhapMc1mLy4Meta?.alias || [],
    redirect: indexhapMc1mLy4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexhapMc1mLy4Meta?.name ?? "jobs-type___en",
    path: indexhapMc1mLy4Meta?.path ?? "/en/jobs/:type()",
    meta: indexhapMc1mLy4Meta || {},
    alias: indexhapMc1mLy4Meta?.alias || [],
    redirect: indexhapMc1mLy4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexhapMc1mLy4Meta?.name ?? "jobs-type___nl",
    path: indexhapMc1mLy4Meta?.path ?? "/nl/jobs/:type()",
    meta: indexhapMc1mLy4Meta || {},
    alias: indexhapMc1mLy4Meta?.alias || [],
    redirect: indexhapMc1mLy4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexhapMc1mLy4Meta?.name ?? "jobs-type___cs",
    path: indexhapMc1mLy4Meta?.path ?? "/cs/jobs/:type()",
    meta: indexhapMc1mLy4Meta || {},
    alias: indexhapMc1mLy4Meta?.alias || [],
    redirect: indexhapMc1mLy4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/jobs/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: loginD4ncAobNn4Meta?.name ?? "login___de",
    path: loginD4ncAobNn4Meta?.path ?? "/login",
    meta: loginD4ncAobNn4Meta || {},
    alias: loginD4ncAobNn4Meta?.alias || [],
    redirect: loginD4ncAobNn4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginD4ncAobNn4Meta?.name ?? "login___en",
    path: loginD4ncAobNn4Meta?.path ?? "/en/login",
    meta: loginD4ncAobNn4Meta || {},
    alias: loginD4ncAobNn4Meta?.alias || [],
    redirect: loginD4ncAobNn4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginD4ncAobNn4Meta?.name ?? "login___nl",
    path: loginD4ncAobNn4Meta?.path ?? "/nl/login",
    meta: loginD4ncAobNn4Meta || {},
    alias: loginD4ncAobNn4Meta?.alias || [],
    redirect: loginD4ncAobNn4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginD4ncAobNn4Meta?.name ?? "login___cs",
    path: loginD4ncAobNn4Meta?.path ?? "/cs/login",
    meta: loginD4ncAobNn4Meta || {},
    alias: loginD4ncAobNn4Meta?.alias || [],
    redirect: loginD4ncAobNn4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91event_93eWC1NMesZjMeta?.name ?? "my-events-event___de",
    path: _91event_93eWC1NMesZjMeta?.path ?? "/my/events/:event()",
    meta: _91event_93eWC1NMesZjMeta || {},
    alias: _91event_93eWC1NMesZjMeta?.alias || [],
    redirect: _91event_93eWC1NMesZjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/events/[event].vue").then(m => m.default || m)
  },
  {
    name: _91event_93eWC1NMesZjMeta?.name ?? "my-events-event___en",
    path: _91event_93eWC1NMesZjMeta?.path ?? "/en/my/events/:event()",
    meta: _91event_93eWC1NMesZjMeta || {},
    alias: _91event_93eWC1NMesZjMeta?.alias || [],
    redirect: _91event_93eWC1NMesZjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/events/[event].vue").then(m => m.default || m)
  },
  {
    name: _91event_93eWC1NMesZjMeta?.name ?? "my-events-event___nl",
    path: _91event_93eWC1NMesZjMeta?.path ?? "/nl/my/events/:event()",
    meta: _91event_93eWC1NMesZjMeta || {},
    alias: _91event_93eWC1NMesZjMeta?.alias || [],
    redirect: _91event_93eWC1NMesZjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/events/[event].vue").then(m => m.default || m)
  },
  {
    name: _91event_93eWC1NMesZjMeta?.name ?? "my-events-event___cs",
    path: _91event_93eWC1NMesZjMeta?.path ?? "/cs/my/events/:event()",
    meta: _91event_93eWC1NMesZjMeta || {},
    alias: _91event_93eWC1NMesZjMeta?.alias || [],
    redirect: _91event_93eWC1NMesZjMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/events/[event].vue").then(m => m.default || m)
  },
  {
    name: indexntIWJMv9p2Meta?.name ?? "my-events___de",
    path: indexntIWJMv9p2Meta?.path ?? "/my/events",
    meta: indexntIWJMv9p2Meta || {},
    alias: indexntIWJMv9p2Meta?.alias || [],
    redirect: indexntIWJMv9p2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexntIWJMv9p2Meta?.name ?? "my-events___en",
    path: indexntIWJMv9p2Meta?.path ?? "/en/my/events",
    meta: indexntIWJMv9p2Meta || {},
    alias: indexntIWJMv9p2Meta?.alias || [],
    redirect: indexntIWJMv9p2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexntIWJMv9p2Meta?.name ?? "my-events___nl",
    path: indexntIWJMv9p2Meta?.path ?? "/nl/my/events",
    meta: indexntIWJMv9p2Meta || {},
    alias: indexntIWJMv9p2Meta?.alias || [],
    redirect: indexntIWJMv9p2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexntIWJMv9p2Meta?.name ?? "my-events___cs",
    path: indexntIWJMv9p2Meta?.path ?? "/cs/my/events",
    meta: indexntIWJMv9p2Meta || {},
    alias: indexntIWJMv9p2Meta?.alias || [],
    redirect: indexntIWJMv9p2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/events/index.vue").then(m => m.default || m)
  },
  {
    name: finisherpointsSVs53aqLY2Meta?.name ?? undefined,
    path: finisherpointsSVs53aqLY2Meta?.path ?? "/my/finisherpoints",
    meta: finisherpointsSVs53aqLY2Meta || {},
    alias: finisherpointsSVs53aqLY2Meta?.alias || [],
    redirect: finisherpointsSVs53aqLY2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints.vue").then(m => m.default || m),
    children: [
  {
    name: achievementskPnnUnnULVMeta?.name ?? "my-finisherpoints-achievements___de",
    path: achievementskPnnUnnULVMeta?.path ?? "achievements",
    meta: achievementskPnnUnnULVMeta || {},
    alias: achievementskPnnUnnULVMeta?.alias || [],
    redirect: achievementskPnnUnnULVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/achievements.vue").then(m => m.default || m)
  },
  {
    name: indexmwBCFcBHVmMeta?.name ?? "my-finisherpoints___de",
    path: indexmwBCFcBHVmMeta?.path ?? "",
    meta: indexmwBCFcBHVmMeta || {},
    alias: indexmwBCFcBHVmMeta?.alias || [],
    redirect: indexmwBCFcBHVmMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/index.vue").then(m => m.default || m)
  },
  {
    name: info7ao38qBaPoMeta?.name ?? "my-finisherpoints-info___de",
    path: info7ao38qBaPoMeta?.path ?? "info",
    meta: info7ao38qBaPoMeta || {},
    alias: info7ao38qBaPoMeta?.alias || [],
    redirect: info7ao38qBaPoMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/info.vue").then(m => m.default || m)
  },
  {
    name: ordersz0eckaRw3VMeta?.name ?? "my-finisherpoints-orders___de",
    path: ordersz0eckaRw3VMeta?.path ?? "orders",
    meta: ordersz0eckaRw3VMeta || {},
    alias: ordersz0eckaRw3VMeta?.alias || [],
    redirect: ordersz0eckaRw3VMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/orders.vue").then(m => m.default || m)
  },
  {
    name: transactionsGJqfSG8AyeMeta?.name ?? "my-finisherpoints-transactions___de",
    path: transactionsGJqfSG8AyeMeta?.path ?? "transactions",
    meta: transactionsGJqfSG8AyeMeta || {},
    alias: transactionsGJqfSG8AyeMeta?.alias || [],
    redirect: transactionsGJqfSG8AyeMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: finisherpointsSVs53aqLY2Meta?.name ?? undefined,
    path: finisherpointsSVs53aqLY2Meta?.path ?? "/en/my/finisherpoints",
    meta: finisherpointsSVs53aqLY2Meta || {},
    alias: finisherpointsSVs53aqLY2Meta?.alias || [],
    redirect: finisherpointsSVs53aqLY2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints.vue").then(m => m.default || m),
    children: [
  {
    name: achievementskPnnUnnULVMeta?.name ?? "my-finisherpoints-achievements___en",
    path: achievementskPnnUnnULVMeta?.path ?? "achievements",
    meta: achievementskPnnUnnULVMeta || {},
    alias: achievementskPnnUnnULVMeta?.alias || [],
    redirect: achievementskPnnUnnULVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/achievements.vue").then(m => m.default || m)
  },
  {
    name: indexmwBCFcBHVmMeta?.name ?? "my-finisherpoints___en",
    path: indexmwBCFcBHVmMeta?.path ?? "",
    meta: indexmwBCFcBHVmMeta || {},
    alias: indexmwBCFcBHVmMeta?.alias || [],
    redirect: indexmwBCFcBHVmMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/index.vue").then(m => m.default || m)
  },
  {
    name: info7ao38qBaPoMeta?.name ?? "my-finisherpoints-info___en",
    path: info7ao38qBaPoMeta?.path ?? "info",
    meta: info7ao38qBaPoMeta || {},
    alias: info7ao38qBaPoMeta?.alias || [],
    redirect: info7ao38qBaPoMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/info.vue").then(m => m.default || m)
  },
  {
    name: ordersz0eckaRw3VMeta?.name ?? "my-finisherpoints-orders___en",
    path: ordersz0eckaRw3VMeta?.path ?? "orders",
    meta: ordersz0eckaRw3VMeta || {},
    alias: ordersz0eckaRw3VMeta?.alias || [],
    redirect: ordersz0eckaRw3VMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/orders.vue").then(m => m.default || m)
  },
  {
    name: transactionsGJqfSG8AyeMeta?.name ?? "my-finisherpoints-transactions___en",
    path: transactionsGJqfSG8AyeMeta?.path ?? "transactions",
    meta: transactionsGJqfSG8AyeMeta || {},
    alias: transactionsGJqfSG8AyeMeta?.alias || [],
    redirect: transactionsGJqfSG8AyeMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: finisherpointsSVs53aqLY2Meta?.name ?? undefined,
    path: finisherpointsSVs53aqLY2Meta?.path ?? "/nl/my/finisherpoints",
    meta: finisherpointsSVs53aqLY2Meta || {},
    alias: finisherpointsSVs53aqLY2Meta?.alias || [],
    redirect: finisherpointsSVs53aqLY2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints.vue").then(m => m.default || m),
    children: [
  {
    name: achievementskPnnUnnULVMeta?.name ?? "my-finisherpoints-achievements___nl",
    path: achievementskPnnUnnULVMeta?.path ?? "achievements",
    meta: achievementskPnnUnnULVMeta || {},
    alias: achievementskPnnUnnULVMeta?.alias || [],
    redirect: achievementskPnnUnnULVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/achievements.vue").then(m => m.default || m)
  },
  {
    name: indexmwBCFcBHVmMeta?.name ?? "my-finisherpoints___nl",
    path: indexmwBCFcBHVmMeta?.path ?? "",
    meta: indexmwBCFcBHVmMeta || {},
    alias: indexmwBCFcBHVmMeta?.alias || [],
    redirect: indexmwBCFcBHVmMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/index.vue").then(m => m.default || m)
  },
  {
    name: info7ao38qBaPoMeta?.name ?? "my-finisherpoints-info___nl",
    path: info7ao38qBaPoMeta?.path ?? "info",
    meta: info7ao38qBaPoMeta || {},
    alias: info7ao38qBaPoMeta?.alias || [],
    redirect: info7ao38qBaPoMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/info.vue").then(m => m.default || m)
  },
  {
    name: ordersz0eckaRw3VMeta?.name ?? "my-finisherpoints-orders___nl",
    path: ordersz0eckaRw3VMeta?.path ?? "orders",
    meta: ordersz0eckaRw3VMeta || {},
    alias: ordersz0eckaRw3VMeta?.alias || [],
    redirect: ordersz0eckaRw3VMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/orders.vue").then(m => m.default || m)
  },
  {
    name: transactionsGJqfSG8AyeMeta?.name ?? "my-finisherpoints-transactions___nl",
    path: transactionsGJqfSG8AyeMeta?.path ?? "transactions",
    meta: transactionsGJqfSG8AyeMeta || {},
    alias: transactionsGJqfSG8AyeMeta?.alias || [],
    redirect: transactionsGJqfSG8AyeMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: finisherpointsSVs53aqLY2Meta?.name ?? undefined,
    path: finisherpointsSVs53aqLY2Meta?.path ?? "/cs/my/finisherpoints",
    meta: finisherpointsSVs53aqLY2Meta || {},
    alias: finisherpointsSVs53aqLY2Meta?.alias || [],
    redirect: finisherpointsSVs53aqLY2Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints.vue").then(m => m.default || m),
    children: [
  {
    name: achievementskPnnUnnULVMeta?.name ?? "my-finisherpoints-achievements___cs",
    path: achievementskPnnUnnULVMeta?.path ?? "achievements",
    meta: achievementskPnnUnnULVMeta || {},
    alias: achievementskPnnUnnULVMeta?.alias || [],
    redirect: achievementskPnnUnnULVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/achievements.vue").then(m => m.default || m)
  },
  {
    name: indexmwBCFcBHVmMeta?.name ?? "my-finisherpoints___cs",
    path: indexmwBCFcBHVmMeta?.path ?? "",
    meta: indexmwBCFcBHVmMeta || {},
    alias: indexmwBCFcBHVmMeta?.alias || [],
    redirect: indexmwBCFcBHVmMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/index.vue").then(m => m.default || m)
  },
  {
    name: info7ao38qBaPoMeta?.name ?? "my-finisherpoints-info___cs",
    path: info7ao38qBaPoMeta?.path ?? "info",
    meta: info7ao38qBaPoMeta || {},
    alias: info7ao38qBaPoMeta?.alias || [],
    redirect: info7ao38qBaPoMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/info.vue").then(m => m.default || m)
  },
  {
    name: ordersz0eckaRw3VMeta?.name ?? "my-finisherpoints-orders___cs",
    path: ordersz0eckaRw3VMeta?.path ?? "orders",
    meta: ordersz0eckaRw3VMeta || {},
    alias: ordersz0eckaRw3VMeta?.alias || [],
    redirect: ordersz0eckaRw3VMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/orders.vue").then(m => m.default || m)
  },
  {
    name: transactionsGJqfSG8AyeMeta?.name ?? "my-finisherpoints-transactions___cs",
    path: transactionsGJqfSG8AyeMeta?.path ?? "transactions",
    meta: transactionsGJqfSG8AyeMeta || {},
    alias: transactionsGJqfSG8AyeMeta?.alias || [],
    redirect: transactionsGJqfSG8AyeMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/finisherpoints/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexvEOs46JYbAMeta?.name ?? "my___de",
    path: indexvEOs46JYbAMeta?.path ?? "/my",
    meta: indexvEOs46JYbAMeta || {},
    alias: indexvEOs46JYbAMeta?.alias || [],
    redirect: indexvEOs46JYbAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEOs46JYbAMeta?.name ?? "my___en",
    path: indexvEOs46JYbAMeta?.path ?? "/en/my",
    meta: indexvEOs46JYbAMeta || {},
    alias: indexvEOs46JYbAMeta?.alias || [],
    redirect: indexvEOs46JYbAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEOs46JYbAMeta?.name ?? "my___nl",
    path: indexvEOs46JYbAMeta?.path ?? "/nl/my",
    meta: indexvEOs46JYbAMeta || {},
    alias: indexvEOs46JYbAMeta?.alias || [],
    redirect: indexvEOs46JYbAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEOs46JYbAMeta?.name ?? "my___cs",
    path: indexvEOs46JYbAMeta?.path ?? "/cs/my",
    meta: indexvEOs46JYbAMeta || {},
    alias: indexvEOs46JYbAMeta?.alias || [],
    redirect: indexvEOs46JYbAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/index.vue").then(m => m.default || m)
  },
  {
    name: memberships1a2WTf1gPLMeta?.name ?? "my-memberships___de",
    path: memberships1a2WTf1gPLMeta?.path ?? "/my/memberships",
    meta: memberships1a2WTf1gPLMeta || {},
    alias: memberships1a2WTf1gPLMeta?.alias || [],
    redirect: memberships1a2WTf1gPLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/memberships.vue").then(m => m.default || m)
  },
  {
    name: memberships1a2WTf1gPLMeta?.name ?? "my-memberships___en",
    path: memberships1a2WTf1gPLMeta?.path ?? "/en/my/memberships",
    meta: memberships1a2WTf1gPLMeta || {},
    alias: memberships1a2WTf1gPLMeta?.alias || [],
    redirect: memberships1a2WTf1gPLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/memberships.vue").then(m => m.default || m)
  },
  {
    name: memberships1a2WTf1gPLMeta?.name ?? "my-memberships___nl",
    path: memberships1a2WTf1gPLMeta?.path ?? "/nl/my/memberships",
    meta: memberships1a2WTf1gPLMeta || {},
    alias: memberships1a2WTf1gPLMeta?.alias || [],
    redirect: memberships1a2WTf1gPLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/memberships.vue").then(m => m.default || m)
  },
  {
    name: memberships1a2WTf1gPLMeta?.name ?? "my-memberships___cs",
    path: memberships1a2WTf1gPLMeta?.path ?? "/cs/my/memberships",
    meta: memberships1a2WTf1gPLMeta || {},
    alias: memberships1a2WTf1gPLMeta?.alias || [],
    redirect: memberships1a2WTf1gPLMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/memberships.vue").then(m => m.default || m)
  },
  {
    name: indexPNHWmS8f5pMeta?.name ?? "my-profile___de",
    path: indexPNHWmS8f5pMeta?.path ?? "/my/profile",
    meta: indexPNHWmS8f5pMeta || {},
    alias: indexPNHWmS8f5pMeta?.alias || [],
    redirect: indexPNHWmS8f5pMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexPNHWmS8f5pMeta?.name ?? "my-profile___en",
    path: indexPNHWmS8f5pMeta?.path ?? "/en/my/profile",
    meta: indexPNHWmS8f5pMeta || {},
    alias: indexPNHWmS8f5pMeta?.alias || [],
    redirect: indexPNHWmS8f5pMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexPNHWmS8f5pMeta?.name ?? "my-profile___nl",
    path: indexPNHWmS8f5pMeta?.path ?? "/nl/my/profile",
    meta: indexPNHWmS8f5pMeta || {},
    alias: indexPNHWmS8f5pMeta?.alias || [],
    redirect: indexPNHWmS8f5pMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexPNHWmS8f5pMeta?.name ?? "my-profile___cs",
    path: indexPNHWmS8f5pMeta?.path ?? "/cs/my/profile",
    meta: indexPNHWmS8f5pMeta || {},
    alias: indexPNHWmS8f5pMeta?.alias || [],
    redirect: indexPNHWmS8f5pMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/index.vue").then(m => m.default || m)
  },
  {
    name: manageB5jVKDiwFFMeta?.name ?? undefined,
    path: manageB5jVKDiwFFMeta?.path ?? "/my/profile/manage",
    meta: manageB5jVKDiwFFMeta || {},
    alias: manageB5jVKDiwFFMeta?.alias || [],
    redirect: manageB5jVKDiwFFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage.vue").then(m => m.default || m),
    children: [
  {
    name: bank_45accountsukoA6HoHDZMeta?.name ?? "my-profile-manage-bank-accounts___de",
    path: bank_45accountsukoA6HoHDZMeta?.path ?? "bank-accounts",
    meta: bank_45accountsukoA6HoHDZMeta || {},
    alias: bank_45accountsukoA6HoHDZMeta?.alias || [],
    redirect: bank_45accountsukoA6HoHDZMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/bank-accounts.vue").then(m => m.default || m)
  },
  {
    name: editOccRVJSI4AMeta?.name ?? "my-profile-manage-billing-requests-request-edit___de",
    path: editOccRVJSI4AMeta?.path ?? "billing/requests/:request()/edit",
    meta: editOccRVJSI4AMeta || {},
    alias: editOccRVJSI4AMeta?.alias || [],
    redirect: editOccRVJSI4AMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/[request]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexJ5NSy5Ka16Meta?.name ?? "my-profile-manage-billing-requests-request___de",
    path: indexJ5NSy5Ka16Meta?.path ?? "billing/requests/:request()",
    meta: indexJ5NSy5Ka16Meta || {},
    alias: indexJ5NSy5Ka16Meta?.alias || [],
    redirect: indexJ5NSy5Ka16Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/[request]/index.vue").then(m => m.default || m)
  },
  {
    name: createUsBYTUTaUBMeta?.name ?? "my-profile-manage-billing-requests-create___de",
    path: createUsBYTUTaUBMeta?.path ?? "billing/requests/create",
    meta: createUsBYTUTaUBMeta || {},
    alias: createUsBYTUTaUBMeta?.alias || [],
    redirect: createUsBYTUTaUBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/create.vue").then(m => m.default || m)
  },
  {
    name: index70ukLfzd04Meta?.name ?? "my-profile-manage-billing-requests___de",
    path: index70ukLfzd04Meta?.path ?? "billing/requests",
    meta: index70ukLfzd04Meta || {},
    alias: index70ukLfzd04Meta?.alias || [],
    redirect: index70ukLfzd04Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/index.vue").then(m => m.default || m)
  },
  {
    name: edit1u7Zd3VKFiMeta?.name ?? "my-profile-manage-blog-article-edit___de",
    path: edit1u7Zd3VKFiMeta?.path ?? "blog/:article()/edit",
    meta: edit1u7Zd3VKFiMeta || {},
    alias: edit1u7Zd3VKFiMeta?.alias || [],
    redirect: edit1u7Zd3VKFiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/[article]/edit.vue").then(m => m.default || m)
  },
  {
    name: create7mY2dcGEHrMeta?.name ?? "my-profile-manage-blog-create___de",
    path: create7mY2dcGEHrMeta?.path ?? "blog/create",
    meta: create7mY2dcGEHrMeta || {},
    alias: create7mY2dcGEHrMeta?.alias || [],
    redirect: create7mY2dcGEHrMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/create.vue").then(m => m.default || m)
  },
  {
    name: indexkH4tjnPAsoMeta?.name ?? "my-profile-manage-blog___de",
    path: indexkH4tjnPAsoMeta?.path ?? "blog",
    meta: indexkH4tjnPAsoMeta || {},
    alias: indexkH4tjnPAsoMeta?.alias || [],
    redirect: indexkH4tjnPAsoMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/index.vue").then(m => m.default || m)
  },
  {
    name: childrenGVy7XcCn6BMeta?.name ?? "my-profile-manage-children___de",
    path: childrenGVy7XcCn6BMeta?.path ?? "children",
    meta: childrenGVy7XcCn6BMeta || {},
    alias: childrenGVy7XcCn6BMeta?.alias || [],
    redirect: childrenGVy7XcCn6BMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/children.vue").then(m => m.default || m)
  },
  {
    name: connectionsaNWuDaLyD5Meta?.name ?? "my-profile-manage-connections___de",
    path: connectionsaNWuDaLyD5Meta?.path ?? "connections",
    meta: connectionsaNWuDaLyD5Meta || {},
    alias: connectionsaNWuDaLyD5Meta?.alias || [],
    redirect: connectionsaNWuDaLyD5Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/connections.vue").then(m => m.default || m)
  },
  {
    name: contact14eUYzBtfUMeta?.name ?? "my-profile-manage-contact___de",
    path: contact14eUYzBtfUMeta?.path ?? "contact",
    meta: contact14eUYzBtfUMeta || {},
    alias: contact14eUYzBtfUMeta?.alias || [],
    redirect: contact14eUYzBtfUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/contact.vue").then(m => m.default || m)
  },
  {
    name: dataCE1LcYOZjzMeta?.name ?? "my-profile-manage-data___de",
    path: dataCE1LcYOZjzMeta?.path ?? "data",
    meta: dataCE1LcYOZjzMeta || {},
    alias: dataCE1LcYOZjzMeta?.alias || [],
    redirect: dataCE1LcYOZjzMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/data.vue").then(m => m.default || m)
  },
  {
    name: description5kWFNwoqe4Meta?.name ?? "my-profile-manage-description___de",
    path: description5kWFNwoqe4Meta?.path ?? "description",
    meta: description5kWFNwoqe4Meta || {},
    alias: description5kWFNwoqe4Meta?.alias || [],
    redirect: description5kWFNwoqe4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/description.vue").then(m => m.default || m)
  },
  {
    name: indexrPh6IUOO6CMeta?.name ?? "my-profile-manage___de",
    path: indexrPh6IUOO6CMeta?.path ?? "",
    meta: indexrPh6IUOO6CMeta || {},
    alias: indexrPh6IUOO6CMeta?.alias || [],
    redirect: indexrPh6IUOO6CMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/index.vue").then(m => m.default || m)
  },
  {
    name: linksGdpEON6MSdMeta?.name ?? "my-profile-manage-links___de",
    path: linksGdpEON6MSdMeta?.path ?? "links",
    meta: linksGdpEON6MSdMeta || {},
    alias: linksGdpEON6MSdMeta?.alias || [],
    redirect: linksGdpEON6MSdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/links.vue").then(m => m.default || m)
  },
  {
    name: parents8KXqhn38Y6Meta?.name ?? "my-profile-manage-parents___de",
    path: parents8KXqhn38Y6Meta?.path ?? "parents",
    meta: parents8KXqhn38Y6Meta || {},
    alias: parents8KXqhn38Y6Meta?.alias || [],
    redirect: parents8KXqhn38Y6Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/parents.vue").then(m => m.default || m)
  },
  {
    name: passwordzkvBKlty1KMeta?.name ?? "my-profile-manage-password___de",
    path: passwordzkvBKlty1KMeta?.path ?? "password",
    meta: passwordzkvBKlty1KMeta || {},
    alias: passwordzkvBKlty1KMeta?.alias || [],
    redirect: passwordzkvBKlty1KMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/password.vue").then(m => m.default || m)
  },
  {
    name: payment_45informationvmQMYwyV9UMeta?.name ?? "my-profile-manage-payment-information___de",
    path: payment_45informationvmQMYwyV9UMeta?.path ?? "payment-information",
    meta: payment_45informationvmQMYwyV9UMeta || {},
    alias: payment_45informationvmQMYwyV9UMeta?.alias || [],
    redirect: payment_45informationvmQMYwyV9UMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/payment-information.vue").then(m => m.default || m)
  },
  {
    name: index7Q6h1QovTgMeta?.name ?? "my-profile-manage-settings___de",
    path: index7Q6h1QovTgMeta?.path ?? "settings",
    meta: index7Q6h1QovTgMeta || {},
    alias: index7Q6h1QovTgMeta?.alias || [],
    redirect: index7Q6h1QovTgMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93WK0c3N7SJRMeta?.name ?? "my-profile-manage-settings-notifications-type___de",
    path: _91type_93WK0c3N7SJRMeta?.path ?? "settings/notifications/:type()",
    meta: _91type_93WK0c3N7SJRMeta || {},
    alias: _91type_93WK0c3N7SJRMeta?.alias || [],
    redirect: _91type_93WK0c3N7SJRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/[type].vue").then(m => m.default || m)
  },
  {
    name: devicesgELcUXxOPWMeta?.name ?? "my-profile-manage-settings-notifications-devices___de",
    path: devicesgELcUXxOPWMeta?.path ?? "settings/notifications/devices",
    meta: devicesgELcUXxOPWMeta || {},
    alias: devicesgELcUXxOPWMeta?.alias || [],
    redirect: devicesgELcUXxOPWMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/devices.vue").then(m => m.default || m)
  },
  {
    name: index8tE0SsCx7YMeta?.name ?? "my-profile-manage-settings-notifications___de",
    path: index8tE0SsCx7YMeta?.path ?? "settings/notifications",
    meta: index8tE0SsCx7YMeta || {},
    alias: index8tE0SsCx7YMeta?.alias || [],
    redirect: index8tE0SsCx7YMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: privacyIX0bRvU07HMeta?.name ?? "my-profile-manage-settings-privacy___de",
    path: privacyIX0bRvU07HMeta?.path ?? "settings/privacy",
    meta: privacyIX0bRvU07HMeta || {},
    alias: privacyIX0bRvU07HMeta?.alias || [],
    redirect: privacyIX0bRvU07HMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/privacy.vue").then(m => m.default || m)
  },
  {
    name: sport_45types8HUd3TfGzrMeta?.name ?? "my-profile-manage-sport-types___de",
    path: sport_45types8HUd3TfGzrMeta?.path ?? "sport-types",
    meta: sport_45types8HUd3TfGzrMeta || {},
    alias: sport_45types8HUd3TfGzrMeta?.alias || [],
    redirect: sport_45types8HUd3TfGzrMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/sport-types.vue").then(m => m.default || m)
  }
]
  },
  {
    name: manageB5jVKDiwFFMeta?.name ?? undefined,
    path: manageB5jVKDiwFFMeta?.path ?? "/en/my/profile/manage",
    meta: manageB5jVKDiwFFMeta || {},
    alias: manageB5jVKDiwFFMeta?.alias || [],
    redirect: manageB5jVKDiwFFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage.vue").then(m => m.default || m),
    children: [
  {
    name: bank_45accountsukoA6HoHDZMeta?.name ?? "my-profile-manage-bank-accounts___en",
    path: bank_45accountsukoA6HoHDZMeta?.path ?? "bank-accounts",
    meta: bank_45accountsukoA6HoHDZMeta || {},
    alias: bank_45accountsukoA6HoHDZMeta?.alias || [],
    redirect: bank_45accountsukoA6HoHDZMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/bank-accounts.vue").then(m => m.default || m)
  },
  {
    name: editOccRVJSI4AMeta?.name ?? "my-profile-manage-billing-requests-request-edit___en",
    path: editOccRVJSI4AMeta?.path ?? "billing/requests/:request()/edit",
    meta: editOccRVJSI4AMeta || {},
    alias: editOccRVJSI4AMeta?.alias || [],
    redirect: editOccRVJSI4AMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/[request]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexJ5NSy5Ka16Meta?.name ?? "my-profile-manage-billing-requests-request___en",
    path: indexJ5NSy5Ka16Meta?.path ?? "billing/requests/:request()",
    meta: indexJ5NSy5Ka16Meta || {},
    alias: indexJ5NSy5Ka16Meta?.alias || [],
    redirect: indexJ5NSy5Ka16Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/[request]/index.vue").then(m => m.default || m)
  },
  {
    name: createUsBYTUTaUBMeta?.name ?? "my-profile-manage-billing-requests-create___en",
    path: createUsBYTUTaUBMeta?.path ?? "billing/requests/create",
    meta: createUsBYTUTaUBMeta || {},
    alias: createUsBYTUTaUBMeta?.alias || [],
    redirect: createUsBYTUTaUBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/create.vue").then(m => m.default || m)
  },
  {
    name: index70ukLfzd04Meta?.name ?? "my-profile-manage-billing-requests___en",
    path: index70ukLfzd04Meta?.path ?? "billing/requests",
    meta: index70ukLfzd04Meta || {},
    alias: index70ukLfzd04Meta?.alias || [],
    redirect: index70ukLfzd04Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/index.vue").then(m => m.default || m)
  },
  {
    name: edit1u7Zd3VKFiMeta?.name ?? "my-profile-manage-blog-article-edit___en",
    path: edit1u7Zd3VKFiMeta?.path ?? "blog/:article()/edit",
    meta: edit1u7Zd3VKFiMeta || {},
    alias: edit1u7Zd3VKFiMeta?.alias || [],
    redirect: edit1u7Zd3VKFiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/[article]/edit.vue").then(m => m.default || m)
  },
  {
    name: create7mY2dcGEHrMeta?.name ?? "my-profile-manage-blog-create___en",
    path: create7mY2dcGEHrMeta?.path ?? "blog/create",
    meta: create7mY2dcGEHrMeta || {},
    alias: create7mY2dcGEHrMeta?.alias || [],
    redirect: create7mY2dcGEHrMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/create.vue").then(m => m.default || m)
  },
  {
    name: indexkH4tjnPAsoMeta?.name ?? "my-profile-manage-blog___en",
    path: indexkH4tjnPAsoMeta?.path ?? "blog",
    meta: indexkH4tjnPAsoMeta || {},
    alias: indexkH4tjnPAsoMeta?.alias || [],
    redirect: indexkH4tjnPAsoMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/index.vue").then(m => m.default || m)
  },
  {
    name: childrenGVy7XcCn6BMeta?.name ?? "my-profile-manage-children___en",
    path: childrenGVy7XcCn6BMeta?.path ?? "children",
    meta: childrenGVy7XcCn6BMeta || {},
    alias: childrenGVy7XcCn6BMeta?.alias || [],
    redirect: childrenGVy7XcCn6BMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/children.vue").then(m => m.default || m)
  },
  {
    name: connectionsaNWuDaLyD5Meta?.name ?? "my-profile-manage-connections___en",
    path: connectionsaNWuDaLyD5Meta?.path ?? "connections",
    meta: connectionsaNWuDaLyD5Meta || {},
    alias: connectionsaNWuDaLyD5Meta?.alias || [],
    redirect: connectionsaNWuDaLyD5Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/connections.vue").then(m => m.default || m)
  },
  {
    name: contact14eUYzBtfUMeta?.name ?? "my-profile-manage-contact___en",
    path: contact14eUYzBtfUMeta?.path ?? "contact",
    meta: contact14eUYzBtfUMeta || {},
    alias: contact14eUYzBtfUMeta?.alias || [],
    redirect: contact14eUYzBtfUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/contact.vue").then(m => m.default || m)
  },
  {
    name: dataCE1LcYOZjzMeta?.name ?? "my-profile-manage-data___en",
    path: dataCE1LcYOZjzMeta?.path ?? "data",
    meta: dataCE1LcYOZjzMeta || {},
    alias: dataCE1LcYOZjzMeta?.alias || [],
    redirect: dataCE1LcYOZjzMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/data.vue").then(m => m.default || m)
  },
  {
    name: description5kWFNwoqe4Meta?.name ?? "my-profile-manage-description___en",
    path: description5kWFNwoqe4Meta?.path ?? "description",
    meta: description5kWFNwoqe4Meta || {},
    alias: description5kWFNwoqe4Meta?.alias || [],
    redirect: description5kWFNwoqe4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/description.vue").then(m => m.default || m)
  },
  {
    name: indexrPh6IUOO6CMeta?.name ?? "my-profile-manage___en",
    path: indexrPh6IUOO6CMeta?.path ?? "",
    meta: indexrPh6IUOO6CMeta || {},
    alias: indexrPh6IUOO6CMeta?.alias || [],
    redirect: indexrPh6IUOO6CMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/index.vue").then(m => m.default || m)
  },
  {
    name: linksGdpEON6MSdMeta?.name ?? "my-profile-manage-links___en",
    path: linksGdpEON6MSdMeta?.path ?? "links",
    meta: linksGdpEON6MSdMeta || {},
    alias: linksGdpEON6MSdMeta?.alias || [],
    redirect: linksGdpEON6MSdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/links.vue").then(m => m.default || m)
  },
  {
    name: parents8KXqhn38Y6Meta?.name ?? "my-profile-manage-parents___en",
    path: parents8KXqhn38Y6Meta?.path ?? "parents",
    meta: parents8KXqhn38Y6Meta || {},
    alias: parents8KXqhn38Y6Meta?.alias || [],
    redirect: parents8KXqhn38Y6Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/parents.vue").then(m => m.default || m)
  },
  {
    name: passwordzkvBKlty1KMeta?.name ?? "my-profile-manage-password___en",
    path: passwordzkvBKlty1KMeta?.path ?? "password",
    meta: passwordzkvBKlty1KMeta || {},
    alias: passwordzkvBKlty1KMeta?.alias || [],
    redirect: passwordzkvBKlty1KMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/password.vue").then(m => m.default || m)
  },
  {
    name: payment_45informationvmQMYwyV9UMeta?.name ?? "my-profile-manage-payment-information___en",
    path: payment_45informationvmQMYwyV9UMeta?.path ?? "payment-information",
    meta: payment_45informationvmQMYwyV9UMeta || {},
    alias: payment_45informationvmQMYwyV9UMeta?.alias || [],
    redirect: payment_45informationvmQMYwyV9UMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/payment-information.vue").then(m => m.default || m)
  },
  {
    name: index7Q6h1QovTgMeta?.name ?? "my-profile-manage-settings___en",
    path: index7Q6h1QovTgMeta?.path ?? "settings",
    meta: index7Q6h1QovTgMeta || {},
    alias: index7Q6h1QovTgMeta?.alias || [],
    redirect: index7Q6h1QovTgMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93WK0c3N7SJRMeta?.name ?? "my-profile-manage-settings-notifications-type___en",
    path: _91type_93WK0c3N7SJRMeta?.path ?? "settings/notifications/:type()",
    meta: _91type_93WK0c3N7SJRMeta || {},
    alias: _91type_93WK0c3N7SJRMeta?.alias || [],
    redirect: _91type_93WK0c3N7SJRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/[type].vue").then(m => m.default || m)
  },
  {
    name: devicesgELcUXxOPWMeta?.name ?? "my-profile-manage-settings-notifications-devices___en",
    path: devicesgELcUXxOPWMeta?.path ?? "settings/notifications/devices",
    meta: devicesgELcUXxOPWMeta || {},
    alias: devicesgELcUXxOPWMeta?.alias || [],
    redirect: devicesgELcUXxOPWMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/devices.vue").then(m => m.default || m)
  },
  {
    name: index8tE0SsCx7YMeta?.name ?? "my-profile-manage-settings-notifications___en",
    path: index8tE0SsCx7YMeta?.path ?? "settings/notifications",
    meta: index8tE0SsCx7YMeta || {},
    alias: index8tE0SsCx7YMeta?.alias || [],
    redirect: index8tE0SsCx7YMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: privacyIX0bRvU07HMeta?.name ?? "my-profile-manage-settings-privacy___en",
    path: privacyIX0bRvU07HMeta?.path ?? "settings/privacy",
    meta: privacyIX0bRvU07HMeta || {},
    alias: privacyIX0bRvU07HMeta?.alias || [],
    redirect: privacyIX0bRvU07HMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/privacy.vue").then(m => m.default || m)
  },
  {
    name: sport_45types8HUd3TfGzrMeta?.name ?? "my-profile-manage-sport-types___en",
    path: sport_45types8HUd3TfGzrMeta?.path ?? "sport-types",
    meta: sport_45types8HUd3TfGzrMeta || {},
    alias: sport_45types8HUd3TfGzrMeta?.alias || [],
    redirect: sport_45types8HUd3TfGzrMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/sport-types.vue").then(m => m.default || m)
  }
]
  },
  {
    name: manageB5jVKDiwFFMeta?.name ?? undefined,
    path: manageB5jVKDiwFFMeta?.path ?? "/nl/my/profile/manage",
    meta: manageB5jVKDiwFFMeta || {},
    alias: manageB5jVKDiwFFMeta?.alias || [],
    redirect: manageB5jVKDiwFFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage.vue").then(m => m.default || m),
    children: [
  {
    name: bank_45accountsukoA6HoHDZMeta?.name ?? "my-profile-manage-bank-accounts___nl",
    path: bank_45accountsukoA6HoHDZMeta?.path ?? "bank-accounts",
    meta: bank_45accountsukoA6HoHDZMeta || {},
    alias: bank_45accountsukoA6HoHDZMeta?.alias || [],
    redirect: bank_45accountsukoA6HoHDZMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/bank-accounts.vue").then(m => m.default || m)
  },
  {
    name: editOccRVJSI4AMeta?.name ?? "my-profile-manage-billing-requests-request-edit___nl",
    path: editOccRVJSI4AMeta?.path ?? "billing/requests/:request()/edit",
    meta: editOccRVJSI4AMeta || {},
    alias: editOccRVJSI4AMeta?.alias || [],
    redirect: editOccRVJSI4AMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/[request]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexJ5NSy5Ka16Meta?.name ?? "my-profile-manage-billing-requests-request___nl",
    path: indexJ5NSy5Ka16Meta?.path ?? "billing/requests/:request()",
    meta: indexJ5NSy5Ka16Meta || {},
    alias: indexJ5NSy5Ka16Meta?.alias || [],
    redirect: indexJ5NSy5Ka16Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/[request]/index.vue").then(m => m.default || m)
  },
  {
    name: createUsBYTUTaUBMeta?.name ?? "my-profile-manage-billing-requests-create___nl",
    path: createUsBYTUTaUBMeta?.path ?? "billing/requests/create",
    meta: createUsBYTUTaUBMeta || {},
    alias: createUsBYTUTaUBMeta?.alias || [],
    redirect: createUsBYTUTaUBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/create.vue").then(m => m.default || m)
  },
  {
    name: index70ukLfzd04Meta?.name ?? "my-profile-manage-billing-requests___nl",
    path: index70ukLfzd04Meta?.path ?? "billing/requests",
    meta: index70ukLfzd04Meta || {},
    alias: index70ukLfzd04Meta?.alias || [],
    redirect: index70ukLfzd04Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/index.vue").then(m => m.default || m)
  },
  {
    name: edit1u7Zd3VKFiMeta?.name ?? "my-profile-manage-blog-article-edit___nl",
    path: edit1u7Zd3VKFiMeta?.path ?? "blog/:article()/edit",
    meta: edit1u7Zd3VKFiMeta || {},
    alias: edit1u7Zd3VKFiMeta?.alias || [],
    redirect: edit1u7Zd3VKFiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/[article]/edit.vue").then(m => m.default || m)
  },
  {
    name: create7mY2dcGEHrMeta?.name ?? "my-profile-manage-blog-create___nl",
    path: create7mY2dcGEHrMeta?.path ?? "blog/create",
    meta: create7mY2dcGEHrMeta || {},
    alias: create7mY2dcGEHrMeta?.alias || [],
    redirect: create7mY2dcGEHrMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/create.vue").then(m => m.default || m)
  },
  {
    name: indexkH4tjnPAsoMeta?.name ?? "my-profile-manage-blog___nl",
    path: indexkH4tjnPAsoMeta?.path ?? "blog",
    meta: indexkH4tjnPAsoMeta || {},
    alias: indexkH4tjnPAsoMeta?.alias || [],
    redirect: indexkH4tjnPAsoMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/index.vue").then(m => m.default || m)
  },
  {
    name: childrenGVy7XcCn6BMeta?.name ?? "my-profile-manage-children___nl",
    path: childrenGVy7XcCn6BMeta?.path ?? "children",
    meta: childrenGVy7XcCn6BMeta || {},
    alias: childrenGVy7XcCn6BMeta?.alias || [],
    redirect: childrenGVy7XcCn6BMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/children.vue").then(m => m.default || m)
  },
  {
    name: connectionsaNWuDaLyD5Meta?.name ?? "my-profile-manage-connections___nl",
    path: connectionsaNWuDaLyD5Meta?.path ?? "connections",
    meta: connectionsaNWuDaLyD5Meta || {},
    alias: connectionsaNWuDaLyD5Meta?.alias || [],
    redirect: connectionsaNWuDaLyD5Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/connections.vue").then(m => m.default || m)
  },
  {
    name: contact14eUYzBtfUMeta?.name ?? "my-profile-manage-contact___nl",
    path: contact14eUYzBtfUMeta?.path ?? "contact",
    meta: contact14eUYzBtfUMeta || {},
    alias: contact14eUYzBtfUMeta?.alias || [],
    redirect: contact14eUYzBtfUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/contact.vue").then(m => m.default || m)
  },
  {
    name: dataCE1LcYOZjzMeta?.name ?? "my-profile-manage-data___nl",
    path: dataCE1LcYOZjzMeta?.path ?? "data",
    meta: dataCE1LcYOZjzMeta || {},
    alias: dataCE1LcYOZjzMeta?.alias || [],
    redirect: dataCE1LcYOZjzMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/data.vue").then(m => m.default || m)
  },
  {
    name: description5kWFNwoqe4Meta?.name ?? "my-profile-manage-description___nl",
    path: description5kWFNwoqe4Meta?.path ?? "description",
    meta: description5kWFNwoqe4Meta || {},
    alias: description5kWFNwoqe4Meta?.alias || [],
    redirect: description5kWFNwoqe4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/description.vue").then(m => m.default || m)
  },
  {
    name: indexrPh6IUOO6CMeta?.name ?? "my-profile-manage___nl",
    path: indexrPh6IUOO6CMeta?.path ?? "",
    meta: indexrPh6IUOO6CMeta || {},
    alias: indexrPh6IUOO6CMeta?.alias || [],
    redirect: indexrPh6IUOO6CMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/index.vue").then(m => m.default || m)
  },
  {
    name: linksGdpEON6MSdMeta?.name ?? "my-profile-manage-links___nl",
    path: linksGdpEON6MSdMeta?.path ?? "links",
    meta: linksGdpEON6MSdMeta || {},
    alias: linksGdpEON6MSdMeta?.alias || [],
    redirect: linksGdpEON6MSdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/links.vue").then(m => m.default || m)
  },
  {
    name: parents8KXqhn38Y6Meta?.name ?? "my-profile-manage-parents___nl",
    path: parents8KXqhn38Y6Meta?.path ?? "parents",
    meta: parents8KXqhn38Y6Meta || {},
    alias: parents8KXqhn38Y6Meta?.alias || [],
    redirect: parents8KXqhn38Y6Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/parents.vue").then(m => m.default || m)
  },
  {
    name: passwordzkvBKlty1KMeta?.name ?? "my-profile-manage-password___nl",
    path: passwordzkvBKlty1KMeta?.path ?? "password",
    meta: passwordzkvBKlty1KMeta || {},
    alias: passwordzkvBKlty1KMeta?.alias || [],
    redirect: passwordzkvBKlty1KMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/password.vue").then(m => m.default || m)
  },
  {
    name: payment_45informationvmQMYwyV9UMeta?.name ?? "my-profile-manage-payment-information___nl",
    path: payment_45informationvmQMYwyV9UMeta?.path ?? "payment-information",
    meta: payment_45informationvmQMYwyV9UMeta || {},
    alias: payment_45informationvmQMYwyV9UMeta?.alias || [],
    redirect: payment_45informationvmQMYwyV9UMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/payment-information.vue").then(m => m.default || m)
  },
  {
    name: index7Q6h1QovTgMeta?.name ?? "my-profile-manage-settings___nl",
    path: index7Q6h1QovTgMeta?.path ?? "settings",
    meta: index7Q6h1QovTgMeta || {},
    alias: index7Q6h1QovTgMeta?.alias || [],
    redirect: index7Q6h1QovTgMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93WK0c3N7SJRMeta?.name ?? "my-profile-manage-settings-notifications-type___nl",
    path: _91type_93WK0c3N7SJRMeta?.path ?? "settings/notifications/:type()",
    meta: _91type_93WK0c3N7SJRMeta || {},
    alias: _91type_93WK0c3N7SJRMeta?.alias || [],
    redirect: _91type_93WK0c3N7SJRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/[type].vue").then(m => m.default || m)
  },
  {
    name: devicesgELcUXxOPWMeta?.name ?? "my-profile-manage-settings-notifications-devices___nl",
    path: devicesgELcUXxOPWMeta?.path ?? "settings/notifications/devices",
    meta: devicesgELcUXxOPWMeta || {},
    alias: devicesgELcUXxOPWMeta?.alias || [],
    redirect: devicesgELcUXxOPWMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/devices.vue").then(m => m.default || m)
  },
  {
    name: index8tE0SsCx7YMeta?.name ?? "my-profile-manage-settings-notifications___nl",
    path: index8tE0SsCx7YMeta?.path ?? "settings/notifications",
    meta: index8tE0SsCx7YMeta || {},
    alias: index8tE0SsCx7YMeta?.alias || [],
    redirect: index8tE0SsCx7YMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: privacyIX0bRvU07HMeta?.name ?? "my-profile-manage-settings-privacy___nl",
    path: privacyIX0bRvU07HMeta?.path ?? "settings/privacy",
    meta: privacyIX0bRvU07HMeta || {},
    alias: privacyIX0bRvU07HMeta?.alias || [],
    redirect: privacyIX0bRvU07HMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/privacy.vue").then(m => m.default || m)
  },
  {
    name: sport_45types8HUd3TfGzrMeta?.name ?? "my-profile-manage-sport-types___nl",
    path: sport_45types8HUd3TfGzrMeta?.path ?? "sport-types",
    meta: sport_45types8HUd3TfGzrMeta || {},
    alias: sport_45types8HUd3TfGzrMeta?.alias || [],
    redirect: sport_45types8HUd3TfGzrMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/sport-types.vue").then(m => m.default || m)
  }
]
  },
  {
    name: manageB5jVKDiwFFMeta?.name ?? undefined,
    path: manageB5jVKDiwFFMeta?.path ?? "/cs/my/profile/manage",
    meta: manageB5jVKDiwFFMeta || {},
    alias: manageB5jVKDiwFFMeta?.alias || [],
    redirect: manageB5jVKDiwFFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage.vue").then(m => m.default || m),
    children: [
  {
    name: bank_45accountsukoA6HoHDZMeta?.name ?? "my-profile-manage-bank-accounts___cs",
    path: bank_45accountsukoA6HoHDZMeta?.path ?? "bank-accounts",
    meta: bank_45accountsukoA6HoHDZMeta || {},
    alias: bank_45accountsukoA6HoHDZMeta?.alias || [],
    redirect: bank_45accountsukoA6HoHDZMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/bank-accounts.vue").then(m => m.default || m)
  },
  {
    name: editOccRVJSI4AMeta?.name ?? "my-profile-manage-billing-requests-request-edit___cs",
    path: editOccRVJSI4AMeta?.path ?? "billing/requests/:request()/edit",
    meta: editOccRVJSI4AMeta || {},
    alias: editOccRVJSI4AMeta?.alias || [],
    redirect: editOccRVJSI4AMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/[request]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexJ5NSy5Ka16Meta?.name ?? "my-profile-manage-billing-requests-request___cs",
    path: indexJ5NSy5Ka16Meta?.path ?? "billing/requests/:request()",
    meta: indexJ5NSy5Ka16Meta || {},
    alias: indexJ5NSy5Ka16Meta?.alias || [],
    redirect: indexJ5NSy5Ka16Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/[request]/index.vue").then(m => m.default || m)
  },
  {
    name: createUsBYTUTaUBMeta?.name ?? "my-profile-manage-billing-requests-create___cs",
    path: createUsBYTUTaUBMeta?.path ?? "billing/requests/create",
    meta: createUsBYTUTaUBMeta || {},
    alias: createUsBYTUTaUBMeta?.alias || [],
    redirect: createUsBYTUTaUBMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/create.vue").then(m => m.default || m)
  },
  {
    name: index70ukLfzd04Meta?.name ?? "my-profile-manage-billing-requests___cs",
    path: index70ukLfzd04Meta?.path ?? "billing/requests",
    meta: index70ukLfzd04Meta || {},
    alias: index70ukLfzd04Meta?.alias || [],
    redirect: index70ukLfzd04Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/billing/requests/index.vue").then(m => m.default || m)
  },
  {
    name: edit1u7Zd3VKFiMeta?.name ?? "my-profile-manage-blog-article-edit___cs",
    path: edit1u7Zd3VKFiMeta?.path ?? "blog/:article()/edit",
    meta: edit1u7Zd3VKFiMeta || {},
    alias: edit1u7Zd3VKFiMeta?.alias || [],
    redirect: edit1u7Zd3VKFiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/[article]/edit.vue").then(m => m.default || m)
  },
  {
    name: create7mY2dcGEHrMeta?.name ?? "my-profile-manage-blog-create___cs",
    path: create7mY2dcGEHrMeta?.path ?? "blog/create",
    meta: create7mY2dcGEHrMeta || {},
    alias: create7mY2dcGEHrMeta?.alias || [],
    redirect: create7mY2dcGEHrMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/create.vue").then(m => m.default || m)
  },
  {
    name: indexkH4tjnPAsoMeta?.name ?? "my-profile-manage-blog___cs",
    path: indexkH4tjnPAsoMeta?.path ?? "blog",
    meta: indexkH4tjnPAsoMeta || {},
    alias: indexkH4tjnPAsoMeta?.alias || [],
    redirect: indexkH4tjnPAsoMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/blog/index.vue").then(m => m.default || m)
  },
  {
    name: childrenGVy7XcCn6BMeta?.name ?? "my-profile-manage-children___cs",
    path: childrenGVy7XcCn6BMeta?.path ?? "children",
    meta: childrenGVy7XcCn6BMeta || {},
    alias: childrenGVy7XcCn6BMeta?.alias || [],
    redirect: childrenGVy7XcCn6BMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/children.vue").then(m => m.default || m)
  },
  {
    name: connectionsaNWuDaLyD5Meta?.name ?? "my-profile-manage-connections___cs",
    path: connectionsaNWuDaLyD5Meta?.path ?? "connections",
    meta: connectionsaNWuDaLyD5Meta || {},
    alias: connectionsaNWuDaLyD5Meta?.alias || [],
    redirect: connectionsaNWuDaLyD5Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/connections.vue").then(m => m.default || m)
  },
  {
    name: contact14eUYzBtfUMeta?.name ?? "my-profile-manage-contact___cs",
    path: contact14eUYzBtfUMeta?.path ?? "contact",
    meta: contact14eUYzBtfUMeta || {},
    alias: contact14eUYzBtfUMeta?.alias || [],
    redirect: contact14eUYzBtfUMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/contact.vue").then(m => m.default || m)
  },
  {
    name: dataCE1LcYOZjzMeta?.name ?? "my-profile-manage-data___cs",
    path: dataCE1LcYOZjzMeta?.path ?? "data",
    meta: dataCE1LcYOZjzMeta || {},
    alias: dataCE1LcYOZjzMeta?.alias || [],
    redirect: dataCE1LcYOZjzMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/data.vue").then(m => m.default || m)
  },
  {
    name: description5kWFNwoqe4Meta?.name ?? "my-profile-manage-description___cs",
    path: description5kWFNwoqe4Meta?.path ?? "description",
    meta: description5kWFNwoqe4Meta || {},
    alias: description5kWFNwoqe4Meta?.alias || [],
    redirect: description5kWFNwoqe4Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/description.vue").then(m => m.default || m)
  },
  {
    name: indexrPh6IUOO6CMeta?.name ?? "my-profile-manage___cs",
    path: indexrPh6IUOO6CMeta?.path ?? "",
    meta: indexrPh6IUOO6CMeta || {},
    alias: indexrPh6IUOO6CMeta?.alias || [],
    redirect: indexrPh6IUOO6CMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/index.vue").then(m => m.default || m)
  },
  {
    name: linksGdpEON6MSdMeta?.name ?? "my-profile-manage-links___cs",
    path: linksGdpEON6MSdMeta?.path ?? "links",
    meta: linksGdpEON6MSdMeta || {},
    alias: linksGdpEON6MSdMeta?.alias || [],
    redirect: linksGdpEON6MSdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/links.vue").then(m => m.default || m)
  },
  {
    name: parents8KXqhn38Y6Meta?.name ?? "my-profile-manage-parents___cs",
    path: parents8KXqhn38Y6Meta?.path ?? "parents",
    meta: parents8KXqhn38Y6Meta || {},
    alias: parents8KXqhn38Y6Meta?.alias || [],
    redirect: parents8KXqhn38Y6Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/parents.vue").then(m => m.default || m)
  },
  {
    name: passwordzkvBKlty1KMeta?.name ?? "my-profile-manage-password___cs",
    path: passwordzkvBKlty1KMeta?.path ?? "password",
    meta: passwordzkvBKlty1KMeta || {},
    alias: passwordzkvBKlty1KMeta?.alias || [],
    redirect: passwordzkvBKlty1KMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/password.vue").then(m => m.default || m)
  },
  {
    name: payment_45informationvmQMYwyV9UMeta?.name ?? "my-profile-manage-payment-information___cs",
    path: payment_45informationvmQMYwyV9UMeta?.path ?? "payment-information",
    meta: payment_45informationvmQMYwyV9UMeta || {},
    alias: payment_45informationvmQMYwyV9UMeta?.alias || [],
    redirect: payment_45informationvmQMYwyV9UMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/payment-information.vue").then(m => m.default || m)
  },
  {
    name: index7Q6h1QovTgMeta?.name ?? "my-profile-manage-settings___cs",
    path: index7Q6h1QovTgMeta?.path ?? "settings",
    meta: index7Q6h1QovTgMeta || {},
    alias: index7Q6h1QovTgMeta?.alias || [],
    redirect: index7Q6h1QovTgMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93WK0c3N7SJRMeta?.name ?? "my-profile-manage-settings-notifications-type___cs",
    path: _91type_93WK0c3N7SJRMeta?.path ?? "settings/notifications/:type()",
    meta: _91type_93WK0c3N7SJRMeta || {},
    alias: _91type_93WK0c3N7SJRMeta?.alias || [],
    redirect: _91type_93WK0c3N7SJRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/[type].vue").then(m => m.default || m)
  },
  {
    name: devicesgELcUXxOPWMeta?.name ?? "my-profile-manage-settings-notifications-devices___cs",
    path: devicesgELcUXxOPWMeta?.path ?? "settings/notifications/devices",
    meta: devicesgELcUXxOPWMeta || {},
    alias: devicesgELcUXxOPWMeta?.alias || [],
    redirect: devicesgELcUXxOPWMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/devices.vue").then(m => m.default || m)
  },
  {
    name: index8tE0SsCx7YMeta?.name ?? "my-profile-manage-settings-notifications___cs",
    path: index8tE0SsCx7YMeta?.path ?? "settings/notifications",
    meta: index8tE0SsCx7YMeta || {},
    alias: index8tE0SsCx7YMeta?.alias || [],
    redirect: index8tE0SsCx7YMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: privacyIX0bRvU07HMeta?.name ?? "my-profile-manage-settings-privacy___cs",
    path: privacyIX0bRvU07HMeta?.path ?? "settings/privacy",
    meta: privacyIX0bRvU07HMeta || {},
    alias: privacyIX0bRvU07HMeta?.alias || [],
    redirect: privacyIX0bRvU07HMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/settings/privacy.vue").then(m => m.default || m)
  },
  {
    name: sport_45types8HUd3TfGzrMeta?.name ?? "my-profile-manage-sport-types___cs",
    path: sport_45types8HUd3TfGzrMeta?.path ?? "sport-types",
    meta: sport_45types8HUd3TfGzrMeta || {},
    alias: sport_45types8HUd3TfGzrMeta?.alias || [],
    redirect: sport_45types8HUd3TfGzrMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/profile/manage/sport-types.vue").then(m => m.default || m)
  }
]
  },
  {
    name: starsquzETRblYIMeta?.name ?? "my-stars___de",
    path: starsquzETRblYIMeta?.path ?? "/my/stars",
    meta: starsquzETRblYIMeta || {},
    alias: starsquzETRblYIMeta?.alias || [],
    redirect: starsquzETRblYIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/stars.vue").then(m => m.default || m)
  },
  {
    name: starsquzETRblYIMeta?.name ?? "my-stars___en",
    path: starsquzETRblYIMeta?.path ?? "/en/my/stars",
    meta: starsquzETRblYIMeta || {},
    alias: starsquzETRblYIMeta?.alias || [],
    redirect: starsquzETRblYIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/stars.vue").then(m => m.default || m)
  },
  {
    name: starsquzETRblYIMeta?.name ?? "my-stars___nl",
    path: starsquzETRblYIMeta?.path ?? "/nl/my/stars",
    meta: starsquzETRblYIMeta || {},
    alias: starsquzETRblYIMeta?.alias || [],
    redirect: starsquzETRblYIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/stars.vue").then(m => m.default || m)
  },
  {
    name: starsquzETRblYIMeta?.name ?? "my-stars___cs",
    path: starsquzETRblYIMeta?.path ?? "/cs/my/stars",
    meta: starsquzETRblYIMeta || {},
    alias: starsquzETRblYIMeta?.alias || [],
    redirect: starsquzETRblYIMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/my/stars.vue").then(m => m.default || m)
  },
  {
    name: _91article_93wYDEzRUatnMeta?.name ?? "news-article___de",
    path: _91article_93wYDEzRUatnMeta?.path ?? "/news/:article()",
    meta: _91article_93wYDEzRUatnMeta || {},
    alias: _91article_93wYDEzRUatnMeta?.alias || [],
    redirect: _91article_93wYDEzRUatnMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/news/[article].vue").then(m => m.default || m)
  },
  {
    name: _91article_93wYDEzRUatnMeta?.name ?? "news-article___en",
    path: _91article_93wYDEzRUatnMeta?.path ?? "/en/news/:article()",
    meta: _91article_93wYDEzRUatnMeta || {},
    alias: _91article_93wYDEzRUatnMeta?.alias || [],
    redirect: _91article_93wYDEzRUatnMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/news/[article].vue").then(m => m.default || m)
  },
  {
    name: _91article_93wYDEzRUatnMeta?.name ?? "news-article___nl",
    path: _91article_93wYDEzRUatnMeta?.path ?? "/nl/news/:article()",
    meta: _91article_93wYDEzRUatnMeta || {},
    alias: _91article_93wYDEzRUatnMeta?.alias || [],
    redirect: _91article_93wYDEzRUatnMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/news/[article].vue").then(m => m.default || m)
  },
  {
    name: _91article_93wYDEzRUatnMeta?.name ?? "news-article___cs",
    path: _91article_93wYDEzRUatnMeta?.path ?? "/cs/news/:article()",
    meta: _91article_93wYDEzRUatnMeta || {},
    alias: _91article_93wYDEzRUatnMeta?.alias || [],
    redirect: _91article_93wYDEzRUatnMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/news/[article].vue").then(m => m.default || m)
  },
  {
    name: indexBcxZoT8oUKMeta?.name ?? "news___de",
    path: indexBcxZoT8oUKMeta?.path ?? "/news",
    meta: indexBcxZoT8oUKMeta || {},
    alias: indexBcxZoT8oUKMeta?.alias || [],
    redirect: indexBcxZoT8oUKMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexBcxZoT8oUKMeta?.name ?? "news___en",
    path: indexBcxZoT8oUKMeta?.path ?? "/en/news",
    meta: indexBcxZoT8oUKMeta || {},
    alias: indexBcxZoT8oUKMeta?.alias || [],
    redirect: indexBcxZoT8oUKMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexBcxZoT8oUKMeta?.name ?? "news___nl",
    path: indexBcxZoT8oUKMeta?.path ?? "/nl/news",
    meta: indexBcxZoT8oUKMeta || {},
    alias: indexBcxZoT8oUKMeta?.alias || [],
    redirect: indexBcxZoT8oUKMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexBcxZoT8oUKMeta?.name ?? "news___cs",
    path: indexBcxZoT8oUKMeta?.path ?? "/cs/news",
    meta: indexBcxZoT8oUKMeta || {},
    alias: indexBcxZoT8oUKMeta?.alias || [],
    redirect: indexBcxZoT8oUKMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsET8Jt3ATWfMeta?.name ?? "notifications___de",
    path: notificationsET8Jt3ATWfMeta?.path ?? "/notifications",
    meta: notificationsET8Jt3ATWfMeta || {},
    alias: notificationsET8Jt3ATWfMeta?.alias || [],
    redirect: notificationsET8Jt3ATWfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsET8Jt3ATWfMeta?.name ?? "notifications___en",
    path: notificationsET8Jt3ATWfMeta?.path ?? "/en/notifications",
    meta: notificationsET8Jt3ATWfMeta || {},
    alias: notificationsET8Jt3ATWfMeta?.alias || [],
    redirect: notificationsET8Jt3ATWfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsET8Jt3ATWfMeta?.name ?? "notifications___nl",
    path: notificationsET8Jt3ATWfMeta?.path ?? "/nl/notifications",
    meta: notificationsET8Jt3ATWfMeta || {},
    alias: notificationsET8Jt3ATWfMeta?.alias || [],
    redirect: notificationsET8Jt3ATWfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsET8Jt3ATWfMeta?.name ?? "notifications___cs",
    path: notificationsET8Jt3ATWfMeta?.path ?? "/cs/notifications",
    meta: notificationsET8Jt3ATWfMeta || {},
    alias: notificationsET8Jt3ATWfMeta?.alias || [],
    redirect: notificationsET8Jt3ATWfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: operation_45plannero9l92BtUFgMeta?.name ?? "operation-planner___de",
    path: operation_45plannero9l92BtUFgMeta?.path ?? "/operation-planner",
    meta: operation_45plannero9l92BtUFgMeta || {},
    alias: operation_45plannero9l92BtUFgMeta?.alias || [],
    redirect: operation_45plannero9l92BtUFgMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/operation-planner.vue").then(m => m.default || m)
  },
  {
    name: operation_45plannero9l92BtUFgMeta?.name ?? "operation-planner___en",
    path: operation_45plannero9l92BtUFgMeta?.path ?? "/en/operation-planner",
    meta: operation_45plannero9l92BtUFgMeta || {},
    alias: operation_45plannero9l92BtUFgMeta?.alias || [],
    redirect: operation_45plannero9l92BtUFgMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/operation-planner.vue").then(m => m.default || m)
  },
  {
    name: operation_45plannero9l92BtUFgMeta?.name ?? "operation-planner___nl",
    path: operation_45plannero9l92BtUFgMeta?.path ?? "/nl/operation-planner",
    meta: operation_45plannero9l92BtUFgMeta || {},
    alias: operation_45plannero9l92BtUFgMeta?.alias || [],
    redirect: operation_45plannero9l92BtUFgMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/operation-planner.vue").then(m => m.default || m)
  },
  {
    name: operation_45plannero9l92BtUFgMeta?.name ?? "operation-planner___cs",
    path: operation_45plannero9l92BtUFgMeta?.path ?? "/cs/operation-planner",
    meta: operation_45plannero9l92BtUFgMeta || {},
    alias: operation_45plannero9l92BtUFgMeta?.alias || [],
    redirect: operation_45plannero9l92BtUFgMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/operation-planner.vue").then(m => m.default || m)
  },
  {
    name: _91order_93ZdRmpGAqbXMeta?.name ?? "orders-order___de",
    path: _91order_93ZdRmpGAqbXMeta?.path ?? "/orders/:order()",
    meta: _91order_93ZdRmpGAqbXMeta || {},
    alias: _91order_93ZdRmpGAqbXMeta?.alias || [],
    redirect: _91order_93ZdRmpGAqbXMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/orders/[order].vue").then(m => m.default || m)
  },
  {
    name: _91order_93ZdRmpGAqbXMeta?.name ?? "orders-order___en",
    path: _91order_93ZdRmpGAqbXMeta?.path ?? "/en/orders/:order()",
    meta: _91order_93ZdRmpGAqbXMeta || {},
    alias: _91order_93ZdRmpGAqbXMeta?.alias || [],
    redirect: _91order_93ZdRmpGAqbXMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/orders/[order].vue").then(m => m.default || m)
  },
  {
    name: _91order_93ZdRmpGAqbXMeta?.name ?? "orders-order___nl",
    path: _91order_93ZdRmpGAqbXMeta?.path ?? "/nl/orders/:order()",
    meta: _91order_93ZdRmpGAqbXMeta || {},
    alias: _91order_93ZdRmpGAqbXMeta?.alias || [],
    redirect: _91order_93ZdRmpGAqbXMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/orders/[order].vue").then(m => m.default || m)
  },
  {
    name: _91order_93ZdRmpGAqbXMeta?.name ?? "orders-order___cs",
    path: _91order_93ZdRmpGAqbXMeta?.path ?? "/cs/orders/:order()",
    meta: _91order_93ZdRmpGAqbXMeta || {},
    alias: _91order_93ZdRmpGAqbXMeta?.alias || [],
    redirect: _91order_93ZdRmpGAqbXMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/orders/[order].vue").then(m => m.default || m)
  },
  {
    name: password8yLouPs9XaMeta?.name ?? "password___de",
    path: password8yLouPs9XaMeta?.path ?? "/password",
    meta: password8yLouPs9XaMeta || {},
    alias: password8yLouPs9XaMeta?.alias || [],
    redirect: password8yLouPs9XaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/password.vue").then(m => m.default || m)
  },
  {
    name: password8yLouPs9XaMeta?.name ?? "password___en",
    path: password8yLouPs9XaMeta?.path ?? "/en/password",
    meta: password8yLouPs9XaMeta || {},
    alias: password8yLouPs9XaMeta?.alias || [],
    redirect: password8yLouPs9XaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/password.vue").then(m => m.default || m)
  },
  {
    name: password8yLouPs9XaMeta?.name ?? "password___nl",
    path: password8yLouPs9XaMeta?.path ?? "/nl/password",
    meta: password8yLouPs9XaMeta || {},
    alias: password8yLouPs9XaMeta?.alias || [],
    redirect: password8yLouPs9XaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/password.vue").then(m => m.default || m)
  },
  {
    name: password8yLouPs9XaMeta?.name ?? "password___cs",
    path: password8yLouPs9XaMeta?.path ?? "/cs/password",
    meta: password8yLouPs9XaMeta || {},
    alias: password8yLouPs9XaMeta?.alias || [],
    redirect: password8yLouPs9XaMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/password.vue").then(m => m.default || m)
  },
  {
    name: indexNAxJvs3P2DMeta?.name ?? "pay-order___de",
    path: indexNAxJvs3P2DMeta?.path ?? "/pay/:order()",
    meta: indexNAxJvs3P2DMeta || {},
    alias: indexNAxJvs3P2DMeta?.alias || [],
    redirect: indexNAxJvs3P2DMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/pay/[order]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNAxJvs3P2DMeta?.name ?? "pay-order___en",
    path: indexNAxJvs3P2DMeta?.path ?? "/en/pay/:order()",
    meta: indexNAxJvs3P2DMeta || {},
    alias: indexNAxJvs3P2DMeta?.alias || [],
    redirect: indexNAxJvs3P2DMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/pay/[order]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNAxJvs3P2DMeta?.name ?? "pay-order___nl",
    path: indexNAxJvs3P2DMeta?.path ?? "/nl/pay/:order()",
    meta: indexNAxJvs3P2DMeta || {},
    alias: indexNAxJvs3P2DMeta?.alias || [],
    redirect: indexNAxJvs3P2DMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/pay/[order]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNAxJvs3P2DMeta?.name ?? "pay-order___cs",
    path: indexNAxJvs3P2DMeta?.path ?? "/cs/pay/:order()",
    meta: indexNAxJvs3P2DMeta || {},
    alias: indexNAxJvs3P2DMeta?.alias || [],
    redirect: indexNAxJvs3P2DMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/pay/[order]/index.vue").then(m => m.default || m)
  },
  {
    name: processeTx51X4yr0Meta?.name ?? "pay-order-process___de",
    path: processeTx51X4yr0Meta?.path ?? "/pay/:order()/process",
    meta: processeTx51X4yr0Meta || {},
    alias: processeTx51X4yr0Meta?.alias || [],
    redirect: processeTx51X4yr0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/pay/[order]/process.vue").then(m => m.default || m)
  },
  {
    name: processeTx51X4yr0Meta?.name ?? "pay-order-process___en",
    path: processeTx51X4yr0Meta?.path ?? "/en/pay/:order()/process",
    meta: processeTx51X4yr0Meta || {},
    alias: processeTx51X4yr0Meta?.alias || [],
    redirect: processeTx51X4yr0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/pay/[order]/process.vue").then(m => m.default || m)
  },
  {
    name: processeTx51X4yr0Meta?.name ?? "pay-order-process___nl",
    path: processeTx51X4yr0Meta?.path ?? "/nl/pay/:order()/process",
    meta: processeTx51X4yr0Meta || {},
    alias: processeTx51X4yr0Meta?.alias || [],
    redirect: processeTx51X4yr0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/pay/[order]/process.vue").then(m => m.default || m)
  },
  {
    name: processeTx51X4yr0Meta?.name ?? "pay-order-process___cs",
    path: processeTx51X4yr0Meta?.path ?? "/cs/pay/:order()/process",
    meta: processeTx51X4yr0Meta || {},
    alias: processeTx51X4yr0Meta?.alias || [],
    redirect: processeTx51X4yr0Meta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/pay/[order]/process.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policylZhrIpUwcfMeta?.name ?? "privacy-policy___de",
    path: privacy_45policylZhrIpUwcfMeta?.path ?? "/privacy-policy",
    meta: privacy_45policylZhrIpUwcfMeta || {},
    alias: privacy_45policylZhrIpUwcfMeta?.alias || [],
    redirect: privacy_45policylZhrIpUwcfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policylZhrIpUwcfMeta?.name ?? "privacy-policy___en",
    path: privacy_45policylZhrIpUwcfMeta?.path ?? "/en/privacy-policy",
    meta: privacy_45policylZhrIpUwcfMeta || {},
    alias: privacy_45policylZhrIpUwcfMeta?.alias || [],
    redirect: privacy_45policylZhrIpUwcfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policylZhrIpUwcfMeta?.name ?? "privacy-policy___nl",
    path: privacy_45policylZhrIpUwcfMeta?.path ?? "/nl/privacy-policy",
    meta: privacy_45policylZhrIpUwcfMeta || {},
    alias: privacy_45policylZhrIpUwcfMeta?.alias || [],
    redirect: privacy_45policylZhrIpUwcfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policylZhrIpUwcfMeta?.name ?? "privacy-policy___cs",
    path: privacy_45policylZhrIpUwcfMeta?.path ?? "/cs/privacy-policy",
    meta: privacy_45policylZhrIpUwcfMeta || {},
    alias: privacy_45policylZhrIpUwcfMeta?.alias || [],
    redirect: privacy_45policylZhrIpUwcfMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registeroyHJqz45klMeta?.name ?? "register___de",
    path: registeroyHJqz45klMeta?.path ?? "/register",
    meta: registeroyHJqz45klMeta || {},
    alias: registeroyHJqz45klMeta?.alias || [],
    redirect: registeroyHJqz45klMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registeroyHJqz45klMeta?.name ?? "register___en",
    path: registeroyHJqz45klMeta?.path ?? "/en/register",
    meta: registeroyHJqz45klMeta || {},
    alias: registeroyHJqz45klMeta?.alias || [],
    redirect: registeroyHJqz45klMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registeroyHJqz45klMeta?.name ?? "register___nl",
    path: registeroyHJqz45klMeta?.path ?? "/nl/register",
    meta: registeroyHJqz45klMeta || {},
    alias: registeroyHJqz45klMeta?.alias || [],
    redirect: registeroyHJqz45klMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registeroyHJqz45klMeta?.name ?? "register___cs",
    path: registeroyHJqz45klMeta?.path ?? "/cs/register",
    meta: registeroyHJqz45klMeta || {},
    alias: registeroyHJqz45klMeta?.alias || [],
    redirect: registeroyHJqz45klMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordgKUfEH2U9XMeta?.name ?? "reset-password___de",
    path: reset_45passwordgKUfEH2U9XMeta?.path ?? "/reset-password",
    meta: reset_45passwordgKUfEH2U9XMeta || {},
    alias: reset_45passwordgKUfEH2U9XMeta?.alias || [],
    redirect: reset_45passwordgKUfEH2U9XMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordgKUfEH2U9XMeta?.name ?? "reset-password___en",
    path: reset_45passwordgKUfEH2U9XMeta?.path ?? "/en/reset-password",
    meta: reset_45passwordgKUfEH2U9XMeta || {},
    alias: reset_45passwordgKUfEH2U9XMeta?.alias || [],
    redirect: reset_45passwordgKUfEH2U9XMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordgKUfEH2U9XMeta?.name ?? "reset-password___nl",
    path: reset_45passwordgKUfEH2U9XMeta?.path ?? "/nl/reset-password",
    meta: reset_45passwordgKUfEH2U9XMeta || {},
    alias: reset_45passwordgKUfEH2U9XMeta?.alias || [],
    redirect: reset_45passwordgKUfEH2U9XMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordgKUfEH2U9XMeta?.name ?? "reset-password___cs",
    path: reset_45passwordgKUfEH2U9XMeta?.path ?? "/cs/reset-password",
    meta: reset_45passwordgKUfEH2U9XMeta || {},
    alias: reset_45passwordgKUfEH2U9XMeta?.alias || [],
    redirect: reset_45passwordgKUfEH2U9XMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: resultshqvMPFGLeFMeta?.name ?? "results___de",
    path: resultshqvMPFGLeFMeta?.path ?? "/results",
    meta: resultshqvMPFGLeFMeta || {},
    alias: resultshqvMPFGLeFMeta?.alias || [],
    redirect: resultshqvMPFGLeFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/results.vue").then(m => m.default || m)
  },
  {
    name: resultshqvMPFGLeFMeta?.name ?? "results___en",
    path: resultshqvMPFGLeFMeta?.path ?? "/en/results",
    meta: resultshqvMPFGLeFMeta || {},
    alias: resultshqvMPFGLeFMeta?.alias || [],
    redirect: resultshqvMPFGLeFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/results.vue").then(m => m.default || m)
  },
  {
    name: resultshqvMPFGLeFMeta?.name ?? "results___nl",
    path: resultshqvMPFGLeFMeta?.path ?? "/nl/results",
    meta: resultshqvMPFGLeFMeta || {},
    alias: resultshqvMPFGLeFMeta?.alias || [],
    redirect: resultshqvMPFGLeFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/results.vue").then(m => m.default || m)
  },
  {
    name: resultshqvMPFGLeFMeta?.name ?? "results___cs",
    path: resultshqvMPFGLeFMeta?.path ?? "/cs/results",
    meta: resultshqvMPFGLeFMeta || {},
    alias: resultshqvMPFGLeFMeta?.alias || [],
    redirect: resultshqvMPFGLeFMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/results.vue").then(m => m.default || m)
  },
  {
    name: search0rJlMV9ieAMeta?.name ?? "search___de",
    path: search0rJlMV9ieAMeta?.path ?? "/search",
    meta: search0rJlMV9ieAMeta || {},
    alias: search0rJlMV9ieAMeta?.alias || [],
    redirect: search0rJlMV9ieAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: search0rJlMV9ieAMeta?.name ?? "search___en",
    path: search0rJlMV9ieAMeta?.path ?? "/en/search",
    meta: search0rJlMV9ieAMeta || {},
    alias: search0rJlMV9ieAMeta?.alias || [],
    redirect: search0rJlMV9ieAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: search0rJlMV9ieAMeta?.name ?? "search___nl",
    path: search0rJlMV9ieAMeta?.path ?? "/nl/search",
    meta: search0rJlMV9ieAMeta || {},
    alias: search0rJlMV9ieAMeta?.alias || [],
    redirect: search0rJlMV9ieAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: search0rJlMV9ieAMeta?.name ?? "search___cs",
    path: search0rJlMV9ieAMeta?.path ?? "/cs/search",
    meta: search0rJlMV9ieAMeta || {},
    alias: search0rJlMV9ieAMeta?.alias || [],
    redirect: search0rJlMV9ieAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: sports_45mapXzKbJm6fpVMeta?.name ?? "sports-map___de",
    path: sports_45mapXzKbJm6fpVMeta?.path ?? "/sports-map",
    meta: sports_45mapXzKbJm6fpVMeta || {},
    alias: sports_45mapXzKbJm6fpVMeta?.alias || [],
    redirect: sports_45mapXzKbJm6fpVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports-map.vue").then(m => m.default || m)
  },
  {
    name: sports_45mapXzKbJm6fpVMeta?.name ?? "sports-map___en",
    path: sports_45mapXzKbJm6fpVMeta?.path ?? "/en/sports-map",
    meta: sports_45mapXzKbJm6fpVMeta || {},
    alias: sports_45mapXzKbJm6fpVMeta?.alias || [],
    redirect: sports_45mapXzKbJm6fpVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports-map.vue").then(m => m.default || m)
  },
  {
    name: sports_45mapXzKbJm6fpVMeta?.name ?? "sports-map___nl",
    path: sports_45mapXzKbJm6fpVMeta?.path ?? "/nl/sports-map",
    meta: sports_45mapXzKbJm6fpVMeta || {},
    alias: sports_45mapXzKbJm6fpVMeta?.alias || [],
    redirect: sports_45mapXzKbJm6fpVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports-map.vue").then(m => m.default || m)
  },
  {
    name: sports_45mapXzKbJm6fpVMeta?.name ?? "sports-map___cs",
    path: sports_45mapXzKbJm6fpVMeta?.path ?? "/cs/sports-map",
    meta: sports_45mapXzKbJm6fpVMeta || {},
    alias: sports_45mapXzKbJm6fpVMeta?.alias || [],
    redirect: sports_45mapXzKbJm6fpVMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports-map.vue").then(m => m.default || m)
  },
  {
    name: _91sport_93JZo5J0an8LMeta?.name ?? "sports-sport___de",
    path: _91sport_93JZo5J0an8LMeta?.path ?? "/sports/:sport()",
    meta: _91sport_93JZo5J0an8LMeta || {},
    alias: _91sport_93JZo5J0an8LMeta?.alias || [],
    redirect: _91sport_93JZo5J0an8LMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports/[sport].vue").then(m => m.default || m)
  },
  {
    name: _91sport_93JZo5J0an8LMeta?.name ?? "sports-sport___en",
    path: _91sport_93JZo5J0an8LMeta?.path ?? "/en/sports/:sport()",
    meta: _91sport_93JZo5J0an8LMeta || {},
    alias: _91sport_93JZo5J0an8LMeta?.alias || [],
    redirect: _91sport_93JZo5J0an8LMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports/[sport].vue").then(m => m.default || m)
  },
  {
    name: _91sport_93JZo5J0an8LMeta?.name ?? "sports-sport___nl",
    path: _91sport_93JZo5J0an8LMeta?.path ?? "/nl/sports/:sport()",
    meta: _91sport_93JZo5J0an8LMeta || {},
    alias: _91sport_93JZo5J0an8LMeta?.alias || [],
    redirect: _91sport_93JZo5J0an8LMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports/[sport].vue").then(m => m.default || m)
  },
  {
    name: _91sport_93JZo5J0an8LMeta?.name ?? "sports-sport___cs",
    path: _91sport_93JZo5J0an8LMeta?.path ?? "/cs/sports/:sport()",
    meta: _91sport_93JZo5J0an8LMeta || {},
    alias: _91sport_93JZo5J0an8LMeta?.alias || [],
    redirect: _91sport_93JZo5J0an8LMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports/[sport].vue").then(m => m.default || m)
  },
  {
    name: indexIuPVxfCMEdMeta?.name ?? "sports___de",
    path: indexIuPVxfCMEdMeta?.path ?? "/sports",
    meta: indexIuPVxfCMEdMeta || {},
    alias: indexIuPVxfCMEdMeta?.alias || [],
    redirect: indexIuPVxfCMEdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: indexIuPVxfCMEdMeta?.name ?? "sports___en",
    path: indexIuPVxfCMEdMeta?.path ?? "/en/sports",
    meta: indexIuPVxfCMEdMeta || {},
    alias: indexIuPVxfCMEdMeta?.alias || [],
    redirect: indexIuPVxfCMEdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: indexIuPVxfCMEdMeta?.name ?? "sports___nl",
    path: indexIuPVxfCMEdMeta?.path ?? "/nl/sports",
    meta: indexIuPVxfCMEdMeta || {},
    alias: indexIuPVxfCMEdMeta?.alias || [],
    redirect: indexIuPVxfCMEdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: indexIuPVxfCMEdMeta?.name ?? "sports___cs",
    path: indexIuPVxfCMEdMeta?.path ?? "/cs/sports",
    meta: indexIuPVxfCMEdMeta || {},
    alias: indexIuPVxfCMEdMeta?.alias || [],
    redirect: indexIuPVxfCMEdMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: startk9Ksk7S5uAMeta?.name ?? "start___de",
    path: startk9Ksk7S5uAMeta?.path ?? "/start",
    meta: startk9Ksk7S5uAMeta || {},
    alias: startk9Ksk7S5uAMeta?.alias || [],
    redirect: startk9Ksk7S5uAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/start.vue").then(m => m.default || m)
  },
  {
    name: startk9Ksk7S5uAMeta?.name ?? "start___en",
    path: startk9Ksk7S5uAMeta?.path ?? "/en/start",
    meta: startk9Ksk7S5uAMeta || {},
    alias: startk9Ksk7S5uAMeta?.alias || [],
    redirect: startk9Ksk7S5uAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/start.vue").then(m => m.default || m)
  },
  {
    name: startk9Ksk7S5uAMeta?.name ?? "start___nl",
    path: startk9Ksk7S5uAMeta?.path ?? "/nl/start",
    meta: startk9Ksk7S5uAMeta || {},
    alias: startk9Ksk7S5uAMeta?.alias || [],
    redirect: startk9Ksk7S5uAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/start.vue").then(m => m.default || m)
  },
  {
    name: startk9Ksk7S5uAMeta?.name ?? "start___cs",
    path: startk9Ksk7S5uAMeta?.path ?? "/cs/start",
    meta: startk9Ksk7S5uAMeta || {},
    alias: startk9Ksk7S5uAMeta?.alias || [],
    redirect: startk9Ksk7S5uAMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/start.vue").then(m => m.default || m)
  },
  {
    name: supportKhFNpPUlZiMeta?.name ?? "support___de",
    path: supportKhFNpPUlZiMeta?.path ?? "/support",
    meta: supportKhFNpPUlZiMeta || {},
    alias: supportKhFNpPUlZiMeta?.alias || [],
    redirect: supportKhFNpPUlZiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportKhFNpPUlZiMeta?.name ?? "support___en",
    path: supportKhFNpPUlZiMeta?.path ?? "/en/support",
    meta: supportKhFNpPUlZiMeta || {},
    alias: supportKhFNpPUlZiMeta?.alias || [],
    redirect: supportKhFNpPUlZiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportKhFNpPUlZiMeta?.name ?? "support___nl",
    path: supportKhFNpPUlZiMeta?.path ?? "/nl/support",
    meta: supportKhFNpPUlZiMeta || {},
    alias: supportKhFNpPUlZiMeta?.alias || [],
    redirect: supportKhFNpPUlZiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportKhFNpPUlZiMeta?.name ?? "support___cs",
    path: supportKhFNpPUlZiMeta?.path ?? "/cs/support",
    meta: supportKhFNpPUlZiMeta || {},
    alias: supportKhFNpPUlZiMeta?.alias || [],
    redirect: supportKhFNpPUlZiMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: indexDqsHUt1UgEMeta?.name ?? "team___de",
    path: indexDqsHUt1UgEMeta?.path ?? "/team",
    meta: indexDqsHUt1UgEMeta || {},
    alias: indexDqsHUt1UgEMeta?.alias || [],
    redirect: indexDqsHUt1UgEMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqsHUt1UgEMeta?.name ?? "team___en",
    path: indexDqsHUt1UgEMeta?.path ?? "/en/team",
    meta: indexDqsHUt1UgEMeta || {},
    alias: indexDqsHUt1UgEMeta?.alias || [],
    redirect: indexDqsHUt1UgEMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqsHUt1UgEMeta?.name ?? "team___nl",
    path: indexDqsHUt1UgEMeta?.path ?? "/nl/team",
    meta: indexDqsHUt1UgEMeta || {},
    alias: indexDqsHUt1UgEMeta?.alias || [],
    redirect: indexDqsHUt1UgEMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqsHUt1UgEMeta?.name ?? "team___cs",
    path: indexDqsHUt1UgEMeta?.path ?? "/cs/team",
    meta: indexDqsHUt1UgEMeta || {},
    alias: indexDqsHUt1UgEMeta?.alias || [],
    redirect: indexDqsHUt1UgEMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeD5N9Wv7eKRMeta?.name ?? "unsubscribe___de",
    path: unsubscribeD5N9Wv7eKRMeta?.path ?? "/unsubscribe",
    meta: unsubscribeD5N9Wv7eKRMeta || {},
    alias: unsubscribeD5N9Wv7eKRMeta?.alias || [],
    redirect: unsubscribeD5N9Wv7eKRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeD5N9Wv7eKRMeta?.name ?? "unsubscribe___en",
    path: unsubscribeD5N9Wv7eKRMeta?.path ?? "/en/unsubscribe",
    meta: unsubscribeD5N9Wv7eKRMeta || {},
    alias: unsubscribeD5N9Wv7eKRMeta?.alias || [],
    redirect: unsubscribeD5N9Wv7eKRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeD5N9Wv7eKRMeta?.name ?? "unsubscribe___nl",
    path: unsubscribeD5N9Wv7eKRMeta?.path ?? "/nl/unsubscribe",
    meta: unsubscribeD5N9Wv7eKRMeta || {},
    alias: unsubscribeD5N9Wv7eKRMeta?.alias || [],
    redirect: unsubscribeD5N9Wv7eKRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeD5N9Wv7eKRMeta?.name ?? "unsubscribe___cs",
    path: unsubscribeD5N9Wv7eKRMeta?.path ?? "/cs/unsubscribe",
    meta: unsubscribeD5N9Wv7eKRMeta || {},
    alias: unsubscribeD5N9Wv7eKRMeta?.alias || [],
    redirect: unsubscribeD5N9Wv7eKRMeta?.redirect,
    component: () => import("/home/runner/work/app/app/src/pages/unsubscribe.vue").then(m => m.default || m)
  }
]